import React from 'react';
import s from './MobileSearch.module.scss';
import MobileMenuLayout from '../MobileMenuLayout/MobileMenuLayout';
import SearchBar from 'components/Layout/Header/SearchBar/SearchBar';

type Props = {
    isActive: boolean;
    onClose: () => void;
    onSearchItemClick: () => void;
};

const MobileSearch = ({ isActive, onClose, onSearchItemClick }: Props) => {
    return (
        <MobileMenuLayout className={s.container} title='Wyszukiwanie' fullWidth isActive={isActive} onClose={onClose}>
            <SearchBar isMobile onSearchItemClick={onSearchItemClick} />
        </MobileMenuLayout>
    );
};

export default MobileSearch;
