import React from 'react';
import s from './ProductCategoriesSection.module.scss';
import CategoryCard from './CategoryCard/CategoryCard';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';

type Props = {};

const ProductCategoriesSection = (props: Props) => {
    return (
        <div className={s.container}>
            <CategoryCard
                title='Optyka'
                description='Najwyższej klasy sprzęt optyczny do Twojego salonu.'
                link={`${NavigationNames.Products}/optyka`}
                barColor='pink'
            />
            <CategoryCard
                title='Refrakcja'
                description='Profesjonalny sprzęt do diagnozowania wad refrakcji.'
                link={`${NavigationNames.Products}/refrakcja`}
                bgColor='gray'
                barColor='green'
            />
            <CategoryCard
                title='Okulistyka'
                link={`${NavigationNames.Products}/okulistyka`}
                description='Pełne spektrum urządzeń okulistycznych renomowanych firm.'
                barColor='yellow'
            />
            <CategoryCard
                title='Meble okulistyczne'
                description='Trwałe i niezawodne meble do Twojego gabinetu i salonu.'
                barColor='blue'
                link={`${NavigationNames.Products}/meble-okulistyczne`}
                bgColor='gray'
            />
        </div>
    );
};

export default ProductCategoriesSection;
