import React, { useState } from 'react';
import s from './TreeViewItem.module.scss';
import { Category } from 'types/prestashopREST/CategoryProduct';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Tooltip, Typography } from '@mui/material';
import { getCategoryName } from 'pages/ShopPage/ShopCategoryPage/ShopCategoryPage';

type Props = { category: Category; expanded?: boolean; closeMobileFilters: () => void };

const TreeViewItem = ({ category, closeMobileFilters, expanded = false }: Props) => {
    const [open, setOpen] = useState(expanded);

    const handleCaretClick = () => setOpen(!open);

    return (
        <li className={s.listItem}>
            <div className={s.listItemText}>
                {category.children.length > 0 ? (
                    <Tooltip title={open ? 'Zwiń listę' : 'Rozwiń listę'} arrow>
                        <div>
                            <FontAwesomeIcon
                                onClick={handleCaretClick}
                                icon={open ? faCaretDown : faCaretRight}
                                className={s.icon}
                            />
                        </div>
                    </Tooltip>
                ) : (
                    <span className={s.icon} />
                )}
                <Tooltip arrow title={`Przejdź do "${getCategoryName(category.name)}"`} placement='right'>
                    <Link to={`../${category.id}`} className={s.listLinkItem} onClick={closeMobileFilters}>
                        <Typography>{getCategoryName(category.name)}</Typography>
                    </Link>
                </Tooltip>
            </div>

            {open && (
                <ul className={s.subcategoriesList}>
                    {category.children.map((subcategory) => {
                        return (
                            <TreeViewItem
                                key={subcategory.id}
                                category={subcategory}
                                closeMobileFilters={closeMobileFilters}
                            />
                        );
                    })}
                </ul>
            )}
        </li>
    );
};

export default TreeViewItem;
