import { Alert, Button, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import s from './AddressStep.module.scss';
import AddressCard from './AddressCard/AddressCard';
import useFetch from 'hooks/useFetch';
import { AddressInfo, GetAllAddressesResponse } from 'types/prestashopREST/Checkout/GetAddresses';
import { useCheckout } from 'contexts/CheckoutContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import AddressDialog from './AddressDialog/AddressDialog';

type Props = {};

const AddressStep = (props: Props) => {
    const checkout = useCheckout();

    const { data, loading, error, refetch } = useFetch<GetAllAddressesResponse>(
        `${process.env.REACT_APP_PRESTASHOP_URL}/rest/alladdresses`
    );

    const [addressToEdit, setAddressToEdit] = useState<AddressInfo | null>(null);
    const [addressEditOpen, setAddressEditOpen] = useState<boolean>(false);

    const [addDialogWindowOpened, setAddDialogWindowOpened] = useState<boolean>(false);

    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!data || addDialogWindowOpened) return;
        const addressesLength = Object.keys(data?.psdata || {}).length;

        if (addressesLength === 0) {
            setAddDialogOpen(true);
            setAddDialogWindowOpened(true);
            return;
        }

        if (addressesLength === 1) {
            checkout?.setAddress(data?.psdata[Object.keys(data?.psdata)[0]]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addDialogWindowOpened, data, setAddDialogWindowOpened]);

    const handleAddressEditClick = (address: AddressInfo) => {
        setAddressToEdit(address);
        setAddressEditOpen(true);
    };

    return (
        <>
            {loading && <LinearProgress />}
            {data && !loading && (
                <div className={s.container}>
                    <Button
                        className={s.addButton}
                        onClick={() => setAddDialogOpen(true)}
                        endIcon={<FontAwesomeIcon icon={faAdd} />}
                    >
                        Dodaj adres
                    </Button>
                    <Typography variant='body1'>
                        Wybrany adres będzie stosowany zarówno jako adres osobisty (do faktury) i jako adres dostawy.
                    </Typography>
                    <div className={s.addressesList}>
                        {Object.keys(data?.psdata || {}).map((key) => {
                            if (!data) return null;
                            return (
                                <AddressCard
                                    refetch={refetch}
                                    address={data?.psdata[key]}
                                    key={data?.psdata[key].id}
                                    onEditClick={handleAddressEditClick}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
            {error && (
                <Alert severity='error'>Wystąpił błąd podczas pobierania adresów. Spróbuj odświeżyć stronę.</Alert>
            )}

            <AddressDialog
                open={addDialogOpen}
                handleClose={() => setAddDialogOpen(false)}
                refetchAddresses={refetch}
            />

            <AddressDialog
                open={addressEditOpen}
                handleClose={() => setAddressEditOpen(false)}
                refetchAddresses={refetch}
                isEdit
                address={addressToEdit}
            />
        </>
    );
};

export default AddressStep;
