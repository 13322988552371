import useFetch from 'hooks/useFetch';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { CartApiResponse, CartInfo, CartProduct } from 'types/prestashopREST/Cart';
import { useSnackbar } from './SnackbarContext';
import ProductAddedDialog from 'components/ShopComponents/ProductAddedDialog/ProductAddedDialog';

interface CartContextProps {
    cartInfo: CartInfo | null;
    loading: boolean;
    cartChanging: boolean;
    error: any; // Replace with a more specific type if desired
    updateCartItem: (
        productId: number,
        quantity: number,
        operation: 'up' | 'down',
        imageSize?: 'cart_default' | 'small_default',
        openDialog?: boolean
    ) => Promise<void>;
    removeCartItem: (productId: number) => Promise<void>;
    refetch: () => void;
    changeQuantity: (productId: number, quantity: CartProduct, openDialog: boolean) => Promise<void>;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export function CartProvider({ children }: { children: React.ReactNode }) {
    const snackbar = useSnackbar();

    const [productAddedDialogOpen, setProductAddedDialogOpen] = useState<boolean>(false);
    const [addedProductId, setAddedProductId] = useState<number | null>(null);

    const [cartChanging, setCartChanging] = useState<boolean>(false);
    const [cartInfo, setCartInfo] = useState<CartInfo | null>(null);

    const { data, loading, error, refetch } = useFetch<CartApiResponse>(
        `${process.env.REACT_APP_PRESTASHOP_URL}/rest/cart`
    );

    useEffect(() => {
        setCartInfo(data?.psdata || null);
    }, [data]);

    const changeQuantity = async (value: number, item: CartProduct, openDialog: boolean = true) => {
        if (item.quantity > value)
            await updateCartItem(item.id_product, item.quantity - value, 'down', 'cart_default', openDialog);
        else if (item.quantity < value)
            await updateCartItem(item.id_product, item.quantity - value, 'up', 'cart_default', openDialog);
    };

    const updateCartItem = async (
        productId: number,
        quantity: number,
        operation: 'up' | 'down',
        imageSize: 'cart_default' | 'small_default' = 'cart_default',
        openDialog: boolean = true
    ) => {
        try {
            setCartChanging(true);
            const url = `${process.env.REACT_APP_PRESTASHOP_URL}/rest/cart?update=1&id_product=${productId}&op=${operation}&action=update&image_size=${imageSize}&qty=${quantity}`;
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
                credentials: 'include',
            });

            if (response.status !== 200) {
                console.error('Błąd podczas aktualizacji koszyka');
                snackbar.openSnackbar('Błąd podczas aktualizacji koszyka', 'error');
                return;
            }

            const data: CartApiResponse = await response.json();
            if (data.success) {
                setCartInfo(data.psdata);
                setAddedProductId(productId);
                if (openDialog) setProductAddedDialogOpen(true);
            }
        } catch (error) {
            if (error instanceof Error) snackbar.openSnackbar(error.message, 'error');
            console.error('Błąd podczas aktualizacji koszyka', error);
        } finally {
            setCartChanging(false);
        }
    };

    const removeCartItem = async (productId: number) => {
        try {
            setCartChanging(true);
            const url = `${process.env.REACT_APP_PRESTASHOP_URL}/rest/cart?id_product=${productId}&delete=1&action=update`;
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
                credentials: 'include',
            });

            if (response.status !== 200) {
                console.error('Błąd podczas aktualizacji koszyka');
                return;
            }

            const data: CartApiResponse = await response.json();

            if (!data.success) {
                snackbar.openSnackbar('Błąd podczas aktualizacji koszyka', 'error');
                return;
            }

            setCartInfo(data.psdata);
            snackbar.openSnackbar('Produkt został usunięty z koszyka', 'success');
        } catch (error) {
            console.error('Błąd podczas aktualizacji koszyka', error);
            if (error instanceof Error) snackbar.openSnackbar(error.message, 'error');
        } finally {
            setCartChanging(false);
        }
    };

    const contextValue = {
        cartInfo,
        loading: loading,
        cartChanging,
        error,
        updateCartItem,
        removeCartItem,
        refetch,
        changeQuantity,
    };

    return (
        <>
            <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>

            <ProductAddedDialog
                open={productAddedDialogOpen}
                handleClose={() => setProductAddedDialogOpen(false)}
                productId={addedProductId}
                cartItems={cartInfo}
            />
        </>
    );
}

export const useCart = () => {
    return useContext(CartContext);
};
