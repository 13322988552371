import React from 'react';
import s from './LoginDialog.module.scss';
import { Dialog, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from '@mui/material';
import LoginForm from 'components/LoginForm/LoginForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

type Props = { open: boolean; handleClose: () => void };

const LoginDialog = ({ open, handleClose }: Props) => {
    const theme = useTheme();

    const belowMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog open={open} onClose={handleClose} fullWidth fullScreen={belowMd}>
            {useMediaQuery(theme.breakpoints.down('md')) && (
                <DialogTitle className={s.dialogTitle}>
                    <FontAwesomeIcon icon={faClose} size='xl' onClick={handleClose} />
                </DialogTitle>
            )}

            <DialogContent className={s.dialogContent}>
                <div>
                    <Typography
                        variant={belowMd ? 'h4' : 'h5'}
                        align={belowMd ? 'left' : 'center'}
                        component='h4'
                        marginBottom={'1rem'}
                    >
                        Logowanie
                    </Typography>

                    <LoginForm onLoginSuccess={handleClose} />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default LoginDialog;
