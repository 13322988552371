import React, { useEffect, useState } from 'react';
import s from './SearchBar.module.scss';
import { gql, useQuery } from '@apollo/client';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ISearchedProduct } from 'types/types';
import { addStrapiUrl } from 'utils/strapiUtils';
import { GET_SEARCHED_PRODUCTS } from './SearchBar.queries';
import MobileSearchBar from './MobileSearchBar/MobileSearchBar';

type Props = { isMobile?: boolean; onSearchItemClick?: () => void };

const SearchBar = ({ onSearchItemClick, isMobile = false }: Props) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [isSearchBarActive, setIsSearchBarActive] = useState<boolean>(false);
    const [searchedProducts, setSearchedProducts] = useState<ISearchedProduct[] | null>(null);
    const { refetch } = useQuery(GET_SEARCHED_PRODUCTS, {
        variables: { name: searchValue },
        skip: true,
    });

    const handleSearchValueChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const onFocusHandler = (): void => setIsSearchBarActive(true);

    const onBlurHandler = (e: React.FocusEvent<HTMLDivElement, Element>): void => {
        if (e.currentTarget.contains(e.relatedTarget)) return;

        setIsSearchBarActive(false);
        setSearchedProducts(null);
        setSearchValue('');
    };

    useEffect(() => {
        const refetchProducts = async (): Promise<void> => {
            if (searchValue === '') {
                setSearchedProducts(null);
                return;
            }

            const response = await refetch({ name: searchValue });
            setSearchedProducts(response?.data?.products?.data);
        };

        refetchProducts();
    }, [searchValue, refetch]);

    if (isMobile)
        return (
            <MobileSearchBar
                searchValue={searchValue}
                isActive={isSearchBarActive}
                setIsActive={setIsSearchBarActive}
                handleSearchValueChange={handleSearchValueChange}
                searchedProducts={searchedProducts}
                onSearchItemClick={onSearchItemClick}
            />
        );

    return (
        <div className={s.container} onFocus={onFocusHandler} onBlur={onBlurHandler}>
            <TextField
                className={s.input}
                placeholder='Wyszukiwanie produktów...'
                autoComplete='off'
                value={searchValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchValueChange(e)}
                InputProps={{
                    startAdornment: <FontAwesomeIcon className={s.icon} icon={faMagnifyingGlass} />,
                }}
                variant='standard'
                size='small'
                fullWidth
            />

            {isSearchBarActive && searchedProducts && (
                <ul className={s.searchList} onMouseOver={() => setIsSearchBarActive(true)}>
                    {searchedProducts.map((product) => (
                        <li className={s.searchListItem} key={product.id}>
                            <Link
                                className={s.searchListItemLink}
                                to={`/produkty/${product.attributes.subcategory.data.attributes.category.data.attributes.url}/${product.attributes.subcategory.data.attributes.url}/${product.attributes.url}`}
                            >
                                <img
                                    className={s.image}
                                    src={addStrapiUrl(product.attributes.images.data[0].attributes.url)}
                                    alt={addStrapiUrl(product.attributes.images.data[0].attributes.alternativeText)}
                                />
                                <Typography variant='body2'>{product.attributes.name}</Typography>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchBar;
