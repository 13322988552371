import React, { useState } from 'react';
import s from './ContactPage.module.scss';
import Layout from 'components/Layout/Layout';
import SocialMediaLinks from 'components/SocialMediaLinks/SocialMediaLinks';
import SubPage from 'components/SubPage/SubPage';
import PersonnelListItem from './PersonnelListItem/PersonnelListItem';
import PersonnelList from './PersonnelList/PersonnelList';
import { Helmet } from 'react-helmet-async';
import BeanButton from 'components/BeanButton/BeanButton';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuestionForm from 'components/QuestionForm/QuestionForm';
import Typography from '@mui/material/Typography/Typography';

type Props = {};

const Contact = (props: Props) => {
    const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);

    const openQuestionForm = (): void => setIsQuestionFormOpen(true);
    const closeQuestionForm = (): void => setIsQuestionFormOpen(false);

    return (
        <>
            <Helmet>
                <title>Kontakt</title>
            </Helmet>
            <Layout>
                <SubPage
                    title={'Kontakt'}
                    actions={
                        <div className={s.buttonsContainer}>
                            <BeanButton
                                color='black'
                                text='Zadaj pytanie'
                                onClick={openQuestionForm}
                                iconEnd={<FontAwesomeIcon icon={faQuestionCircle} size='lg' />}
                            />
                        </div>
                    }
                >
                    <div className={s.container}>
                        <iframe
                            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2576.3440904414006!2d18.630459315707213!3d49.77959387938832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471403ffd4dab81f%3A0x4c328dbb6ce86377!2sPoland%20Optical%20Sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1669671123191!5m2!1spl!2spl'
                            className={s.map}
                            title='location-map'
                            loading='lazy'
                        />

                        <ul className={s.contactList}>
                            <Typography variant='h5' component='h2' className={s.contactListHeader}>
                                Zachęcamy do kontaktu!
                            </Typography>

                            <li className={s.contactListItem}>
                                <h3 className={s.contactItemTitle}>Siedziba Poland Optical Sp. z o.o.</h3>
                                <Typography variant='body1' className={s.contactItemDescription}>
                                    ul. Katowicka 100, 43-400 Cieszyn{' '}
                                </Typography>
                            </li>

                            <li className={s.contactListItem}>
                                <h3 className={s.contactItemTitle}>Telefon </h3>
                                <Typography variant='body1' className={s.contactItemDescription}>
                                    33 851 3630
                                </Typography>
                                <Typography variant='body1' className={s.contactItemDescription}>
                                    33 487 6209
                                </Typography>
                            </li>

                            <li className={s.contactListItem}>
                                <h3 className={s.contactItemTitle}>E-mail</h3>
                                <Typography variant='body1' className={s.contactItemDescription}>
                                    polandoptical@po.pl
                                </Typography>
                            </li>

                            <li className={s.contactListItem}>
                                <h3 className={s.contactItemTitle}>Social Media</h3>
                                <SocialMediaLinks color='black' />
                            </li>
                        </ul>

                        <div className={s.personnelContainer}>
                            <ul className={s.personnelTypeList}>
                                <PersonnelList name='Dział sprzedaży'>
                                    <PersonnelListItem
                                        name='Hanna Cwynar'
                                        phone='33 851 3630'
                                        email='hania@po.pl'
                                        additionalInfo='w. 103'
                                    />
                                </PersonnelList>
                                <PersonnelList name='Obsługa sklepu internetowego, zamawianie części serwisowych'>
                                    <PersonnelListItem
                                        name='Sylwia Pieczonka'
                                        phone='33 851 36 30'
                                        email='sylwia.pieczonka@po.pl'
                                        additionalInfo='w. 102'
                                    />
                                </PersonnelList>
                                <PersonnelList name='Dział Serwisu'>
                                    <PersonnelListItem
                                        name='Przyjmowanie zleceń'
                                        phone='33 851 36 30'
                                        email='serwis@po.pl'
                                        additionalInfo='w. 101'
                                    />
                                </PersonnelList>
                                <PersonnelList name='Kierownik Serwisu'>
                                    <PersonnelListItem name='Lucjan Penkala' phone='502 558 652' email='lucjan@po.pl' />
                                </PersonnelList>
                                <PersonnelList name='Serwisanci'>
                                    <PersonnelListItem
                                        location='Cieszyn'
                                        name='Grzegorz Goły'
                                        phone='501 624 838'
                                        email='grzegorz.goly@po.pl'
                                    />
                                    <PersonnelListItem
                                        location='Rzeszów'
                                        name='Grzegorz Bolka'
                                        phone='512 616 159'
                                        email='grzegorz.bolka@po.pl'
                                    />
                                    <PersonnelListItem
                                        location='Poznań'
                                        name='Jerzy Chojnacki'
                                        phone='512 464 725'
                                        email='jerzy.chojnacki@po.pl'
                                    />
                                    <PersonnelListItem
                                        location='Sochaczew'
                                        name='Sławomir Gerek'
                                        phone='509 643 342'
                                        email='slawomir.gerek@po.pl'
                                    />
                                </PersonnelList>
                                <PersonnelList name='Przedstawiciele handlowi'>
                                    <PersonnelListItem
                                        location='Cieszyn'
                                        name='Wiarosław Wajdzik'
                                        phone='509 366 930'
                                        email='wiarek.wajdzik@po.pl'
                                    />
                                    <PersonnelListItem
                                        location='Warszawa'
                                        name='Piotr Tabor'
                                        phone='506 128 363'
                                        email='piotr@po.pl'
                                    />
                                    <PersonnelListItem
                                        location='Zielona Góra'
                                        name='Mateusz Radkiewicz'
                                        phone='513 028 590'
                                        email='mateusz.radkiewicz@po.pl'
                                    />
                                </PersonnelList>
                            </ul>
                        </div>
                    </div>
                </SubPage>
            </Layout>

            <QuestionForm isOpen={isQuestionFormOpen} onClose={closeQuestionForm} />
        </>
    );
};

export default Contact;
