import { Button, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import s from './AccountDropdown.module.scss';
import LoginDialog from '../AccountDropdown/LoginDialog/LoginDialog';
import { useAuth } from 'contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { ReactComponent as AccountCircleIcon } from 'assets/icons/account_circle_FILL1_wght400_GRAD0_opsz20.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { ProfileRoutesNames } from 'Router/ProfileRoutes';

type Props = { mobile?: boolean };

const AccountDropdown = ({ mobile = false }: Props) => {
    const auth = useAuth();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isLoginDialogOpen, setIsLoginDialogOpen] = useState<boolean>(false);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!auth?.isAuthenticated) setIsLoginDialogOpen(true);
        else setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        auth?.logout();
        handleClose();
        navigate('/');
    };

    return (
        <>
            <div>
                <Tooltip title='Konto'>
                    <div>
                        {mobile ? (
                            <IconButton
                                disabled={auth?.loading}
                                onClick={handleClick}
                                color='secondary'
                                sx={{ textTransform: 'none' }}
                                size='large'
                            >
                                <FontAwesomeIcon icon={faUserCircle} />
                            </IconButton>
                        ) : (
                            <Button
                                disabled={auth?.loading}
                                onClick={handleClick}
                                color='secondary'
                                sx={{ textTransform: 'none' }}
                                startIcon={<AccountCircleIcon />}
                            >
                                <Typography component='span' variant='body1'>
                                    {auth?.isAuthenticated
                                        ? `${auth.userData?.firstname} ${auth.userData?.lastname}`
                                        : `Konto`}
                                </Typography>
                            </Button>
                        )}
                    </div>
                </Tooltip>

                <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    disableScrollLock
                >
                    <MenuItem>
                        <Link className={s.menuLink} to={NavigationNames.Profile}>
                            Moje konto
                        </Link>
                    </MenuItem>
                    <MenuItem className={s.menuLink}>
                        <Link
                            className={s.menuLink}
                            to={`${NavigationNames.Profile}/${ProfileRoutesNames.OrderHistory}`}
                        >
                            Historia zamówień
                        </Link>
                    </MenuItem>
                    <MenuItem className={s.menuLink} onClick={handleLogout}>
                        Wyloguj
                    </MenuItem>
                </Menu>
            </div>

            <LoginDialog open={isLoginDialogOpen} handleClose={() => setIsLoginDialogOpen(false)} />
        </>
    );
};

export default AccountDropdown;
