import SubPage from 'components/SubPage/SubPage';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import backgroundImg from 'assets/subpage-images/products.png';
import Layout from 'components/Layout/Layout';
import { gql, useQuery } from '@apollo/client';
import { IProductCategory } from 'types/types';
import { Route, Routes } from 'react-router-dom';
import Loading from 'components/Loading/Loading';
import CategorySelect from '../CategorySelect/CategorySelect';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import ProductSelect from 'pages/ProductsPage/ProductSelect/ProductSelect';

type Props = {
    id: number;
    name: string;
    description?: string;
};

interface IProductSubcategoriesData {
    productSubcategories: {
        data: IProductCategory[];
    };
}

const GET_SUBCATEGORIES = gql`
    query GetProductCategories($categoryId: ID!) {
        productSubcategories(pagination: { limit: -1 }, filters: { category: { id: { eq: $categoryId } } }) {
            data {
                id
                attributes {
                    name
                    url
                    description
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ProductCategory = ({ id, name, description }: Props) => {
    const { loading, error, data } = useQuery<IProductSubcategoriesData>(GET_SUBCATEGORIES, {
        variables: { categoryId: id },
    });

    return (
        <>
            <Helmet>
                <title>{name[0].toUpperCase() + name.slice(1).toLowerCase()}</title>
                <meta name='description' content={description} />
                <link rel='canonical' href={window.location.href} />
            </Helmet>

            <Routes>
                <Route path='/'>
                    <Route
                        index
                        element={
                            <SubPage title={name} image={backgroundImg}>
                                {loading && <Loading />}
                                {!loading && data && (
                                    <CategorySelect
                                        showReturnButton
                                        categories={data.productSubcategories.data}
                                        description={description}
                                    />
                                )}
                            </SubPage>
                        }
                    />

                    {data?.productSubcategories.data.map(({ id, attributes }) => (
                        <Route
                            key={id}
                            path={`${attributes.url}/*`}
                            element={
                                <ProductSelect
                                    id={id}
                                    description={attributes.description}
                                    subcategoryName={attributes.name}
                                />
                            }
                        />
                    ))}

                    {!loading && <Route path='*' element={<NotFoundPage showLayout={false} />} />}
                </Route>
            </Routes>
        </>
    );
};

export default ProductCategory;
