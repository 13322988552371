import Typography from '@mui/material/Typography';
import React from 'react';
import s from './ShopCategoriesListItem.module.scss';
import { Link } from 'react-router-dom';
import { Category } from 'types/prestashopApi';

type Props = { category: Category };

const ShopCategoriesListItem = ({ category }: Props) => {
    return (
        <Link to={`${category.id}-${category.link_rewrite}`} state={{ categoryName: category.name }} className={s.link}>
            <div className={s.card}>
                <Typography component='h3' variant='h6'>
                    {category.name}
                </Typography>

                <Typography component='p' variant='body1'>
                    {category.associations?.products?.length || '0'} Produkty
                </Typography>

                <div className={s.bottomBar} />
            </div>

            <div className={s.cardCopy}>
                <Typography component='h3' variant='h6'>
                    {category.name}
                </Typography>

                <Typography component='p' variant='body1'>
                    {category.associations?.products?.length || '0'} Produkty
                </Typography>

                <div className={s.callToActionBar}>
                    <Typography component='p' variant='body1' fontWeight={'bold'}>
                        ZOBACZ
                    </Typography>
                    <Typography component='p' variant='body1'>
                        {category.associations?.products?.length || '0'} Produkty
                    </Typography>
                </div>
            </div>
        </Link>
    );
};

export default ShopCategoriesListItem;
