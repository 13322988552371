import React, { useRef } from 'react';
import s from './UncontrolledNumberInput.module.scss';
import { IconButton, TextField } from '@mui/material';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    value: number;
    onChange: (value: number) => void;
    max?: number;
    min?: number;
    disabled?: boolean;
    className?: string;
    size?: 'small' | 'medium';
};

const UncontrolledNumberInput = ({ value, max, min = 1, disabled, onChange, className, size = 'medium' }: Props) => {
    const inputRef = useRef<any>(null);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        let newValue = parseInt(e.target.value);

        onChange(newValue);
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            let newValue = parseInt(inputRef.current.value);
            onChange(newValue);
        }
    };

    const decrementValue = () => {
        let newValue = value - 1;

        if (min !== undefined && newValue < min) newValue = min;

        onChange(newValue);
        inputRef.current.value = newValue;
    };

    const incrementValue = () => {
        let newValue = value + 1;

        if (max !== undefined && newValue > max) newValue = max;

        onChange(newValue);
        inputRef.current.value = newValue;
    };

    return (
        <div className={`${s.container} ${className}`}>
            <IconButton onClick={decrementValue} disabled={disabled} size={size}>
                <FontAwesomeIcon icon={faMinus} />
            </IconButton>
            <TextField
                inputRef={inputRef}
                className={s.input}
                inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    min,
                    max,
                    inputprops: {
                        style: {
                            '-webkit-appearance': 'none',
                            '-moz-appearance': 'textfield',
                        } as React.CSSProperties,
                    },
                }}
                defaultValue={value}
                fullWidth={false}
                size='small'
                onBlur={handleBlur}
                onKeyUp={handleKeyUp}
                disabled={disabled}
            />
            <IconButton onClick={incrementValue} disabled={disabled} size={size}>
                <FontAwesomeIcon icon={faPlus} />
            </IconButton>
        </div>
    );
};

export default UncontrolledNumberInput;
