import React, { Fragment } from 'react';
import s from './FeaturedProducts.module.scss';
import { IAccessories, IAccessory, IRelatedProduct, IRelatedProducts } from 'types/types';
import Divider from '@mui/material/Divider';
import { addStrapiUrl } from 'utils/strapiUtils';
import { Link } from 'react-router-dom';

type Props = {
    products: IAccessories | IRelatedProducts;
    title: string;
};

const FeaturedProducts = ({ products, title }: Props) => {
    return (
        <div className={s.container}>
            <h5 className={s.title}>{title}</h5>
            <div className={s.content}>
                {products.data.map((product: IAccessory | IRelatedProduct) => {
                    const url = `/produkty/${product.attributes.subcategory.data.attributes.category.data.attributes.url}/${product.attributes.subcategory.data.attributes.url}/${product.attributes.url}`;

                    return (
                        <Fragment key={product.id}>
                            <Link to={url} className={s.product}>
                                <img
                                    src={addStrapiUrl(product.attributes.images.data[0].attributes.url)}
                                    alt={product.attributes.images.data[0].attributes.alternativeText}
                                    className={s.productImage}
                                />
                                <h6 className={s.productName}>{product.attributes.name}</h6>
                            </Link>

                            <Divider />
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default FeaturedProducts;
