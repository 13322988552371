import React, { useEffect, useState } from 'react';
import s from './ShopCategoryPage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import useFetch from 'hooks/useFetch';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { CategoryProductsApiResponse } from 'types/prestashopREST/CategoryProduct';
import ProductListTopBar from './ProductListTopBar/ProductListTopBar';
import ShopProductCard from '../../../components/ShopComponents/ShopProductCard/ShopProductCard';
import Sidebar from './SideBar/Sidebar';
import { Alert, Pagination } from '@mui/material';
import Loading from 'components/Loading/Loading';
import { Product } from 'types/prestashopREST/MainShopPage/ProductListing';

type Props = {};

const DEFAULT_SORT_ORDER = 'product.position.asc';

export const getCategoryName = (name: string | undefined) => {
    const HOME_PAGE_NAME = 'Strona główna';

    if (!name) return null;

    if (name === HOME_PAGE_NAME) return 'Wszystkie produkty';
    return name;
};

const ShopCategoryPage = (props: Props) => {
    let { categoryId } = useParams();

    const [selectedFiltersFacet, setSelectedFiltersFacet] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<string>(DEFAULT_SORT_ORDER);
    const [page, setPage] = useState<number>(1);

    const fetchUrl = `${process.env.REACT_APP_PRESTASHOP_URL}/rest/categoryProducts?page=${page}&image_size=home_default&id_category=${categoryId}&with_all_images=0&with_category_tree=true&q=${selectedFiltersFacet}&order=${sortOrder}`;
    const { data, loading, error, firstLoading } = useFetch<CategoryProductsApiResponse>(fetchUrl);

    const clearFilters = () => {
        const currentSortOrder = data?.psdata.sort_orders.find((sortOrder) => sortOrder.current);
        setSortOrder(currentSortOrder?.urlParameter || '');
        setPage(0);
        setSelectedFiltersFacet('');
    };

    useEffect(() => {
        if (!data) return;

        const currentSortOrder = data?.psdata.sort_orders.find((sortOrder) => sortOrder.current);
        setSortOrder(currentSortOrder?.urlParameter || '');
        // do analizy
        setPage(0);
        setSelectedFiltersFacet('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId]);

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <Helmet>
                <title>{getCategoryName(data?.psdata?.name) || 'Sklep'}</title>
                <meta name='description' content={`Sklep Poland Optical`} />
            </Helmet>

            <Layout>
                <SubPage
                    loading={firstLoading}
                    title={getCategoryName(data?.psdata?.name) || ''}
                    currentPageName={getCategoryName(data?.psdata?.name) || ''}
                >
                    {data?.success ? (
                        <div className={s.container}>
                            <Sidebar
                                data={data}
                                setSelectedFiltersFacet={setSelectedFiltersFacet}
                                clearFilters={clearFilters}
                                loading={loading}
                            />

                            <div className={s.mainContainer}>
                                <ProductListTopBar
                                    apiResponse={data}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    sortOrders={data?.psdata.sort_orders || []}
                                />
                                {loading && <Loading />}
                                {!loading && data && (
                                    <>
                                        {error && (
                                            <Alert severity='error'>
                                                <span>Wystąpił błąd! </span>
                                                <span>Komunikat: {error.message}</span>
                                            </Alert>
                                        )}

                                        <div className={s.productsListContainer}>
                                            {data.psdata.products.length === 0 ? (
                                                <Alert severity='info'>Brak produktów do wyświetlenia</Alert>
                                            ) : (
                                                <ul className={s.productsList}>
                                                    {data.psdata.products.map((product: Product) => (
                                                        <ShopProductCard
                                                            key={product.id_product}
                                                            product={product}
                                                            link={`${product.id_product}`}
                                                        />
                                                    ))}
                                                </ul>
                                            )}

                                            <div className={s.paginationContainer}>
                                                <Pagination
                                                    className={s.pagination}
                                                    color='primary'
                                                    size='large'
                                                    count={data.psdata.pagination.pages_count}
                                                    page={data.psdata.pagination.current_page}
                                                    onChange={handlePaginationChange}
                                                    showFirstButton
                                                    showLastButton
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ) : (
                        <Alert severity='error'>{data?.message}</Alert>
                    )}
                </SubPage>
            </Layout>
        </>
    );
};

export default ShopCategoryPage;
