import React from 'react';
import { IServiceArticle } from 'types/types';
import s from './ServiceArticlesList.module.scss';
import ServiceArticlesItem from './ServiceArticlesItem/ServiceArticlesItem';
import Typography from '@mui/material/Typography';

interface Props {
    articles: IServiceArticle[];
    showBlogButton?: boolean;
}

const ServiceArticlesList = ({ articles, showBlogButton = false }: Props) => {
    return (
        <div className={s.container}>
            <Typography variant='h5' component='h2' className={s.heading}>
                Aktualności
            </Typography>
            <ul className={s.list}>
                {articles.length === 0 && <li className={s.noNews}>Brak nowych aktualności</li>}
                {articles.map((article: IServiceArticle) => (
                    <ServiceArticlesItem article={article} key={article.id} />
                ))}
            </ul>
        </div>
    );
};

export default ServiceArticlesList;
