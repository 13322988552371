import React from 'react';
import s from './ContactSection.module.scss';
import BeanButton from 'components/BeanButton/BeanButton';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { Typography } from '@mui/material';

type Props = {};

const ContactSection = (props: Props) => {
    return (
        <section className={s.contactSection}>
            <Typography variant='h4' component='h2' className={s.title}>
                Bądźmy w kontakcie!
            </Typography>
            <BeanButton text='Skontaktuj się' link={NavigationNames.Contact} color='white' variant='outlined' />
        </section>
    );
};

export default ContactSection;
