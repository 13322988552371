import React from 'react';
import s from './ProductAddedDialog.module.scss';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { CartInfo } from 'types/prestashopREST/Cart';
import { useNavigate } from 'react-router-dom';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { ShopRoutesEnum } from 'Router/ShopRoutes';

type Props = { open: boolean; handleClose: () => void; productId: number | null; cartItems: CartInfo | null };

const ProductAddedDialog = ({ open, handleClose, productId, cartItems }: Props) => {
    const navigate = useNavigate();
    // eslint-disable-next-line eqeqeq
    const addedProduct = cartItems?.products.find((product) => product.id_product == productId);

    const handleOrder = () => {
        navigate(`${NavigationNames.Shop}${ShopRoutesEnum.CartSummary}`);
        handleClose();
    };

    const theme = useTheme();

    return (
        <Dialog open={open} onClose={handleClose} fullScreen={useMediaQuery(theme.breakpoints.down('md'))}>
            <DialogTitle>Produkt został dodany do koszyka</DialogTitle>
            {addedProduct && (
                <DialogContent className={s.dialogContent}>
                    <img src={addedProduct.image_url} alt={addedProduct.name} />
                    {addedProduct.name}
                </DialogContent>
            )}

            <DialogActions disableSpacing className={s.dialogActions}>
                <Button
                    className={s.button}
                    onClick={handleClose}
                    color='secondary'
                    variant='outlined'
                    startIcon={<FontAwesomeIcon icon={faShoppingBasket} />}
                >
                    Kontynuuj zakupy
                </Button>
                <Button className={s.button} variant='contained' onClick={handleOrder}>
                    Przejdź do realizacji zamówienia
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProductAddedDialog;
