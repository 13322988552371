import { Stepper, Step, StepLabel, Typography, StepContent } from '@mui/material';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import React, { useEffect, useState } from 'react';
import AddressStep from './AddressStep/AddressStep';
import { ICheckoutStep } from './OrderCheckout.types';
import StepNavigationButtons, { CheckoutStepsEnum } from './StepNavigationButtons/StepNavigationButtons';
import ShippingStep from './ShippingStep/ShippingStep';
import PaymentStep from './PaymentStep/PaymentStep';
import PersonalDataStep from './PersonalDataStep/PersonalDataStep';
import { useAuth } from 'contexts/AuthContext';
import { useCart } from 'contexts/CartContext';
import { useSnackbar } from 'contexts/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { ShopRoutesEnum } from 'Router/ShopRoutes';

type Props = {};

const OrderCheckout = (props: Props) => {
    const auth = useAuth();
    const cart = useCart();
    const snackbar = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        if (cart?.cartInfo?.products_count === 0) {
            navigate(`${NavigationNames.Shop}${ShopRoutesEnum.CartSummary}`);
            snackbar.openSnackbar('Koszyk jest pusty', 'warning');
        }
    }, [cart]);

    const [activeStep, setActiveStep] = useState<number>(
        auth?.isAuthenticated ? CheckoutStepsEnum.Address : CheckoutStepsEnum.PersonalData
    );

    const steps: ICheckoutStep[] = [
        {
            label: 'Dane osobowe',
            content: <PersonalDataStep nextStep={() => setActiveStep(activeStep + 1)} />,
        },
        {
            label: 'Adresy',
            content: <AddressStep />,
        },
        {
            label: 'Sposób dostawy',
            content: <ShippingStep />,
        },
        {
            label: 'Płatność',
            content: <PaymentStep />,
        },
    ];

    return (
        <Layout>
            <SubPage title='Zamówienie'>
                <Stepper activeStep={activeStep} orientation='vertical'>
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                <Typography variant='h4' component='h5'>
                                    {step.label}
                                </Typography>
                            </StepLabel>
                            <StepContent>
                                {step.content}
                                <StepNavigationButtons
                                    activeStep={activeStep}
                                    setActiveStep={setActiveStep}
                                    steps={steps}
                                />
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </SubPage>
        </Layout>
    );
};

export default OrderCheckout;
