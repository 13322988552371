import React, { ReactNode } from 'react';
import s from './SummaryItem.module.scss';
import { Typography } from '@mui/material';

type Props = { title: string; value: string | number | ReactNode };

const SummaryItem = ({ title, value }: Props) => {
    return (
        <div className={s.container}>
            <Typography component='h3' variant='h6'>
                {title}
            </Typography>
            {typeof value === 'string' || typeof value === 'number' ? <Typography>{value}</Typography> : value}
        </div>
    );
};

export default SummaryItem;
