import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import { Form, Formik, FormikHelpers } from 'formik';
import useFetch from 'hooks/useFetch';
import * as Yup from 'yup';
import React from 'react';
import s from './ProfileSettings.module.scss';
import { Button, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ApiResponse, genderEnum } from 'types/prestashopREST/AccountInformation';
import { useAuth } from 'contexts/AuthContext';
import { format } from 'date-fns';
import { useSnackbar } from 'contexts/SnackbarContext';

type Props = {};

interface IFormValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    // birthday: string | undefined;
    gender: genderEnum;
}

const ProfileSettings = (props: Props) => {
    const { data, loading, error } = useFetch<ApiResponse>(`${process.env.REACT_APP_PRESTASHOP_URL}/rest/accountInfo`);
    const auth = useAuth();
    const snackbar = useSnackbar();

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Imię jest wymagane'),
        lastName: Yup.string().required('Nazwisko jest wymagane'),
        email: Yup.string().email('Niepoprawny email').required('Aby edytować dane, podaj aktualny email'),
        password: Yup.string().required('Aby edytować dane, podaj aktualne hasło'),
        // birthday: Yup.date(),
        gender: Yup.number().required('Tytuł jest wymagany'),
    });

    const handeSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PRESTASHOP_URL}/rest/accountedit`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                body: JSON.stringify(values),
            });

            const responseData: ApiResponse = await response.json();

            if (!responseData.success) {
                console.error(responseData);
                return;
            }

            auth?.setUserData((prevState) => {
                if (prevState === null) return null;

                const newState = {
                    ...prevState,
                    firstname: values.firstName,
                    lastname: values.lastName,
                    id_gender: values.gender,
                };

                // if (values.birthday) {
                //     newState.birthday = values.birthday;
                // }

                return newState;
            });

            snackbar.openSnackbar('Profil został edytowany');
        } catch (err) {
            console.error(err);

            if (err instanceof Error) {
                snackbar.openSnackbar(err.message, 'error');
            }
        }

        setSubmitting(false);
    };

    return (
        <Layout>
            <SubPage loading={loading} title='Ustawienia konta'>
                <Formik
                    initialValues={{
                        firstName: data?.psdata.firstname || '',
                        lastName: data?.psdata.lastname || '',
                        email: data?.psdata.email || '',
                        password: '',
                        // birthday: data?.psdata.birthday,
                        gender: data?.psdata.id_gender,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handeSubmit}
                >
                    {({
                        submitForm,
                        isSubmitting,
                        getFieldProps,
                        values,
                        setFieldValue,
                        touched,
                        errors,
                        handleChange,
                    }) => (
                        <Form className={s.form}>
                            <div className={s.fullColSpan}>
                                <FormLabel id='gender-radio-buttons-group-label'>Tytuł</FormLabel>
                                <RadioGroup
                                    aria-labelledby='gender-radio-buttons-group-label'
                                    value={values.gender}
                                    name='gender'
                                    row
                                >
                                    <FormControlLabel
                                        value={genderEnum.Male}
                                        onChange={handleChange}
                                        control={<Radio />}
                                        label='Pan'
                                    />
                                    <FormControlLabel
                                        value={genderEnum.Female}
                                        onChange={handleChange}
                                        control={<Radio />}
                                        label='Pani'
                                    />
                                </RadioGroup>
                            </div>

                            <TextField
                                {...getFieldProps('firstName')}
                                name='firstName'
                                type='text'
                                label='Imię'
                                fullWidth
                                error={Boolean(touched.firstName && errors.firstName)}
                                helperText={touched.firstName && errors.firstName}
                            />

                            <TextField
                                {...getFieldProps('lastName')}
                                name='lastName'
                                type='text'
                                label='Nazwisko'
                                fullWidth
                                error={Boolean(touched.lastName && errors.lastName)}
                                helperText={touched.lastName && errors.lastName}
                            />

                            <TextField
                                {...getFieldProps('email')}
                                name='email'
                                type='email'
                                label='Email'
                                fullWidth
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />

                            <TextField
                                {...getFieldProps('password')}
                                type='password'
                                placeholder='Aby edytować dane, podaj aktualne hasło'
                                label='Hasło'
                                name='password'
                                fullWidth
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                            />
                            {/* 
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    onChange={(value) => setFieldValue('birthday', value, true)}
                                    value={values.birthday}
                                    renderInput={(params) => (
                                        <TextField
                                            label='Data urodzenia'
                                            margin='normal'
                                            name='birthday'
                                            error={Boolean(touched.birthday && errors.birthday)}
                                            helperText={touched.birthday && errors.birthday}
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider> */}
                            <Button
                                className={s.fullColSpan}
                                variant='contained'
                                color='primary'
                                disabled={isSubmitting}
                                onClick={submitForm}
                                fullWidth
                                type='submit'
                            >
                                Zapisz zmiany
                            </Button>
                        </Form>
                    )}
                </Formik>
            </SubPage>
        </Layout>
    );
};

export default ProfileSettings;
