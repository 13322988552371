import React from 'react';
import s from './PasswordResetPage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';

import EmailForm from './EmailForm/EmailForm';
import { Typography } from '@mui/material';
import NewPasswordForm from './NewPasswordForm/NewPasswordForm';

const PasswordResetPage = () => {
    // const [emailSent, setEmailSent] = React.useState(false);
    const [email, setEmail] = React.useState<string | null>(null);

    return (
        <Layout>
            <SubPage showBreadcrumbs={false}>
                <div className={s.container}>
                    <Typography variant='h3' component='h1'>
                        Przypomnienie hasła
                    </Typography>

                    <Typography>
                        {email
                            ? 'Podaj kod, który otrzymałeś w wiadomości email oraz nowe hasło.'
                            : 'Na podany adres zostanie wysłany tymczasowy link umożliwiający zresetowanie hasła.'}
                    </Typography>

                    {!email ? <EmailForm setEmail={setEmail} /> : <NewPasswordForm email={email} />}
                </div>
            </SubPage>
        </Layout>
    );
};

export default PasswordResetPage;
