import React from 'react';
import s from './OrderSummary.module.scss';
import { Button, Divider, Paper, Typography } from '@mui/material';
import { useCart } from 'contexts/CartContext';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { ShopRoutesEnum } from 'Router/ShopRoutes';
import { Link } from 'react-router-dom';

type Props = { hasCartErrors: boolean | undefined; loading: boolean | undefined };

const OrderSummary = ({ hasCartErrors, loading }: Props) => {
    const cart = useCart();

    const allowOrder: boolean = cart?.cartInfo?.products_count !== 0 && hasCartErrors === false && !loading;

    return (
        <Paper className={s.container}>
            <Typography className={s.header} variant='h5' component='h2'>
                Podsumowanie
            </Typography>

            <Divider />

            <div className={s.innerContainer}>
                <Typography>{cart?.cartInfo?.subtotals.products.label}</Typography>
                <Typography fontWeight={600}>{cart?.cartInfo?.subtotals.products.value}</Typography>
            </div>

            <div className={s.innerContainer}>
                <Typography>{cart?.cartInfo?.subtotals.shipping.label}</Typography>
                <Typography fontWeight={600}>{cart?.cartInfo?.subtotals.shipping.value}</Typography>
            </div>

            <Divider />

            <div className={s.innerContainer}>
                <Typography variant='body1' fontWeight={600}>
                    {cart?.cartInfo?.totals.total.label}
                </Typography>
                <Typography variant='h6' fontWeight={600}>
                    {cart?.cartInfo?.totals.total.value}
                </Typography>
            </div>

            <Divider />

            <div className={s.buttonContainer}>
                <Button
                    component={Link}
                    to={`${NavigationNames.Shop}${ShopRoutesEnum.Order}`}
                    fullWidth
                    color='secondary'
                    variant='contained'
                    disabled={!allowOrder}
                >
                    Złóż zamówienie
                </Button>
            </div>
        </Paper>
    );
};

export default OrderSummary;
