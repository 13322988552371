import React, { useRef, useState } from 'react';
import s from './ShoppingCart.module.scss';
import { ReactComponent as ShoppingCartIcon } from 'assets/icons/shopping_basket_FILL0_wght400_GRAD0_opsz20.svg';
import Badge from '@mui/material/Badge';
import { Button, Card, Divider, Fade, IconButton, Typography, useTheme } from '@mui/material';
import { useCart } from 'contexts/CartContext';
import { Link } from 'react-router-dom';
import ShoppingCartItem from './ShoppingCartItems/ShoppingCartItem';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { ShopRoutesEnum } from 'Router/ShopRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';

type Props = { mobile?: boolean };

const cartCountLabel = (count: number): string => {
    if (count === 0) {
        return 'Pusty koszyk';
    }
    if (count > 99) {
        return 'Ponad 99 produktów w koszyku';
    }
    return `${count} produktów w koszyku`;
};

const ShoppingCart = ({ mobile = false }: Props) => {
    const theme = useTheme();
    const cart = useCart();

    const cartButtonRef = useRef(null);
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    return (
        <div className={s.container} onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
            <Link to={`${NavigationNames.Shop}${ShopRoutesEnum.CartSummary}`} ref={cartButtonRef}>
                <IconButton
                    disabled={cart?.loading}
                    aria-label={cartCountLabel(cart?.cartInfo?.products_count || 0)}
                    size='large'
                >
                    <Badge
                        badgeContent={cart?.cartInfo?.products_count}
                        max={99}
                        sx={{
                            '& .MuiBadge-badge': {
                                background: theme.gradients.pink,
                                color: 'white',
                            },
                        }}
                    >
                        {mobile ? (
                            <FontAwesomeIcon icon={faShoppingBasket} color={theme.palette.secondary.main} />
                        ) : (
                            <ShoppingCartIcon />
                        )}
                    </Badge>
                </IconButton>
            </Link>

            {!mobile && cart?.cartInfo && (
                <Fade className={s.zoomContainer} in={mouseOver} mountOnEnter unmountOnExit>
                    <Card className={s.cartContent}>
                        <ul className={s.cartItemsContainer}>
                            {cart?.cartInfo?.products.map((product) => (
                                <ShoppingCartItem product={product} key={product.id_product} />
                            ))}
                        </ul>
                        <div className={s.infoContainer}>
                            <div className={s.infoInnerContainer}>
                                <Typography>{cart?.cartInfo?.summary_string}</Typography>
                                <Typography fontWeight={600}>{cart?.cartInfo?.totals.total.value}</Typography>
                            </div>
                            <div className={s.infoInnerContainer}>
                                <Typography>{cart?.cartInfo?.subtotals.shipping.label}</Typography>
                                <Typography fontWeight={600}>{cart?.cartInfo?.subtotals.shipping.value}</Typography>
                            </div>
                        </div>
                        <Divider />
                        <div className={s.summary}>
                            <Typography variant='body1' fontWeight={600}>
                                {cart?.cartInfo?.totals.total.label}
                            </Typography>
                            <Typography variant='body1' fontWeight={600}>
                                {cart?.cartInfo?.totals.total.value}
                            </Typography>
                        </div>

                        <Divider />

                        <div className={s.buttonContainer}>
                            <Button
                                component={Link}
                                to={`${NavigationNames.Shop}${ShopRoutesEnum.CartSummary}`}
                                fullWidth
                                color='secondary'
                                variant='contained'
                                disabled={cart?.cartInfo?.products_count === 0}
                            >
                                Finalizacja Zamówienia
                            </Button>
                        </div>
                    </Card>
                </Fade>
            )}
        </div>
    );
};

export default ShoppingCart;
