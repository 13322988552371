import React from 'react';
import { IServiceArticle } from 'types/types';
import s from './ServiceArticlesItem.module.scss';
import dayjs from 'dayjs';
import { Chip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import BeanButton from 'components/BeanButton/BeanButton';
import { addStrapiUrl } from 'utils/strapiUtils';
import { Link } from 'react-router-dom';

interface Props {
    article: IServiceArticle;
}

const ServiceArticlesItem = ({ article }: Props) => {
    return (
        <li className={[s.container, article.attributes.image.data ? '' : s.noImage].join(' ')}>
            <div className={s.top}>
                {article.attributes.image.data && (
                    <img
                        className={s.image}
                        src={addStrapiUrl(article.attributes.image.data.attributes.url)}
                        alt={article.attributes.image.data.attributes.alternativeText}
                    />
                )}
                <div className={s.textContainer}>
                    <Link to={`${article.id}`} className={s.titleLink}>
                        <Typography variant='h6' component='h4' className={s.title}>
                            {article.attributes.title}
                        </Typography>
                    </Link>
                    <div className={s.date}>
                        <Chip
                            label={dayjs(article.attributes.createdAt).format('DD.MM.YYYY')}
                            icon={<FontAwesomeIcon icon={faCalendarDays} size='lg' />}
                            sx={{ padding: '.5rem', width: 'fit-content' }}
                        />
                    </div>
                    {article.attributes.description && (
                        <Typography variant='body1' className={s.description}>
                            {article.attributes.description}
                        </Typography>
                    )}
                    <BeanButton
                        variant='contained'
                        color='black'
                        iconEnd={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                        link={`${article.id}`}
                        text='Czytaj'
                        fullWidth
                    />
                </div>
            </div>
        </li>
    );
};

export default ServiceArticlesItem;
