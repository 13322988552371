import { faArrowLeft, faArrowRight, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { IProductVideos } from 'types/types';
import s from './ProductMedia.module.scss';
import { PrestaImage } from 'types/prestashopREST/ProductDetail';

type Props = {
    coverImageUrl: string;
    images: PrestaImage[];
    videos?: IProductVideos;
};

const ProductMedia = ({ images, coverImageUrl, videos }: Props) => {
    const [selectedImageSrc, setSelectedImageSrc] = useState<string>(coverImageUrl);
    const [selectedImageAlt, setSelectedImageAlt] = useState<string>(images[0]?.alt);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    const [isImageOpen, setIsImageOpen] = useState<boolean>(false);

    // states reset after changing product
    useEffect(() => {
        if (images.length === 0) return;

        setSelectedImageSrc(coverImageUrl);
        setSelectedImageAlt(images[0].alt);
        setSelectedImageIndex(0);
    }, [images]);

    // image navigation
    const changeImage = useCallback(
        (shift: number) => {
            setSelectedImageSrc(images[selectedImageIndex + shift].src);
            setSelectedImageAlt('');
            setSelectedImageIndex(selectedImageIndex + shift);
        },
        [images, selectedImageIndex]
    );

    useEffect(() => {
        const handleImageNavigation = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft' && selectedImageIndex > 0) {
                changeImage(-1);
            } else if (e.key === 'ArrowRight' && selectedImageIndex < images.length - 1) {
                changeImage(1);
            }
        };

        if (isImageOpen) {
            window.addEventListener('keydown', handleImageNavigation);
            return () => window.removeEventListener('keydown', handleImageNavigation);
        }
    }, [isImageOpen, images, selectedImageIndex, changeImage]);

    return (
        <div className={s.productMedia}>
            <div className={s.imageContainer}>
                <img
                    src={selectedImageSrc}
                    alt={selectedImageAlt}
                    className={s.image}
                    onClick={() => setIsImageOpen(true)}
                />
            </div>

            <div className={s.mediaCarouselContainer}>
                <div className={s.mediaCarousel}>
                    {images.length > 0 &&
                        images.map((image, index) => (
                            <img
                                src={image.src}
                                alt={image.alt}
                                className={[s.carouselImage, selectedImageSrc === image.src ? s.selected : null].join(
                                    ' '
                                )}
                                draggable={false}
                                onClick={() => {
                                    setSelectedImageSrc(image.src);
                                    setSelectedImageAlt(image.alt);
                                    setSelectedImageIndex(index);
                                }}
                                key={index}
                            />
                        ))}
                </div>
            </div>

            <Modal className={s.modal} open={isImageOpen} onClose={() => setIsImageOpen(false)} disableScrollLock>
                <div className={s.modalImageBackground}>
                    <FontAwesomeIcon
                        className={s.modalImageClose}
                        icon={faClose}
                        onClick={() => setIsImageOpen(false)}
                    />
                    {selectedImageIndex !== 0 && (
                        <div
                            className={s.imageNavigationContainerLeft}
                            onClick={() => selectedImageIndex !== 0 && changeImage(-1)}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className={s.imageArrow} />
                        </div>
                    )}

                    <img className={s.modalImage} src={selectedImageSrc} alt={selectedImageAlt} />

                    {selectedImageIndex < images.length - 1 && (
                        <div
                            className={s.imageNavigationContainerRight}
                            onClick={() => selectedImageIndex < images.length - 1 && changeImage(1)}
                        >
                            <FontAwesomeIcon icon={faArrowRight} className={s.imageArrow} />
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default ProductMedia;
