import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import React from 'react';
import s from './FinancePage.module.scss';
import { gql, useQuery } from '@apollo/client';
import Loading from 'components/Loading/Loading';
import RichTextDisplay from 'components/RichTextDisplay/RichTextDisplay';
import { Helmet } from 'react-helmet-async';

type Props = {};

const GET_PAGE_CONTENT = gql`
    query getFinancingPageContent {
        financingPage {
            data {
                attributes {
                    content
                }
            }
        }
    }
`;

const FinancePage = (props: Props) => {
    const { loading, data, error } = useQuery(GET_PAGE_CONTENT);

    if (loading) return <Loading />;

    if (!loading && error) return <h1>Nie udało się pobrać strony</h1>;

    return (
        <>
            <Helmet>
                <title>Finansowanie</title>
            </Helmet>
            <Layout>
                <SubPage title='Finansowanie'>
                    <div className={s.container}>
                        <RichTextDisplay html={data.financingPage.data.attributes.content} />
                    </div>
                </SubPage>
            </Layout>
        </>
    );
};

export default FinancePage;
