import React from 'react';
import s from './PaymentStep.module.scss';
import useFetch from 'hooks/useFetch';
import { GetPaymentMethodsApiResponse } from 'types/prestashopREST/Checkout/GetPaymentMethods';
import Alert from '@mui/material/Alert/Alert';
import PaymentMethodCard from './PaymentMethodCard/PaymentMethodCard';
import { useCheckout } from 'contexts/CheckoutContext';
import { FormControlLabel, Checkbox, Typography, Divider, LinearProgress } from '@mui/material';

type Props = {};

const PaymentStep = (props: Props) => {
    const checkout = useCheckout();
    const { data, loading, error } = useFetch<GetPaymentMethodsApiResponse>(
        `${process.env.REACT_APP_PRESTASHOP_URL}/rest/paymentoptions`
    );

    const handleAgreementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkout?.setAgreementChecked(event.target.checked);
    };

    if (loading) return <LinearProgress />;
    return (
        <div className={s.cards}>
            <Typography variant='h6' component='h6'>
                Metody płatności
            </Typography>
            {Object.keys(data?.psdata || {}).length === 0 && (
                <Alert severity='warning'>Brak dostępnych metod płatności</Alert>
            )}
            {Object.keys(data?.psdata || {}).map((key) => {
                if (!data || data.psdata[key].length === 0) return null;
                return (
                    <PaymentMethodCard
                        paymentOption={data.psdata[key][0]}
                        selectedPaymentOption={checkout?.selectedPaymentOption || null}
                        key={key}
                    />
                );
            })}

            <Divider sx={{ margin: '1rem 0' }} />

            <FormControlLabel
                control={<Checkbox checked={checkout?.agreementChecked} onChange={handleAgreementChange} />}
                label='Zgadzam się z warunkami świadczenia usług i będę stosować się do nich bezwarunkowo.'
            />
        </div>
    );
};

export default PaymentStep;
