import React from 'react';
import s from './Summary.module.scss';
import { OrderDetails } from 'types/prestashopREST/OrderDetails';
import SummaryItem from './SummaryItem/SummaryItem';
import { Chip, Link as MuiLink, Typography } from '@mui/material';
import getContrastingTextColor from 'utils/getContrastingTextColor';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';

type Props = { data: OrderDetails };

const Summary = ({ data }: Props) => {
    return (
        <>
            <Typography component='h2' variant='h5'>
                Szczegóły zamówienia
            </Typography>

            <div className={s.container}>
                <SummaryItem title='Numer zamówienia' value={data.details.reference} />
                <SummaryItem title='Płatność' value={data.details.payment} />
                <SummaryItem
                    title='Status'
                    value={
                        <Chip
                            sx={{
                                background: data.history.current.color,
                                color: getContrastingTextColor(data.history.current.color),
                            }}
                            label={data.history.current.ostate_name}
                        />
                    }
                />
                <SummaryItem title='Data zamówienia' value={data.details.order_date} />
                <SummaryItem title='Dostawa' value={`${data.carrier.name} ${data.carrier.delay}`} />
                <SummaryItem
                    title='Adres dostawy'
                    value={
                        <Typography
                            variant='body1'
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(data.addresses.delivery.formatted),
                            }}
                        />
                    }
                />

                {data.history.current.invoice === '1' && (
                    <MuiLink component={Link} to={data.details.invoice_url}>
                        <FontAwesomeIcon icon={faDownload} /> Pobierz fakturę (PDF)
                    </MuiLink>
                )}
            </div>
        </>
    );
};

export default Summary;
