import React, { useState } from 'react';
import s from './ProductInfo.module.scss';
import { Button, IconButton, Typography, Tooltip, Chip } from '@mui/material';
import { ProductData } from 'types/prestashopREST/ProductDetail';
import { ReactComponent as ShoppingCartIcon } from 'assets/icons/shopping_basket_FILL0_wght400_GRAD0_opsz20.svg';
import NumberInput from 'components/NumberInput/NumberInput';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { useCart } from 'contexts/CartContext';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ShopRoutesEnum } from 'Router/ShopRoutes';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';

interface Props {
    product: ProductData;
}

const ProductInfo = ({ product }: Props) => {
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState<number>(1);
    const isAvaiable = product.quantity > 0;

    const cart = useCart();
    const handleAddToCartClick = async () => {
        await cart?.updateCartItem(product.id_product, quantity, 'up');
    };

    const handleBuyNow = async () => {
        await cart?.updateCartItem(product.id_product, quantity, 'up', 'cart_default', false);
        navigate(`${NavigationNames.Shop}${ShopRoutesEnum.CartSummary}`);
    };

    return (
        <div className={s.container}>
            <Typography variant='h4' component='h1' className={s.productName}>
                {product.name}
            </Typography>

            <div className={s.row}>
                <div className={s.pricesContainer}>
                    <Typography variant='h4' component='p' color={'primary'}>
                        {product.price} brutto
                    </Typography>
                </div>

                <div className={s.quantityContainer}>
                    {isAvaiable ? (
                        <Chip color='success' size='small' label='Produkt jest dostępny' />
                    ) : (
                        <Chip
                            icon={<FontAwesomeIcon icon={faWarning} />}
                            color='warning'
                            size='medium'
                            label='Brak na stanie'
                        />
                    )}
                </div>
            </div>

            <div className={s.row}>
                <NumberInput
                    disabled={!isAvaiable}
                    value={quantity}
                    setValue={setQuantity}
                    min={1}
                    max={product.quantity}
                />

                <div className={s.hideOnMobile}>
                    <Button
                        disabled={!isAvaiable || cart?.cartChanging}
                        fullWidth
                        startIcon={<ShoppingCartIcon />}
                        variant='outlined'
                        onClick={handleAddToCartClick}
                    >
                        Dodaj do koszyka
                    </Button>
                </div>

                <Tooltip title='Dodaj do ulubionych' arrow>
                    <div>
                        <IconButton disabled color='primary'>
                            <FontAwesomeIcon icon={faHeart} />
                        </IconButton>
                    </div>
                </Tooltip>
            </div>

            <div className={s.buttonsContainer}>
                <div className={s.hideOnDestkop}>
                    <Button
                        disabled={!isAvaiable || cart?.cartChanging}
                        fullWidth
                        startIcon={<ShoppingCartIcon />}
                        variant='outlined'
                        onClick={handleAddToCartClick}
                    >
                        Dodaj do koszyka
                    </Button>
                </div>

                <Button
                    fullWidth
                    variant='contained'
                    onClick={handleBuyNow}
                    disabled={!isAvaiable || cart?.cartChanging}
                >
                    Kup teraz
                </Button>
            </div>

            <div className={s.row}>
                <Typography
                    variant='body1'
                    component='div'
                    className={s.description}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.description_short) }}
                />
            </div>
        </div>
    );
};

export default ProductInfo;
