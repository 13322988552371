import React, { useMemo } from 'react';
import s from './ProductMainDescription.module.scss';
import './ProductMainDescription.scss';
import DOMPurify from 'dompurify';
import { STRAPI_URL } from 'utils/strapiUtils';
import { Typography } from '@mui/material';

interface Props {
    description: string;
    skipSidebar: boolean;
}

const ProductMainDescription = ({ description, skipSidebar }: Props) => {
    const fixedDescription = useMemo(() => {
        return description.replaceAll('/uploads', `${STRAPI_URL}/uploads`);
    }, [description]);

    if (!description) return null;

    return (
        <div className={[s.container, skipSidebar && s.skipSidebar].join(' ')}>
            <Typography variant='h4' component='h2' className={s.header}>
                O produkcie
            </Typography>
            {fixedDescription && (
                <div
                    className='ck-content'
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(fixedDescription),
                    }}
                />
            )}
        </div>
    );
};

export default ProductMainDescription;
