import { genderEnum } from 'types/prestashopREST/AccountInformation';

export type LoginResult = {
    success: boolean;
    errorCode?: number;
    errorMessage?: string;
};

export class LoginFailedError extends Error {
    errorCode: number;

    constructor(message: string, errorCode: number) {
        super(message);
        this.name = 'LoginFailedError';
        this.errorCode = errorCode;
    }
}

export enum LoginErrorCode {
    InvalidPassword = 304,
    AuthenticationFailed = 306,
}

export interface IRegisterData {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    gender: genderEnum;
    newsletter: number;
}
