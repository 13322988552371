import React, { useState } from 'react';
import s from './ProductInfo.module.scss';
import AskForPriceForm from './AskForPriceForm/AskForPriceForm';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEnvelope, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { IProductDownloadFiles } from 'types/types';
import DownloadsModal from './DownloadsModal/DownloadsModal';
import QuestionForm from '../../../../components/QuestionForm/QuestionForm';
import BeanButton from 'components/BeanButton/BeanButton';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';

interface Props {
    categoryName: string;
    categoryUrl: string;
    name: string;
    description: string;
    downloadFiles: IProductDownloadFiles;
}

const ProductInfo = ({ categoryName, categoryUrl, name, description, downloadFiles }: Props) => {
    const [isAskForPriceFormOpen, setAskForPriceIsFormOpen] = useState(false);
    const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);

    const [areDownloadsOpen, setAreDownloadOpen] = useState(false);

    const handleAskForPriceFormOpen = (): void => setAskForPriceIsFormOpen(true);

    const handleAskForPriceFormClose = (): void => setAskForPriceIsFormOpen(false);

    const handleQuestionFormOpen = (): void => setIsQuestionFormOpen(true);

    const handleQuestionFormClose = (): void => setIsQuestionFormOpen(false);

    const handleDownloadFilesButton = (): void => {
        setAreDownloadOpen(true);
    };

    return (
        <>
            <div className={s.productInfo}>
                <div className={s.productInfoText}>
                    <Typography
                        variant='body1'
                        component={Link}
                        to={`${NavigationNames.Products}/${categoryUrl}`}
                        className={s.productCategory}
                    >
                        {categoryName}
                    </Typography>
                    <Typography variant='h4' component='h1' className={s.productName}>
                        {name}
                    </Typography>
                    <Typography variant='body1' className={s.productDescription}>
                        {description}
                    </Typography>
                </div>
                <div className={s.buttons}>
                    <BeanButton
                        text='Do pobrania'
                        fullWidth
                        disabled={downloadFiles?.data.length === 0}
                        color='black'
                        iconEnd={<FontAwesomeIcon icon={faDownload} size='lg' />}
                        onClick={handleDownloadFilesButton}
                    />

                    <BeanButton
                        text='Zadaj pytanie'
                        fullWidth
                        color='black'
                        iconEnd={<FontAwesomeIcon icon={faQuestionCircle} size='lg' />}
                        onClick={handleQuestionFormOpen}
                    />

                    <div className={s.mainButton}>
                        <BeanButton
                            text='Zapytaj o cenę'
                            fullWidth
                            color='blue'
                            iconEnd={<FontAwesomeIcon icon={faEnvelope} size='lg' />}
                            onClick={handleAskForPriceFormOpen}
                        />
                    </div>
                </div>
            </div>

            <AskForPriceForm isOpen={isAskForPriceFormOpen} onClose={handleAskForPriceFormClose} productName={name} />
            <QuestionForm isOpen={isQuestionFormOpen} onClose={handleQuestionFormClose} />
            <DownloadsModal
                isOpen={areDownloadsOpen}
                onClose={() => setAreDownloadOpen(false)}
                downloadFiles={downloadFiles}
            />
        </>
    );
};

export default ProductInfo;
