import React from 'react';
import s from './OrderConfirmation.module.scss';
import { Alert, Link as MuiLink, Paper, Typography } from '@mui/material';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import useFetch from 'hooks/useFetch';
import { Link, useParams } from 'react-router-dom';
import { PrestashopRestApiResponse } from 'types/prestashopREST/ApiResponse';
import OrderConfirmationTable from './OrderConfirmationTable/OrderConfirmationTable';
import { OrderConfirmPSData } from 'types/prestashopREST/Checkout/OrderConfirmation';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import useBreakpoint from 'hooks/useBreakpoint';

type Props = {};

const OrderConfirmation = (props: Props) => {
    const { orderId } = useParams();

    const { data, loading, error } = useFetch<PrestashopRestApiResponse<OrderConfirmPSData>>(
        `${process.env.REACT_APP_PRESTASHOP_URL}/rest/orderhistory?id_order=${orderId}`
    );

    return (
        <Layout>
            <SubPage loading={loading} showBreadcrumbs={false}>
                {data?.success && (
                    <div className={s.container}>
                        <Alert severity='success'>Twoje zamówienie zostało potwierdzone!</Alert>

                        <Paper className={s.infoContainer}>
                            <Typography component='p' variant='h6' marginBottom='1rem'>
                                Cieszymy się, że złożyłeś zamówienie! Przesłaliśmy do Ciebie wiadomość e-mail z
                                potwierdzeniem zamówienia.
                            </Typography>
                            <Typography component='p'>
                                Twoje zamówienie zostanie wkrótce przygotowane do wysyłki. W razie jakichkolwiek pytań
                                lub potrzeby dalszych informacji, jesteśmy do Twojej dyspozycji. Skontaktuj się z naszym
                                zespołem{' '}
                                <MuiLink component={Link} to={NavigationNames.Contact}>
                                    obsługi klienta
                                </MuiLink>
                                , a chętnie Ci pomożemy.
                            </Typography>
                        </Paper>

                        <OrderConfirmationTable data={data.psdata} />
                    </div>
                )}

                {data?.success === false && <Alert severity='error'>Nie znaleziono zamówienia o podanym numerze</Alert>}
            </SubPage>
        </Layout>
    );
};

export default OrderConfirmation;
