import React from 'react';
import s from './EmailForm.module.scss';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { PrestashopRestApiResponse } from 'types/prestashopREST/ApiResponse';
import { useSnackbar } from 'contexts/SnackbarContext';
import { Button, TextField } from '@mui/material';

type Props = { setEmail: React.Dispatch<React.SetStateAction<string | null>> };

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Nieprawidłowy email').required('Email jest wymagany'),
});

interface IFormValues {
    email: string;
}

const EmailForm = ({ setEmail }: Props) => {
    const { openSnackbar } = useSnackbar();

    const handleFormSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
        const res = await fetch(`${process.env.REACT_APP_PRESTASHOP_URL}/rest/resetpasswordemail`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(values),
        });

        const data: PrestashopRestApiResponse = await res.json();

        if (data.success) {
            setEmail(values.email);
            return;
        }

        openSnackbar('Wystąpił błąd podczas wysyłania emaila. Spróbuj ponownie', 'error');
    };

    return (
        <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
            {({ submitForm, isSubmitting, getFieldProps, touched, errors }) => (
                <Form className={s.form}>
                    <TextField
                        {...getFieldProps('email')}
                        label='Adres e-mail'
                        variant='outlined'
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />
                    <Button
                        disabled={isSubmitting}
                        variant='contained'
                        color='primary'
                        onClick={submitForm}
                        type='submit'
                    >
                        Przypomnij hasło
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default EmailForm;
