import { gql, useQuery } from '@apollo/client';
import Layout from 'components/Layout/Layout';
import Loading from 'components/Loading/Loading';
import RichTextDisplay from 'components/RichTextDisplay/RichTextDisplay';
import SubPage from 'components/SubPage/SubPage';
import React from 'react';
import s from './RodoPage.module.scss';

type Props = {};

const GET_PAGE_CONTENT = gql`
    query getFinancingPageContent {
        rodo {
            data {
                attributes {
                    page_content
                }
            }
        }
    }
`;

const RodoPage = (props: Props) => {
    const { loading, data, error } = useQuery(GET_PAGE_CONTENT);

    if (loading)
        return (
            <Layout>
                <SubPage title='RODO - Obowiązek informacyjny' currentPageName='RODO - Obowiązek informacyjny'>
                    <Loading />
                </SubPage>
            </Layout>
        );

    if (!loading && error)
        return (
            <Layout>
                <SubPage title='RODO - Obowiązek informacyjny' currentPageName='RODO - Obowiązek informacyjny'>
                    <h1>Nie udało się pobrać strony</h1>
                </SubPage>
            </Layout>
        );

    return (
        <Layout>
            <SubPage title='RODO - Obowiązek informacyjny' currentPageName='RODO - Obowiązek informacyjny'>
                <div className={s.container}>
                    <RichTextDisplay html={data.rodo.data.attributes.page_content} />
                </div>
            </SubPage>
        </Layout>
    );
};

export default RodoPage;
