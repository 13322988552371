import { Typography } from '@mui/material';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import useFetch from 'hooks/useFetch';
import React from 'react';
import { useParams } from 'react-router-dom';
import { PrestashopRestApiResponse } from 'types/prestashopREST/ApiResponse';
import { OrderDetails } from 'types/prestashopREST/OrderDetails';
import Summary from './Summary/Summary';
import OrderedProducts from './OrderedProducts/OrderedProducts';

type Props = {};

const OrderDetailsPage = (props: Props) => {
    const { id } = useParams<{ id: string }>();

    const URL = `${process.env.REACT_APP_PRESTASHOP_URL}/rest/orderhistory?id_order=${id}`;

    const { data, loading, error } = useFetch<PrestashopRestApiResponse<OrderDetails>>(URL);

    return (
        <Layout>
            <SubPage
                title={`Szczegóły zamówienia ${data?.psdata.details.reference}`}
                loading={loading}
                currentPageName={`Zamówienie ${data?.psdata.details.reference}`}
                previousPageName='Historia zamówień'
            >
                {data && (
                    <>
                        <Summary data={data.psdata} />
                        <OrderedProducts data={data.psdata} />
                    </>
                )}
            </SubPage>
        </Layout>
    );
};

export default OrderDetailsPage;
