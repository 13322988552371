import React, { createContext, useContext, useEffect, useState } from 'react';
import { ApiResponse } from 'types/prestashopREST/AccountInformation';
import { ApiResponse as AuthenticationApiResponse } from 'types/prestashopREST/UserData';
import { UserData } from 'types/prestashopREST/UserData';
import { IRegisterData, LoginErrorCode, LoginFailedError, LoginResult } from './AuthContext.types';
import { useSnackbar } from './SnackbarContext';
import { RegisterPSData } from 'types/prestashopREST/Register';
import { PrestashopRestApiResponse } from 'types/prestashopREST/ApiResponse';
import { format } from 'date-fns';
import { useCart } from './CartContext';
import { useNavigate } from 'react-router-dom';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';

interface AuthContextType {
    isAuthenticated: boolean;
    login: (email: string, password: string) => Promise<LoginResult>;
    logout: () => void;
    userData: UserData | null;
    setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
    loading: boolean;
    register: (registerData: IRegisterData) => Promise<boolean>;
}

const PRESTA_URL = process.env.REACT_APP_PRESTASHOP_URL;

enum RegisterCode {
    EmailUsed = 308,
}

const registerCodeMessages: Record<RegisterCode, string> = {
    [RegisterCode.EmailUsed]: 'Adres e-mail jest już używany.',
};

const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const cart = useCart();
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userData, setUserData] = useState<UserData | null>(null);
    const [loading, setLoading] = useState(true);

    const isUserAuthenticated = async () => {
        try {
            const response = await fetch(`${PRESTA_URL}/rest/accountInfo`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                mode: 'cors',
            });
            const data: ApiResponse = await response.json();

            if (!data.success) {
                setIsAuthenticated(false);
                return;
            }

            setIsAuthenticated(true);
            setUserData(data.psdata);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        isUserAuthenticated();
    }, []);

    const login = async (email: string, password: string) => {
        try {
            setLoading(true);
            const response: Response = await fetch(`${PRESTA_URL}/rest/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                mode: 'cors',
                body: JSON.stringify({ email, password }),
            });

            if (response.status !== 200) throw new Error();

            const data: AuthenticationApiResponse = await response.json();
            if (!data.success) throw new Error();
            if (data.code === LoginErrorCode.AuthenticationFailed)
                throw new LoginFailedError('Podano niepoprawny email lub hasło', data.code);
            if (data.code === LoginErrorCode.InvalidPassword)
                throw new LoginFailedError('Podano niepoprawne hasło', data.code);

            setIsAuthenticated(true);
            setUserData(data.psdata.user);
            snackbar.openSnackbar('Zalogowano pomyślnie');

            return { success: true };
        } catch (error) {
            if (error instanceof LoginFailedError) {
                snackbar.openSnackbar(error.message || 'Wystąpił błąd', 'error');

                return {
                    success: false,
                    errorCode: error.errorCode,
                    errorMessage: error.name,
                };
            }

            const errMsg = error instanceof Error ? error.message : null;
            console.error(error);
            snackbar.openSnackbar(errMsg || 'Wystąpił błąd', 'error');

            return { success: false };
        } finally {
            setLoading(false);
        }
    };

    const logout = async () => {
        try {
            setLoading(true);

            const response: Response = await fetch(`${PRESTA_URL}/rest/logout`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                mode: 'cors',
            });

            const data: PrestashopRestApiResponse<any> = await response.json();

            if (data.success) {
                cart?.refetch();
                setIsAuthenticated(false);
                snackbar.openSnackbar('Wylogowano pomyślnie');
                navigate(NavigationNames.Home);
                return;
            }

            snackbar.openSnackbar('Wystąpił błąd', 'error');
        } catch (error) {
            console.error(error);
            snackbar.openSnackbar('Wystąpił błąd', 'error');
        } finally {
            setLoading(false);
        }
    };

    const register: (registerData: IRegisterData) => Promise<boolean> = async (registerData: IRegisterData) => {
        try {
            const body = JSON.stringify({
                ...registerData,
                // birthday: format(new Date(registerData.birthday), 'yyyy-MM-dd'),
            });

            const response: Response = await fetch(`${PRESTA_URL}/rest/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                mode: 'cors',
                body: body,
            });

            const data: PrestashopRestApiResponse<RegisterPSData> = await response.json();

            if (!data.success) {
                snackbar.openSnackbar('Wystąpił błąd', 'error');
                return false;
            }

            if (data.psdata.registered) {
                await isUserAuthenticated();
                snackbar.openSnackbar('Zarejestrowano pomyślnie', 'success');
                return true;
            }

            if (!data.psdata.registered) {
                const message = registerCodeMessages[data.code as RegisterCode];

                if (message) {
                    snackbar.openSnackbar(message, 'error');
                } else {
                    snackbar.openSnackbar('Rejestracja nie powiodła się', 'error');
                }
            }

            return false;
        } catch (error) {
            const errMsg = error instanceof Error ? error.message : null;
            console.error(error);
            snackbar.openSnackbar(errMsg || 'Wystąpił błąd', 'error');
            return false;
        }
    };

    const contextValue = {
        isAuthenticated,
        userData,
        setUserData,
        login,
        logout,
        register,
        loading,
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return useContext(AuthContext);
}
