import React, { useEffect } from 'react';
import s from './NotFoundPage.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import BeanButton from 'components/BeanButton/BeanButton';
import Layout from 'components/Layout/Layout';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';

interface Props {
    showLayout?: boolean;
}

const NotFound = ({ showLayout = true }: Props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleHomeClick = (): void => {
        navigate('/');
    };

    const handleReturnClick = (): void => {
        navigate(-1);
    };

    useEffect(() => {
        if (pathname === '/katalog.html') navigate(NavigationNames.Products);
        else if (pathname === '/kontakt.html') navigate(NavigationNames.Contact);
        else if (pathname === '/katalog,e-sklep.html') navigate(NavigationNames.Shop);
        else if (pathname === '/service.html') navigate(NavigationNames.Service);
    }, []);

    if (showLayout)
        return (
            <Layout>
                <div className={s.container}>
                    <div className={s.content}>
                        <h1 className={s.title}>404 - STRONA NIE ZNALEZIONA</h1>
                        <p>
                            Strona której szukasz mogła zostać usunięta, zmieniła się jej nazwa lub jest chwilowo
                            niedostępna.
                        </p>
                        <BeanButton
                            text='Strona główna'
                            onClick={handleHomeClick}
                            color='blue'
                            iconStart={<FontAwesomeIcon icon={faHome} />}
                        />
                        <BeanButton
                            text='Powrót'
                            onClick={handleReturnClick}
                            color='blue'
                            iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
                        />
                    </div>
                </div>
            </Layout>
        );

    return (
        <div className={s.container}>
            <div className={s.content}>
                <h1 className={s.title}>404 - STRONA NIE ZNALEZIONA</h1>
                <p>
                    Strona której szukasz mogła zostać usunięta, zmieniła się jej nazwa lub jest chwilowo niedostępna.
                </p>
                <BeanButton
                    text='Strona główna'
                    onClick={handleHomeClick}
                    color='blue'
                    iconStart={<FontAwesomeIcon icon={faHome} />}
                />
                <BeanButton
                    text='Powrót'
                    onClick={handleReturnClick}
                    color='blue'
                    iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
                />
            </div>
        </div>
    );
};

export default NotFound;
