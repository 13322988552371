import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React from 'react';
import { AddressInfo } from 'types/prestashopREST/Checkout/GetAddresses';

type Props = {
    open: boolean;
    handleClose: () => void;
    address: AddressInfo;
    onDelete: () => Promise<void>;
};

const AddressDeleteConfirmDialog = ({ open, handleClose, address, onDelete }: Props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleAccept = () => {
        onDelete();
        handleClose();
    };

    return (
        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
            <DialogTitle id='address-delete-confirm'>{'Czy na pewno chcesz usunąć ten adres?'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Adres zostanie usunięty z twojego konta. Nie będzie możliwe jego przywrócenie.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' autoFocus onClick={handleClose}>
                    Anuluj
                </Button>
                <Button variant='contained' onClick={handleAccept} autoFocus>
                    Usuń
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddressDeleteConfirmDialog;
