import React, { useState } from 'react';
import s from './ProductSelect.module.scss';
import ProductCard from './ProductCard/ProductCard';
import SubPage from 'components/SubPage/SubPage';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { addStrapiUrl } from 'utils/strapiUtils';
import BeanButton from 'components/BeanButton/BeanButton';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useQuery } from '@apollo/client';
import Loading from 'components/Loading/Loading';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Product from '../Product/Product';
import { IManufacturer } from 'types/types';
import { Helmet } from 'react-helmet-async';
import Layout from 'components/Layout/Layout';
import NotFound from 'pages/NotFoundPage/NotFoundPage';

interface IProductSubcategoriesData {
    products: {
        data: IProduct[];
    };
}

interface IManufacturersData {
    manufacturers: {
        data: IManufacturer[];
    };
}

interface IProduct {
    id: number;
    attributes: {
        name: string;
        is_new: boolean;
        url: string;
        images: {
            id: string;
            data: {
                attributes: {
                    url: string;
                    alternativeText: string;
                };
            }[];
        };
    };
}

interface Props {
    subcategoryName: string;
    description: string;
    id: number;
}

const GET_PRODUCTS_BY_SUBCATEGORY_ID = gql`
    query GetProductsBySubcategoryId($id: ID!, $manufacturerId: ID) {
        products(
            filters: { and: [{ subcategory: { id: { eq: $id } } }, { manufacturer: { id: { eq: $manufacturerId } } }] }
        ) {
            data {
                id
                attributes {
                    name
                    url
                    is_new
                    images {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_MANUFACTURERS = gql`
    query GetManufacturers($id: ID!) {
        manufacturers(filters: { products: { subcategory: { id: { eq: $id } } } }) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`;

const ProductSelect = ({ subcategoryName, description, id }: Props) => {
    const navigate = useNavigate();
    const [selectedManufacturerId, setSelectedManufacturerId] = useState<string>('unset');

    const { data: productsData, loading: productsLoading } = useQuery<IProductSubcategoriesData>(
        GET_PRODUCTS_BY_SUBCATEGORY_ID,
        {
            variables: { id, manufacturerId: selectedManufacturerId === 'unset' ? undefined : selectedManufacturerId },
        }
    );

    const { data: manufData } = useQuery<IManufacturersData>(GET_MANUFACTURERS, {
        variables: { id },
    });

    const handleReturnClick = (): void => {
        navigate(-1);
    };

    return (
        <>
            <Helmet>
                <title>{subcategoryName[0].toUpperCase() + subcategoryName.slice(1).toLowerCase()}</title>
                <meta name='description' content={description} />
                <link rel='canonical' href={window.location.href} />
            </Helmet>

            <Routes>
                <Route path='/'>
                    <Route
                        index
                        element={
                            <SubPage>
                                <div className={s.container}>
                                    <div className={s.pageDescription}>
                                        <h1 className={s.title}>{subcategoryName}</h1>
                                    </div>

                                    <div className={s.filtersContainer}>
                                        <Typography variant='body1' className={s.displayedProductsNumber}>
                                            {!productsLoading &&
                                                productsData &&
                                                `Wyświetla się ${productsData.products.data.length} z ${productsData.products.data.length} produktów`}
                                        </Typography>

                                        <FormControl
                                            disabled={manufData?.manufacturers.data.length === 0}
                                            sx={{ minWidth: 200 }}
                                        >
                                            {manufData?.manufacturers.data.length !== 0 && (
                                                <>
                                                    <InputLabel id='manufacturer-filter-select-label'>
                                                        Producent
                                                    </InputLabel>
                                                    <Select
                                                        labelId='manufacturer-filter-select-label'
                                                        id='manufacturer-filter-select'
                                                        label='Producent'
                                                        value={selectedManufacturerId}
                                                        onChange={(event) =>
                                                            setSelectedManufacturerId(event.target.value)
                                                        }
                                                    >
                                                        <MenuItem value={'unset'}>Wszyscy</MenuItem>
                                                        {manufData?.manufacturers.data.map(({ id, attributes }) => (
                                                            <MenuItem
                                                                value={id}
                                                                key={`manufacturer-${id}-${attributes.name}`}
                                                            >
                                                                {attributes.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                            )}
                                        </FormControl>
                                    </div>

                                    {productsLoading && <Loading />}
                                    {!productsLoading && productsData && (
                                        <ul className={s.productsList}>
                                            {productsData.products.data.map(({ attributes, id }: IProduct) => (
                                                <ProductCard
                                                    title={attributes.name}
                                                    image={addStrapiUrl(attributes.images.data[0].attributes.url)}
                                                    url={attributes.url}
                                                    isOfferNew={attributes.is_new}
                                                    key={id}
                                                />
                                            ))}
                                        </ul>
                                    )}

                                    <div className={s.returnButtonContainer}>
                                        <BeanButton
                                            text='Powrót'
                                            onClick={handleReturnClick}
                                            color='blue'
                                            variant='outlined'
                                            iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
                                        />
                                    </div>

                                    {description && <div className={s.description}>{description}</div>}
                                </div>
                            </SubPage>
                        }
                    />

                    {!productsLoading && (
                        <>
                            {productsData?.products.data.map(({ id, attributes }: IProduct) => (
                                <Route
                                    key={attributes.url}
                                    path={attributes.url}
                                    element={<Product id={id} name={attributes.name} />}
                                />
                            ))}

                            <Route path='*' element={<NotFound showLayout={false} />} />
                        </>
                    )}
                </Route>
            </Routes>
        </>
    );
};

export default ProductSelect;
