import { gql } from '@apollo/client';

export const GET_SEARCHED_PRODUCTS = gql`
    query GetProductsByName($name: String!) {
        products(
            pagination: { limit: 10 }
            filters: { or: [{ name: { containsi: $name } }, { subcategory: { name: { containsi: $name } } }] }
        ) {
            data {
                id
                attributes {
                    name
                    url
                    images {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    subcategory {
                        data {
                            attributes {
                                url
                                category {
                                    data {
                                        attributes {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
