import React from 'react';
import s from './ProductCard.module.scss';
import { Link } from 'react-router-dom';

interface Props {
    title: string;
    description?: string;
    url: string;
    image: string;
    isOfferNew?: boolean;
}

const ProductCard = ({ title, description, url, image, isOfferNew = false }: Props) => {
    return (
        <li className={s.cardContainer}>
            <Link to={url} className={s.link}>
                <div className={s.imageContainer}>
                    <img className={s.image} src={image} alt='category' />
                </div>
                {isOfferNew && <div className={s.newOffer}>Nowość</div>}
                <div className={s.content}>
                    <h3 className={s.title}>{title}</h3>
                    {description && <span className={s.description}>{description}</span>}
                </div>
            </Link>
        </li>
    );
};

export default ProductCard;
