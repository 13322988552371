import React from 'react';
import s from './Footer.module.scss';
import { Link } from 'react-router-dom';
import { Button, TextField, Typography } from '@mui/material';
import SocialMediaLinks from 'components/SocialMediaLinks/SocialMediaLinks';
import { NavigationNames } from '../Navbar/Navbar';

interface Props {}

const Footer = (props: Props) => {
    return (
        <footer className={s.footer}>
            <div className={s.footerTop}>
                <div className={s.linksContainer}>
                    <Typography variant='h5' component='h4' className={s.linksTitle}>
                        Poland Optical
                    </Typography>
                    <ul className={s.linkList}>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.Products} className={s.linkAnchor}>
                                Produkty
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.Shop} className={s.linkAnchor}>
                                Sklep
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.Service} className={s.linkAnchor}>
                                Serwis
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.AboutUs} className={s.linkAnchor}>
                                O nas
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.Contact} className={s.linkAnchor}>
                                Kontakt
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className={s.linksContainer}>
                    <Typography variant='h5' component='h4' className={s.linksTitle}>
                        Informacje
                    </Typography>
                    <ul className={s.linkList}>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.Financing} className={s.linkAnchor}>
                                Finansowanie
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.LegalNotice} className={s.linkAnchor}>
                                Noty prawne
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Typography
                                variant='body1'
                                component={Link}
                                to={NavigationNames.TermsOfSale}
                                className={s.linkAnchor}
                            >
                                Ogólne warunki sprzedaży
                            </Typography>
                        </li>
                        <li className={s.linkListItem}>
                            <Typography
                                variant='body1'
                                component={Link}
                                to={NavigationNames.Rodo}
                                className={s.linkAnchor}
                            >
                                RODO - Obowiązek informacyjny
                            </Typography>
                        </li>
                    </ul>
                </div>

                <div className={s.linksContainer}>
                    <Typography variant='h5' component='h4' className={s.linksTitle}>
                        Produkty
                    </Typography>
                    <ul className={s.linkList}>
                        <li className={s.linkListItem}>
                            <Typography variant='body1' component={Link} to='/produkty/optyka' className={s.linkAnchor}>
                                Optyka
                            </Typography>
                        </li>
                        <li className={s.linkListItem}>
                            <Typography
                                variant='body1'
                                component={Link}
                                to='/produkty/refrakcja'
                                className={s.linkAnchor}
                            >
                                Refrakcja
                            </Typography>
                        </li>
                        <li className={s.linkListItem}>
                            <Typography
                                variant='body1'
                                component={Link}
                                to='/produkty/okulistyka'
                                className={s.linkAnchor}
                            >
                                Okulistyka
                            </Typography>
                        </li>
                    </ul>
                </div>

                <div className={s.newsletter}>
                    <Typography variant='h5' component='h4' className={s.newsletterTitle}>
                        Newsletter
                    </Typography>
                    <form className={s.newsletterForm}>
                        <TextField
                            id=''
                            onChange={() => null}
                            type='email'
                            label='Twój email'
                            variant='filled'
                            color='primary'
                            className={s.newsletterInput}
                            size='small'
                            disabled
                        />
                        <Button variant='contained' size='small' className={s.newsletterSubmit}>
                            Dostępny wkrótce!
                        </Button>
                    </form>

                    <SocialMediaLinks />
                </div>
            </div>

            <div className={s.copyright}>
                <span className={s.copyrightText}>
                    Copyright © {new Date().getFullYear()} Poland Optical All Rights Reserved
                </span>
            </div>
        </footer>
    );
};

export default Footer;
