import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
    uri: `${
        process.env.NODE_ENV === 'production' ? 'https://admin.polandoptical.pl' : 'http://localhost:1337'
    }/graphql`,
    // uri: 'https://admin.polandoptical.pl/graphql',
    cache: new InMemoryCache(),
});

export default client;
