import { CheckoutProvider } from 'contexts/CheckoutContext';
import NotFound from 'pages/NotFoundPage/NotFoundPage';
import RegisterPage from 'pages/RegisterPage/RegisterPage';
import CartSummaryPage from 'pages/ShopPage/CartSummaryPage/CartSummaryPage';
import OrderCheckout from 'pages/ShopPage/OrderCheckout/OrderCheckout';
import OrderConfirmation from 'pages/ShopPage/OrderCheckout/OrderConfimation/OrderConfirmation';
import ShopCategoryPage from 'pages/ShopPage/ShopCategoryPage/ShopCategoryPage';
import ShopPage from 'pages/ShopPage/ShopPage';
import ShopProductPage from 'pages/ShopPage/ShopProductPage/ShopProductPage';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

export enum ShopRoutesEnum {
    CartSummary = '/koszyk',
    Order = '/zamowienie',
    Register = '/rejestracja',
}

const ShopRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path='/' element={<ShopPage />} />
            <Route path={ShopRoutesEnum.CartSummary} element={<CartSummaryPage />} />
            <Route path={ShopRoutesEnum.Register} element={<RegisterPage />} />
            <Route path={ShopRoutesEnum.Order}>
                <Route
                    index
                    element={
                        <CheckoutProvider>
                            <OrderCheckout />
                        </CheckoutProvider>
                    }
                />
                <Route path='potwierdzenie/:orderId' element={<OrderConfirmation />} />
            </Route>
            <Route path=':categoryId' element={<ShopCategoryPage />} />
            <Route path=':categoryId/:productId' element={<ShopProductPage />} />

            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default ShopRoutes;
