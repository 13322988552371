import React, { useCallback } from 'react';
import s from './ShopPage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import { Helmet } from 'react-helmet-async';
import ShopCategoriesList from './ShopCategoriesList/ShopCategoriesList';
import ShopProductsList from './ShopProductsList/ShopProductsList';
import { CategoryProductsApiResponse } from 'types/prestashopREST/CategoryProduct';
import { useMultipleFetch } from 'hooks/useMultipleFetch';
import { PrestashopRestApiResponse } from 'types/prestashopREST/ApiResponse';
import { Product } from 'types/prestashopREST/MainShopPage/ProductListing';
import { CategoryResponse } from 'types/prestashopApi';

type Props = {};

const CATEGORIES_URL = `${process.env.REACT_APP_PRESTASHOP_URL}/api/categories?display=full&filter[active]=1`;
const USED_PRODUCTS_URL = `${process.env.REACT_APP_PRESTASHOP_URL}/rest/categoryProducts?with_all_images=0&image_size=home_default&id_category=26`;
const NEW_PRODUCTS_URL = `${process.env.REACT_APP_PRESTASHOP_URL}/rest/new-products`;
const BEST_SELLING_URL = `${process.env.REACT_APP_PRESTASHOP_URL}/rest/best-sales`;

const URLS = [CATEGORIES_URL, USED_PRODUCTS_URL, NEW_PRODUCTS_URL, BEST_SELLING_URL];

const ShopPage = (props: Props) => {
    const { data, loading, error } =
        useMultipleFetch<
            [
                CategoryResponse,
                CategoryProductsApiResponse,
                PrestashopRestApiResponse<Product[]>,
                PrestashopRestApiResponse<Product[]>
            ]
        >(URLS);
    const [categoriesData, usedProductsData, newProductsData, bestSellingData] = data;

    const getHomePageCategories = useCallback(() => {
        if (!categoriesData) return [];
        const homePageCategory = categoriesData.categories.find((category) => category.id === 2);

        if (!homePageCategory) return [];
        const homePageCategoriesIds: number[] | undefined = homePageCategory.associations.categories?.map((category) =>
            parseInt(category.id)
        );

        const categories = categoriesData.categories.filter((category) => homePageCategoriesIds?.includes(category.id));
        return categories;
    }, [categoriesData]);

    return (
        <>
            <Helmet>
                <title>Sklep</title>
                <meta name='description' content={`Sklep Poland Optical`} />
            </Helmet>

            <Layout>
                <SubPage loading={loading}>
                    <div className={s.container}>
                        <ShopCategoriesList categories={getHomePageCategories()} />
                        <ShopProductsList
                            products={newProductsData?.psdata}
                            title='NOWOŚCI'
                            description='Zobacz, co nowego w naszej ofercie!'
                        />
                        <ShopProductsList
                            products={usedProductsData?.psdata.products}
                            title='UŻYWANE'
                            description='Pełnowartościowe produkty z drugiej ręki'
                        />
                        <ShopProductsList
                            products={bestSellingData?.psdata}
                            title='BESTSELLERY'
                            description='Najczęściej kupowane produkty'
                        />
                    </div>
                </SubPage>
            </Layout>
        </>
    );
};

export default ShopPage;
