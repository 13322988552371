import React from 'react';
import s from './CategoryCard.module.scss';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

interface Props {
    category: string;
    image: string;
    url: string;
    description?: string;
}

const CategoryCard = ({ image, category, url, description }: Props) => {
    return (
        <li className={s.card}>
            <Link to={url} className={s.link}>
                <div className={s.imageContainer}>
                    <img className={s.image} src={image} alt='category' />
                </div>
                <div className={s.content}>
                    <Typography variant='h6' component='h2' className={s.title}>
                        {category}
                    </Typography>
                    {description && <span className={s.description}>{description}</span>}
                </div>
            </Link>
        </li>
    );
};

export default CategoryCard;
