import React, { createContext, useContext, useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

type SnackbarSeverity = 'success' | 'error' | 'warning' | 'info';

interface SnackbarState {
    open: boolean;
    message: string;
    severity: SnackbarSeverity;
}

interface SnackbarContextType {
    openSnackbar: (message: string, severity?: SnackbarSeverity) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = () => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error('Snackbar context must be used within a SnackbarProvider');
    }
    return context;
};

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
    const [snackbarState, setSnackbarState] = useState<SnackbarState>({
        open: false,
        message: '',
        severity: 'success',
    });

    const openSnackbar = (message: string, severity: SnackbarSeverity = 'success') => {
        setSnackbarState({ open: true, message, severity });
    };

    const closeSnackbar = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (snackbarState.open) {
            timer = setTimeout(() => {
                closeSnackbar();
            }, 6000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [snackbarState]);

    const SnackbarComponent = (
        <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert severity={snackbarState.severity} variant='filled'>
                {snackbarState.message}
            </Alert>
        </Snackbar>
    );

    return (
        <SnackbarContext.Provider value={{ openSnackbar }}>
            {children}
            {SnackbarComponent}
        </SnackbarContext.Provider>
    );
}
