import React from 'react';
import s from './CategorySelect.module.scss';
import CategoryCard from './CategoryCard/CategoryCard';
import { IProductCategory } from 'types/types';
import { addStrapiUrl } from 'utils/strapiUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import BeanButton from 'components/BeanButton/BeanButton';
import { useNavigate } from 'react-router-dom';

interface Props {
    description?: string;
    categories: IProductCategory[];
    showReturnButton?: boolean;
}

const CategoriesSection = ({ description, categories, showReturnButton = false }: Props) => {
    const navigate = useNavigate();

    const handleReturnClick = (): void => {
        navigate(-1);
    };

    return (
        <div className={s.container}>
            <ul className={s.categoriesList}>
                {categories &&
                    categories.map(({ attributes }) => {
                        if (attributes.name && attributes.image && attributes.url) {
                            return (
                                <CategoryCard
                                    category={attributes.name}
                                    image={addStrapiUrl(attributes.image.data.attributes.url)}
                                    url={attributes.url}
                                    key={attributes.url}
                                />
                            );
                        }

                        return null;
                    })}
            </ul>

            {showReturnButton && (
                <div className={s.returnButtonContainer}>
                    <BeanButton
                        text='Powrót'
                        onClick={handleReturnClick}
                        color='blue'
                        variant='outlined'
                        iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
                    />
                </div>
            )}

            {description && <div className={s.description}>{description}</div>}
        </div>
    );
};

export default CategoriesSection;
