import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, Typography } from '@mui/material';
import React from 'react';
import s from './MobileSearchBar.module.scss';
import { ISearchedProduct } from 'types/types';
import { addStrapiUrl } from 'utils/strapiUtils';
import { Link } from 'react-router-dom';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';

type Props = {
    searchValue: string;
    handleSearchValueChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
    searchedProducts: ISearchedProduct[] | null;
    isActive: boolean;
    setIsActive: (value: React.SetStateAction<boolean>) => void;
    onSearchItemClick?: () => void;
};

const MobileSearchBar = ({
    searchValue,
    handleSearchValueChange,
    searchedProducts,
    isActive,
    setIsActive,
    onSearchItemClick,
}: Props) => {
    return (
        <div className={s.container}>
            <TextField
                className={s.input}
                placeholder='Wyszukiwanie produktów...'
                autoComplete='off'
                value={searchValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchValueChange(e)}
                InputProps={{
                    startAdornment: <FontAwesomeIcon className={s.icon} size='lg' icon={faMagnifyingGlass} />,
                }}
                variant='outlined'
                fullWidth
            />

            <ul className={s.searchList} onMouseOver={() => setIsActive(true)}>
                {searchedProducts?.map((product) => (
                    <li className={s.searchListItem} key={product.id}>
                        <Link
                            className={s.searchListItemLink}
                            onClick={onSearchItemClick}
                            to={`${NavigationNames.Products}/${product.attributes.subcategory.data.attributes.category.data.attributes.url}/${product.attributes.subcategory.data.attributes.url}/${product.attributes.url}`}
                        >
                            <img
                                className={s.image}
                                src={addStrapiUrl(product.attributes.images.data[0].attributes.url)}
                                alt={addStrapiUrl(product.attributes.images.data[0].attributes.alternativeText)}
                            />
                            <Typography className={s.productName} variant='body2'>
                                {product.attributes.name}
                            </Typography>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MobileSearchBar;
