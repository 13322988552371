import React from 'react';
import s from './NewPasswordForm.module.scss';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { PrestashopRestApiResponse } from 'types/prestashopREST/ApiResponse';
import { useSnackbar } from 'contexts/SnackbarContext';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';

type Props = { email: string };

const validationSchema = Yup.object().shape({
    code: Yup.string().required('Kod jest wymagany'),
    password: Yup.string().required('Hasło jest wymagane'),
});

interface IFormValues {
    code: string;
    password: string;
}

const NewPasswordForm = ({ email }: Props) => {
    const { openSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleFormSubmit = async ({ code, password }: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
        const body: {
            'pass-code': string;
            email: string;
            passwd: string | null;
        } = {
            'pass-code': code,
            email: email,
            passwd: null,
        };

        const codeCheckRes = await fetch(`${process.env.REACT_APP_PRESTASHOP_URL}/rest/resetpasswordcheck`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(body),
        });

        const codeCheckData: PrestashopRestApiResponse = await codeCheckRes.json();

        if (!codeCheckData.success) {
            openSnackbar('Podany kod jest nieprawidłowy', 'error');
            return;
        }

        body.passwd = password;

        const passEnterRes = await fetch(`${process.env.REACT_APP_PRESTASHOP_URL}/rest/resetpasswordenter`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(body),
        });

        // const data: PrestashopRestApiResponse = await passEnterRes.json();

        if (passEnterRes.ok) {
            openSnackbar('Hasło zostało zmienione');
            navigate(NavigationNames.Login);
            return;
        }

        openSnackbar('Wystąpił błąd podczas wysyłania emaila. Spróbuj ponownie', 'error');
    };

    return (
        <Formik
            initialValues={{ code: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {({ submitForm, isSubmitting, getFieldProps, touched, errors }) => (
                <Form className={s.form}>
                    <TextField
                        {...getFieldProps('code')}
                        label='Kod'
                        variant='outlined'
                        error={Boolean(touched.code && errors.code)}
                        helperText={touched.code && errors.code}
                    />

                    <TextField
                        {...getFieldProps('password')}
                        label='Nowe hasło'
                        variant='outlined'
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />

                    <Button
                        disabled={isSubmitting}
                        variant='contained'
                        color='primary'
                        onClick={submitForm}
                        type='submit'
                    >
                        Przypomnij hasło
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default NewPasswordForm;
