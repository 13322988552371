import { faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = { open: boolean; handleClose: () => void };

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const MedicalStaffCheckDialog = ({ open, handleClose }: Props) => {
    const navigate = useNavigate();

    const onClose = (event: object, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }

        handleClose();
    };

    const handleMedicalStaffClick = () => {
        sessionStorage.setItem('isMedicalStaff', 'true');
        handleClose();
    };

    const handleExitClick = () => {
        navigate(-1);
        handleClose();
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby='alert-dialog-slide-description'
        >
            <DialogTitle>{'Potwierdzenie uprawnień dostępu do informacji'}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                    Informacje zawarte na stronie przeznaczone są wyłącznie dla profesjonalnych użytkowników związanych
                    z medycyną lub obrotem produktami medycznymi. Aby uzyskać dostęp potwierdź, że jesteś taką osobą.
                </DialogContentText>
                <DialogContentText>
                    <Link href='https://isap.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20220000974'>
                        Podstawa prawna
                    </Link>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='secondary' onClick={handleExitClick}>
                    Wychodzę
                </Button>
                <Button
                    variant='contained'
                    onClick={handleMedicalStaffClick}
                    startIcon={<FontAwesomeIcon icon={faUserDoctor} />}
                >
                    Potwierdzam
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MedicalStaffCheckDialog;
