import React, { useMemo } from 'react';
import { Link as MuiLink, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';

interface Props {
    currentPageName?: string | null;
    previousPageName?: string | null;
}

const Breadcrumbs = ({ currentPageName = null, previousPageName = null }: Props) => {
    const location = useLocation();

    const crumbs = useMemo(() => {
        const array: string[] = location.pathname.split('/').slice(1);

        let backHref: string = '';
        const crumbs: { crumbName: string; path: string }[] = array.map((crumb) => {
            const crumbName = crumb.charAt(0).toUpperCase() + crumb.slice(1);
            backHref += `/${crumb}`;
            return { crumbName, path: backHref };
        });

        // Check if previousPageName is provided and replace the name of the second-to-last breadcrumb
        if (previousPageName && crumbs.length > 1) {
            crumbs[crumbs.length - 2].crumbName = previousPageName;
        }

        return crumbs;
    }, [location, previousPageName]);

    return (
        <MuiBreadcrumbs>
            <MuiLink component={Link} to='/' underline='hover' color='inherit'>
                Home
            </MuiLink>

            {crumbs.slice(0, -1).map(({ crumbName, path }) => (
                <MuiLink component={Link} to={path} underline='hover' color='inherit' key={crumbName}>
                    {crumbName.replaceAll('-', ' ')}
                </MuiLink>
            ))}

            <Typography color='text.primary'>
                {currentPageName || crumbs[crumbs.length - 1].crumbName.replaceAll('-', ' ')}
            </Typography>
        </MuiBreadcrumbs>
    );
};

export default Breadcrumbs;
