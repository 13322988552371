import { FormControlLabel, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Filter } from 'types/prestashopREST/CategoryProduct';

type Props = {
    setSelectedFiltersFacet: React.Dispatch<React.SetStateAction<string>>;
    filter: Filter;
    loading: boolean;
};

const FacetCheckbox = ({ filter, loading, setSelectedFiltersFacet }: Props) => {
    let { categoryId } = useParams();

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(false);
    }, [categoryId]);

    const handleChange = (filter: Filter) => {
        setSelectedFiltersFacet(filter.nextEncodedFacets);
        setIsChecked(!isChecked);
    };
    return (
        <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={() => handleChange(filter)} disabled={loading} />}
            label={filter.label}
        />
    );
};

export default FacetCheckbox;
