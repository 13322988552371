import React, { useState } from 'react';
import s from './AddressCard.module.scss';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Checkbox, Divider, Typography } from '@mui/material';
import { AddressInfo } from 'types/prestashopREST/Checkout/GetAddresses';
import { useCheckout } from 'contexts/CheckoutContext';
import AddressDeleteConfirmDialog from '../AddressDeleteConfirmDialog/AddressDeleteConfirmDialog';
import { PrestashopRestApiResponse } from 'types/prestashopREST/ApiResponse';
import { useSnackbar } from 'contexts/SnackbarContext';

type Props = {
    address: AddressInfo;
    refetch: () => void;
    onEditClick: (address: AddressInfo) => void;
};

const AddressCard = ({ address, refetch, onEditClick }: Props) => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const checkout = useCheckout();
    const snackbar = useSnackbar();

    const handleAliasClick = () => {
        if (checkout?.loading) return;
        if (checkout?.selectedAddress === address) {
            checkout?.setAddress(null);
        } else {
            checkout?.setAddress(address);
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (event.target.checked) {
            checkout?.setAddress(address);
            return;
        }

        checkout?.setAddress(null);
    };

    const handleDeleteClick = () => {
        setDeleteDialogOpen(true);
    };

    const deleteAddress = async () => {
        try {
            const body = {
                id_address: address.id,
            };

            const response = await fetch(`${process.env.REACT_APP_PRESTASHOP_URL}/rest/address`, {
                method: 'DELETE',
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify(body),
            });

            const data: PrestashopRestApiResponse<boolean> = await response.json();

            if (!data.success) {
                snackbar.openSnackbar(data.message || 'Wystąpił błąd podczas usuwania adresu', 'error');
                return;
            }

            snackbar.openSnackbar('Adres został usunięty', 'success');
            refetch();
        } catch (error) {
            if (error instanceof Error) {
                snackbar.openSnackbar(error.message, 'error');
                return;
            }

            snackbar.openSnackbar('Wystąpił błąd podczas usuwania adresu', 'error');
        }
    };

    return (
        <>
            <Card className={s.card}>
                <div className={s.aliasContainer}>
                    <Checkbox
                        disabled={checkout?.loading}
                        checked={checkout?.selectedAddress?.id === address.id}
                        onChange={handleCheckboxChange}
                    />
                    <Typography
                        variant='h6'
                        onClick={handleAliasClick}
                        sx={{ cursor: checkout?.loading ? 'auto' : 'pointer' }}
                    >
                        {address.alias}
                    </Typography>
                </div>

                <Divider />

                <div className={s.infoContainer}>
                    <Typography variant='body1'>{`${address.firstname} ${address.lastname}`}</Typography>
                    <Typography variant='body1'>{address.company}</Typography>
                    <Typography variant='body1'>{address.phone}</Typography>
                    <Typography variant='body1'>{address.address1}</Typography>
                    <Typography variant='body1'>{address.address2}</Typography>
                    <Typography variant='body1'>
                        {address.city} {address.postcode}
                    </Typography>
                    <Typography variant='body1'>{address.country}</Typography>
                    <Typography variant='body1'>{address.other}</Typography>
                </div>

                <Divider />

                <div>
                    <Button
                        color='secondary'
                        startIcon={<FontAwesomeIcon icon={faEdit} />}
                        onClick={() => onEditClick(address)}
                    >
                        Edytuj
                    </Button>
                    <Button
                        color='secondary'
                        startIcon={<FontAwesomeIcon icon={faTrash} />}
                        onClick={handleDeleteClick}
                    >
                        Usuń
                    </Button>
                </div>
            </Card>

            <AddressDeleteConfirmDialog
                open={deleteDialogOpen}
                handleClose={() => setDeleteDialogOpen(false)}
                address={address}
                onDelete={deleteAddress}
            />
        </>
    );
};

export default AddressCard;
