import React from 'react';
import s from './ProductListTopBar.module.scss';
import { CategoryProductsApiResponse, SortOrder } from 'types/prestashopREST/CategoryProduct';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

type Props = {
    apiResponse: CategoryProductsApiResponse | null;
    sortOrder: string;
    setSortOrder: React.Dispatch<React.SetStateAction<string>>;
    sortOrders: SortOrder[];
};

const ProductListTopBar = ({ apiResponse, sortOrder, setSortOrder, sortOrders }: Props) => {
    const theme = useTheme();

    const handleSortChange = (event: SelectChangeEvent<string>) => {
        if (!event.target.value) return;
        setSortOrder(event.target.value);
    };

    return (
        <div className={s.container}>
            <span>
                Wyświetla się {apiResponse?.psdata.products.length} z {apiResponse?.psdata.pagination.total_items}{' '}
                produktów
            </span>

            <div className={s.sortSelectContainer}>
                <FormControl disabled={sortOrders.length === 0}>
                    <InputLabel id='sort-select-label'>Sortuj według</InputLabel>
                    <Select
                        size={useMediaQuery(theme.breakpoints.down('sm')) ? 'small' : 'medium'}
                        label='Sortuj według'
                        labelId='sort-select-label'
                        id='sort-select'
                        value={sortOrder}
                        onChange={handleSortChange}
                    >
                        {apiResponse?.psdata.sort_orders.map((sortOrder) => (
                            <MenuItem value={sortOrder.urlParameter} key={sortOrder.urlParameter}>
                                {sortOrder.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default ProductListTopBar;
