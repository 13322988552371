import React from 'react';
import s from './ShippingMethodCard.module.scss';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Typography } from '@mui/material';
import { Carrier } from 'types/prestashopREST/Checkout/GetCarriers';
import { useCheckout } from 'contexts/CheckoutContext';

type Props = { carrier: Carrier };

const ShippingMethodCard = ({ carrier }: Props) => {
    const checkout = useCheckout();

    const getCarrierPrice: () => string = () => {
        if (carrier.is_free === '1') {
            return 'Za darmo';
        }

        return `${carrier.price}`;
    };

    const handleAliasClick = () => {
        if (checkout?.loading) return;

        if (checkout?.selectedCarrier === carrier) {
            checkout?.setCarrier(null);
        } else {
            checkout?.setCarrier(carrier);
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (checkout?.loading) return;
        if (event.target.checked) {
            checkout?.setCarrier(carrier);
            return;
        }

        checkout?.setCarrier(null);
    };

    return (
        <div
            className={`${s.card} ${checkout?.selectedCarrier?.id === carrier.id ? s.active : ''}`}
            onClick={handleAliasClick}
        >
            <div className={s.topContainer}>
                <Checkbox
                    disabled={checkout?.loading}
                    checked={checkout?.selectedCarrier?.id === carrier.id}
                    onChange={handleCheckboxChange}
                />

                <div className={s.carrierContainer}>
                    <div className={s.icon}>
                        <FontAwesomeIcon size='xl' icon={faTruck} />
                    </div>

                    <Typography component={'p'} fontWeight={'bold'}>
                        {carrier.name}
                    </Typography>
                </div>
            </div>

            <div className={s.bottomContainer}>
                <Typography variant={'body1'}>{carrier.delay}</Typography>

                <Typography variant={'body1'}>{getCarrierPrice()}</Typography>
            </div>
        </div>
    );
};

export default ShippingMethodCard;
