import React from 'react';
import { IEvent } from 'types/types';
import { addStrapiUrl } from 'utils/strapiUtils';
import s from './EventsListItem.module.scss';
import dayjs from 'dayjs';
import { Chip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import BeanButton from 'components/BeanButton/BeanButton';

interface Props {
    event: IEvent;
}

const EventsListItem = ({ event }: Props) => {
    return (
        <li className={[s.container, event.attributes.image.data ? '' : s.noImage].join(' ')}>
            <div className={s.textContainer}>
                <div>
                    <Typography variant='h6' component='h4' className={s.title}>
                        {event.attributes.title}
                    </Typography>
                    {event.attributes.date_start && (
                        <div className={s.date}>
                            <Chip
                                label={`${dayjs(event.attributes.date_start).format('DD.MM.YYYY')} ${
                                    event.attributes.date_end
                                        ? '- ' + dayjs(event.attributes.date_end).format('DD.MM.YYYY')
                                        : ''
                                }`}
                                icon={<FontAwesomeIcon icon={faCalendarDays} size='lg' />}
                                sx={{ padding: '.5rem' }}
                            />
                        </div>
                    )}
                </div>
                <Typography variant='body1' className={s.description}>
                    {event.attributes.description}
                </Typography>
                <BeanButton variant='contained' link={`${event.id}`} text='Szczegóły' fullWidth />
            </div>
            {event.attributes.image.data && (
                <div className={s.imageContainer}>
                    <img
                        className={s.image}
                        src={addStrapiUrl(event.attributes.image.data.attributes.url)}
                        alt={event.attributes.image.data.attributes.alternativeText}
                    />
                </div>
            )}
        </li>
    );
};

export default EventsListItem;
