import { Link, Typography } from '@mui/material';
import React from 'react';
import s from './PersonnelListItem.module.scss';

type Props = {
    location?: string;
    name: string;
    phone: string;
    email: string;
    additionalInfo?: string;
};

const PersonnelListItem = ({ location, name, phone, email, additionalInfo }: Props) => {
    return (
        <li className={s.container}>
            <Typography variant='body1'>
                {location ? `${location} -` : ''} <b className={s.name}>{name}</b>, tel. {phone} {additionalInfo}
            </Typography>
            <Typography variant='body1'>
                e-mail: <Link href={`mailto:${email}`}>{email}</Link>
            </Typography>
        </li>
    );
};

export default PersonnelListItem;
