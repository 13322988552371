import { useState, useEffect, useCallback } from 'react';

function useFetch<T>(
    url: string,
    requestInit?: RequestInit,
    customHeaders?: Headers
): {
    data: T | null;
    loading: boolean;
    error: Error | null;
    firstLoading: boolean;
    refetch: () => void;
} {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);

            const headers = new Headers(customHeaders);

            headers.append('Authorization', 'Basic ' + btoa(`${process.env.REACT_APP_API_KEY}:`));
            headers.append('Output-Format', 'JSON');

            const response = await fetch(url, { ...requestInit, credentials: 'include', mode: 'cors' });
            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            const responseData: T = await response.json();
            setData(responseData);
        } catch (err: any) {
            setError(err);
            console.error(err);
        } finally {
            setFirstLoading(false);
            setLoading(false);
        }
    }, [url, requestInit, customHeaders]);

    useEffect(() => {
        fetchData();
    }, [url, requestInit, customHeaders, fetchData]);

    return { data, loading, error, firstLoading, refetch: fetchData };
}

export default useFetch;
