import React, { useEffect } from 'react';
import s from './CartSummaryPage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import { useCart } from 'contexts/CartContext';
import ProductsTable from './CartSummaryProducts/ProductsTable/ProductsTable';
import OrderSummary from './OrderSummary/OrderSummary';
import { Alert, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import useBreakpoint from 'hooks/useBreakpoint';
import ProductsList from './CartSummaryProducts/ProductsList/ProductsList';

type Props = {};

const CartSummaryPage = (props: Props) => {
    const cart = useCart();
    const { belowDesktop } = useBreakpoint();

    const cartErrors = cart?.cartInfo?.errors;
    const hasCartErrors = cartErrors && cartErrors.length > 0;

    useEffect(() => {
        cart?.refetch();
    }, []);

    return (
        <Layout>
            <SubPage title='Koszyk' loading={cart?.loading}>
                <div className={s.container}>
                    {hasCartErrors && (
                        <div className={s.errors}>
                            {cartErrors.map((error) => (
                                <Alert severity='error'>{error}</Alert>
                            ))}
                        </div>
                    )}

                    {cart?.cartInfo?.products_count === 0 ? (
                        <div className={s.emptyCart}>
                            <Typography component='h2' variant='h4'>
                                Twój koszyk jest pusty
                            </Typography>
                            <Link to={NavigationNames.Shop}>
                                <Button variant='contained'>Wróć do sklepu</Button>
                            </Link>
                        </div>
                    ) : belowDesktop ? (
                        <ProductsList cartItems={cart?.cartInfo?.products || []} />
                    ) : (
                        <ProductsTable cartItems={cart?.cartInfo?.products || []} />
                    )}

                    <OrderSummary hasCartErrors={hasCartErrors} loading={cart?.cartChanging} />
                </div>
            </SubPage>
        </Layout>
    );
};

export default CartSummaryPage;
