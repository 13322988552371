import React from 'react';
import s from './MobileFilters.module.scss';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide } from '@mui/material';
import { CategoryProductsApiResponse } from 'types/prestashopREST/CategoryProduct';
import ShopCategoryFilters from '../ShopCategoryFilters/ShopCategoryFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { TransitionProps } from '@mui/material/transitions';
type Props = {
    open: boolean;
    handleClose: () => void;
    data: CategoryProductsApiResponse | null;
    setSelectedFiltersFacet: React.Dispatch<React.SetStateAction<string>>;
    loading: boolean;
    clearFilters: () => void;
    closeMobileFilters: () => void;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='left' ref={ref} {...props} />;
});

const MobileFilters = ({
    open,
    handleClose,
    data,
    setSelectedFiltersFacet,
    loading,
    clearFilters,
    closeMobileFilters,
}: Props) => {
    return (
        <Dialog TransitionComponent={Transition} fullScreen open={open} onClose={handleClose} className={s.container}>
            <DialogTitle variant='h4' className={s.dialogTitle}>
                Filtry
                <IconButton onClick={handleClose}>
                    <FontAwesomeIcon icon={faClose} />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <ShopCategoryFilters
                    data={data}
                    setSelectedFiltersFacet={setSelectedFiltersFacet}
                    clearFilters={clearFilters}
                    loading={loading}
                    closeMobileFilters={closeMobileFilters}
                />
            </DialogContent>
        </Dialog>
    );
};

export default MobileFilters;
