import { useState, useEffect } from 'react';

function useMultipleFetch<T extends any[]>(
    urls: string[],
    requestInit?: RequestInit,
    customHeaders?: Headers
): {
    data: T;
    loading: boolean;
    error: Error | null;
} {
    const initialData: T = urls.map(() => null) as T; // Create initial data array based on the type T
    const [data, setData] = useState<T>(initialData);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const headers = new Headers(customHeaders);

                headers.append('Authorization', 'Basic ' + btoa(`${process.env.REACT_APP_API_KEY}:`));
                headers.append('Output-Format', 'JSON');

                const fetchPromises = urls.map(async (url, index) => {
                    const response = await fetch(url, {
                        ...requestInit,
                        headers,
                        // mode: 'cors',
                        // credentials: 'include',
                    });
                    if (!response.ok) {
                        throw new Error(`Request failed with status: ${response.status}`);
                    }
                    const responseData = await response.json();
                    return responseData;
                });

                const responses = await Promise.all(fetchPromises);
                setData(responses as T); // Note: We're asserting the type here
            } catch (err: any) {
                setError(err);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAllData();
    }, [urls, requestInit, customHeaders]);

    return { data, loading, error };
}

export { useMultipleFetch };
