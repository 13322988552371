import React, { useEffect, useState } from 'react';
import s from './MobileMenu.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { NavigationNames } from '../Navbar';
import { IconButton } from '@mui/material';
import MobileMenuLayout from '../MobileMenuLayout/MobileMenuLayout';
import SocialMediaLinks from 'components/SocialMediaLinks/SocialMediaLinks';
import MobileSearch from '../MobileSearch/MobileSearch';
import ShoppingCart from 'components/Layout/Header/ShoppingCart/ShoppingCart';
import AccountDropdown from 'components/Layout/Header/AccountDropdown/AccountDropdown';

interface Props {
    isActive: boolean;
    onClose: () => void;
}

const MobileMenu = ({ isActive, onClose }: Props) => {
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

    useEffect(() => {
        if (isActive) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'visible';
    }, [isActive]);

    const onSearchItemClick = () => {
        onClose();
        setIsSearchOpen(false);
    };

    return (
        <>
            <div className={isActive ? [s.darkBackground, s.active].join(' ') : s.darkBackground} onClick={onClose} />

            <MobileSearch
                isActive={isSearchOpen}
                onClose={() => setIsSearchOpen(false)}
                onSearchItemClick={onSearchItemClick}
            />

            <MobileMenuLayout isActive={isActive} onClose={onClose} title='MENU'>
                <ul className={s.mobileLinksList}>
                    <Link to={NavigationNames.Products} className={s.mobileLink} onClick={onClose}>
                        Produkty
                    </Link>
                    <Link to={NavigationNames.Shop} className={s.mobileLink} onClick={onClose}>
                        Sklep
                    </Link>
                    <Link to={NavigationNames.Service} className={s.mobileLink} onClick={onClose}>
                        Serwis
                    </Link>
                    <Link to={NavigationNames.News} className={s.mobileLink} onClick={onClose}>
                        Aktualności
                    </Link>
                    <Link to={NavigationNames.AboutUs} className={s.mobileLink} onClick={onClose}>
                        O nas
                    </Link>
                    <Link to={NavigationNames.Contact} className={s.mobileLink} onClick={onClose}>
                        Kontakt
                    </Link>
                </ul>

                <div className={s.mobileMenuBottom}>
                    {/* <SocialMediaLinks color='black' size='lg' /> */}

                    <div className={s.bottomRight}>
                        <AccountDropdown mobile />

                        <ShoppingCart mobile />

                        <IconButton color='secondary' onClick={() => setIsSearchOpen(true)}>
                            <FontAwesomeIcon className={s.icon} icon={faMagnifyingGlass} />
                        </IconButton>
                    </div>
                </div>
            </MobileMenuLayout>
        </>
    );
};

export default MobileMenu;
