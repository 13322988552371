import React, { useState } from 'react';
import s from './CookieConsent.module.scss';
import { Link } from 'react-router-dom';
import { Button, Link as MuiLink, Typography } from '@mui/material';

type Props = {};

const CookieConsent = (props: Props) => {
    const [agreedToCookies, setAgreedToCookies] = useState(localStorage.getItem('agreedToCookies') === 'true');

    const handleAgreeClick = () => {
        localStorage.setItem('agreedToCookies', 'true');
        setAgreedToCookies(true);
    };

    if (agreedToCookies) {
        return null;
    }

    return (
        <div className={s.container}>
            <Typography variant='h6'>Pliki cookies</Typography>
            <Typography variant='body2'>
                Ta strona wykorzystuje pliki cookies w celu zapewnienia prawidłowego działania poszczególnych jej
                funkcji. Dowiedz się więcej:{' '}
                <MuiLink component={Link} to='/rodo'>
                    polityka prywatności
                </MuiLink>
                .
            </Typography>
            <Typography variant='body2'>
                Jeśli nie wyrażasz zgody na przechowywanie informacji oraz uzyskiwanie dostępu do informacji
                przechowywanych w plikach cookies, opuść stronę.
            </Typography>

            <div className={s.buttons}>
                <Button variant='contained' color='primary' onClick={handleAgreeClick}>
                    Zgadzam się
                </Button>
            </div>
        </div>
    );
};

export default CookieConsent;
