import React from 'react';
import s from './ShoppingCartItem.module.scss';
import { Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { CartProduct } from 'types/prestashopREST/Cart';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCart } from 'contexts/CartContext';
import { Link } from 'react-router-dom';

type Props = { product: CartProduct };

const ShoppingCartItem = ({ product }: Props) => {
    const cart = useCart();

    const handleRemoveClick = async () => {
        await cart?.removeCartItem(product.id_product);
    };

    return (
        <React.Fragment>
            <li className={s.container}>
                <div className={s.imgContainer}>
                    <img src={product.image_url} alt={product.name} />
                </div>
                <div className={s.contentContainer}>
                    <div className={s.topContainer}>
                        <Tooltip title={product.name} arrow disableHoverListener={product.name.length <= 18}>
                            <Link to={`/sklep/${product.id_category_default}/${product.id_product}`} className={s.link}>
                                <Typography className={s.name} variant='body1' fontWeight={'600'} component={'h4'}>
                                    {product.name}
                                </Typography>
                            </Link>
                        </Tooltip>
                        <Tooltip title='Usuń z koszyka' arrow>
                            <IconButton size='small' onClick={handleRemoveClick}>
                                <FontAwesomeIcon icon={faTimes} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={s.bottomContainer}>
                        <Typography className={s.price} variant='body1' fontWeight={'600'} component={'p'}>
                            {product.formatted_price_amount}
                        </Typography>
                        <Typography variant='body1'>Ilość: {product.quantity}</Typography>
                    </div>
                </div>
            </li>
            <Divider />
        </React.Fragment>
    );
};

export default ShoppingCartItem;
