import React from 'react';
import s from './Layout.module.scss';
import Header from 'components/Layout/Header/Header';
import Navbar from 'components/Layout/Navbar/Navbar';
import Footer from 'components/Layout/Footer/Footer';

interface Props {
    children?: React.ReactNode;
}

const Layout = (props: Props) => {
    return (
        <div className={s.layout}>
            <Header />
            <Navbar />
            <main className={s.content}>{props.children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
