import React from 'react';
import './App.scss';

import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import apolloClient from '../apollo.config.js';

// mui
import { ThemeProvider } from '@mui/material/styles';
import theme from 'mui/theme';

//  Pages
import HomePage from 'pages/HomePage/HomePage';
import Contact from 'pages/ContactPage/ContactPage';
import ProductsPage from 'pages/ProductsPage/ProductsPage';
import NotFound from 'pages/NotFoundPage/NotFoundPage';
import ServicePage from 'pages/ServicePage/ServicePage';
import AboutUsPage from 'pages/AboutUsPage/AboutUsPage';

// Components
import ScrollTop from 'components/ScrollTop/ScrollTop';
import EventsPage from 'pages/EventsPage/EventsPage';
import FinancePage from 'pages/FinancePage/FinancePage';
import SpecialOffersPage from 'pages/SpecialOffersPage/SpecialOffersPage';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import LegalNoticePage from 'pages/LegalNoticePage/LegalNoticePage';
import TermsOfSalePage from 'pages/TermsOfSalePage/TermsOfSalePage';
import RodoPage from 'pages/RodoPage/RodoPage';
import NewProductsPage from 'pages/NewProductsPage/NewProductsPage';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import RegisterPage from 'pages/RegisterPage/RegisterPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import ShopRoutes from 'Router/ShopRoutes';
import { AuthProvider } from 'contexts/AuthContext';
import ProfileRoutes from 'Router/ProfileRoutes';
import { CartProvider } from 'contexts/CartContext';
import { SnackbarProvider } from 'contexts/SnackbarContext';
import CookieConsent from 'components/CookieConsent/CookieConsent';
import PasswordResetPage from 'pages/PasswordResetPage/PasswordResetPage';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <HelmetProvider>
                <BrowserRouter>
                    <ApolloProvider client={apolloClient}>
                        <div className='app'>
                            <Helmet>
                                <title>Poland Optical</title>
                                <meta
                                    name='description'
                                    content='Poland Optical to renomowany dystrybutor wysokiej jakości urządzeń okulistycznych oraz sprzętu optycznego wielu cenionych marek.'
                                />
                            </Helmet>
                            <ScrollTop>
                                <SnackbarProvider>
                                    <CartProvider>
                                        <AuthProvider>
                                            <Routes>
                                                <Route index element={<HomePage />} />
                                                <Route
                                                    path={`${NavigationNames.Products}/*`}
                                                    element={<ProductsPage />}
                                                />
                                                <Route path={NavigationNames.Contact} element={<Contact />} />
                                                <Route
                                                    path={`${NavigationNames.Service}/*`}
                                                    element={<ServicePage />}
                                                />
                                                <Route path={NavigationNames.AboutUs} element={<AboutUsPage />} />
                                                <Route path={`${NavigationNames.News}/*`} element={<EventsPage />} />
                                                <Route
                                                    path={`${NavigationNames.SpecialOffers}/*`}
                                                    element={<SpecialOffersPage />}
                                                />
                                                <Route
                                                    path={`${NavigationNames.Financing}`}
                                                    element={<FinancePage />}
                                                />
                                                <Route
                                                    path={`${NavigationNames.LegalNotice}`}
                                                    element={<LegalNoticePage />}
                                                />
                                                <Route
                                                    path={`${NavigationNames.TermsOfSale}`}
                                                    element={<TermsOfSalePage />}
                                                />
                                                <Route path={`${NavigationNames.Rodo}`} element={<RodoPage />} />
                                                <Route
                                                    path={`${NavigationNames.NewProducts}`}
                                                    element={<NewProductsPage />}
                                                />
                                                <Route path={`${NavigationNames.Shop}/*`} element={<ShopRoutes />} />
                                                <Route
                                                    path={`${NavigationNames.Profile}/*`}
                                                    element={<ProfileRoutes />}
                                                />
                                                <Route path={NavigationNames.Register} element={<RegisterPage />} />
                                                <Route path={NavigationNames.Login} element={<LoginPage />} />
                                                <Route
                                                    path={NavigationNames.PasswordReset}
                                                    element={<PasswordResetPage />}
                                                />
                                                <Route path='*' element={<NotFound />} />
                                            </Routes>
                                        </AuthProvider>
                                    </CartProvider>
                                </SnackbarProvider>
                            </ScrollTop>

                            <CookieConsent />
                        </div>
                    </ApolloProvider>
                </BrowserRouter>
            </HelmetProvider>
        </ThemeProvider>
    );
};

export default App;
