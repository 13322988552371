import { useMediaQuery, useTheme } from '@mui/material';

const useBreakpoint = () => {
    const theme = useTheme();
    const belowTablet: boolean = useMediaQuery(theme.breakpoints.down('md'));
    const belowDesktop: boolean = useMediaQuery(theme.breakpoints.down('lg'));

    const breakpoints = {
        belowTablet,
        belowDesktop,
    };

    return breakpoints;
};

export default useBreakpoint;
