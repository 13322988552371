import React from 'react';
import s from './AboutUsPage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import { Helmet } from 'react-helmet-async';
import { Typography } from '@mui/material';

type Props = {};

const AboutUsPage = (props: Props) => {
    return (
        <>
            <Helmet>
                <title>O nas</title>
                <meta
                    name='description'
                    content={`Poland Optical to renomowana ogólnopolska firma, której specjalizacją jest sprzedaż różnego
                            typu sprzętu optycznego oraz urządzeń okulistycznych. Jesteśmy z Państwem już od 20 lat.
                            Dzięki szerokiej wiedzy oraz wieloletniemu doświadczeniu, wspieramy wszystkich tych, którzy
                            profesjonalnie dbają o zdrowie oczu pacjentów w całej Polsce.`}
                />
            </Helmet>
            <Layout>
                <SubPage title='O nas'>
                    <div className={s.container}>
                        <Typography variant='h4' component='h1' className={s.title}>
                            Poland Optical
                        </Typography>

                        <Typography variant='h5' component='h2' className={s.subtitle}>
                            Sprzedaż diagnostycznych i chirurgicznych urządzeń okulistycznych
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            Poland Optical to renomowana ogólnopolska firma, której specjalizacją jest sprzedaż różnego
                            typu sprzętu optycznego oraz urządzeń okulistycznych. Jesteśmy z Państwem już od 20 lat.
                            Dzięki szerokiej wiedzy oraz wieloletniemu doświadczeniu, wspieramy wszystkich tych, którzy
                            profesjonalnie dbają o zdrowie oczu pacjentów w całej Polsce.
                        </Typography>

                        <Typography variant='h5' component='h2' className={s.subtitle}>
                            Współpracujemy z najlepszymi
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            W naszych katalogach produktów znajdą Państwo artykuły i urządzenia pochodzące wyłącznie od
                            najbardziej znanych i cenionych producentów sprzętu okulistycznego i optycznego na rynku.
                            Dzięki takiemu podejściu mogą mieć Państwo pewność, że dokonując zakupów w naszej firmie,
                            otrzymujecie sprzęt, który zyskał uznanie wśród profesjonalistów na całym świecie, jest
                            nowoczesny i w 100% bezpieczny. Jesteśmy bezpośrednim importerem i wyłącznym dystrybutorem
                            sprzętu optycznego i urządzeń okulistycznych japońskiej firmy NIDEK. Oprócz tego
                            współpracujemy także z takimi firmami, jak, CSO, TAKAGI , MSD, Mecanottica Mazza i SBM
                            Sistemi.
                        </Typography>

                        <Typography variant='h5' component='h2' className={s.subtitle}>
                            Sprzęt okulistyczny i optyczny
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            Rynek sprzętu optycznego i okulistycznego jest bardzo dynamiczny i nieustannie podlega
                            rozwojowi i udoskonaleniom. Mając na uwadze zachodzące zmiany, Poland Optical nieustannie
                            aktualizuje swoją ofertę, wzbogacają ją o najnowocześniejsze na rynku urządzenia. Staramy
                            się, aby oferta naszego sklepu była czytelna i przejrzysta oraz żeby spełniała oczekiwania
                            nawet najbardziej wymagających klientów. Korzystając z usług naszego e-sklepu mogą Państwo
                            kompleksowo wyposażyć swoje gabinety i zakłady optyczne. Zajmujemy się między innymi
                            sprzedażą diagnostycznych, terapeutycznych i chirurgicznych urządzeń okulistycznych.
                            Wszystkie oferowane przez nas urządzenia okulistyczne i optyczne objęte są gwarancją
                            użytkowania oraz posiadają wszystkie niezbędne certyfikaty potwierdzające zgodność z
                            dyrektywami Unii Europejskiej.
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            W naszych katalogach produktów znajdą Państwo m.in. autorefraktometry, tonometry, foroptery
                            czy funduskamery. Oferujemy także lampy szczelinowe, mikroperymetr, analizator rogówkowy
                            (OPD-Scan) oraz biometr optyczny. Ponadto posiadamy szeroki wybór zestawów szlifierskich do
                            obróbki szkieł okularowych firmy Nidek. Doskonałym uzupełnieniem naszej oferty handlowej są
                            różnego typu meble okulistyczne oraz programy komputerowe.
                        </Typography>

                        <Typography variant='h5' component='h2' className={s.subtitle}>
                            Opis firmy
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            Poland Optical specjalizuje się w sprzedaży diagnostycznych, terapeutycznych i
                            chirurgicznych urządzeń okulistycznych oraz wysokiej klasy sprzętu optycznego.
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            Nasza firma powstała w 1996 roku w wyniku połączenia działających wcześniej firm: FRAGX i
                            VISUS. Nasza siedziba znajduje się w Cieszynie, prowadzimy jednak działalność ogólnopolską.
                            Na początku koncentrowaliśmy się na sprzedaży stosowanego w optyce okularowej i optometrii
                            sprzętu używanego, z czasem rozszerzyliśmy jednak ofertę także o urządzenia okulistyczne.
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            Przełomem w naszej działalności był rok 1997. Wtedy to nawiązaliśmy współpracę i staliśmy
                            się autoryzowanymi dystrybutorami włoskich producentów wyposażenia gabinetów okulistycznych
                            i salonów optycznych: Meccanottica Mazza i SBISA, rozpoczynając tym samym sprzedaż urządzeń
                            fabrycznie nowych.
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            W roku 1999 całkowicie wycofaliśmy się ze sprzedaży urządzeń używanych i rozszerzyliśmy
                            swoją ofertę o urządzenia innych znanych i cenionych na całym świecie producentów, takich
                            jak: REICHERT, TOMEY, F.I.S.O. oraz C.S.O.
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            W kwietniu 2001 roku zostaliśmy wyłącznym przedstawicielem w Polsce firmy Meccanottica
                            Mazza, a niedługo potem podpisaliśmy kontrakt dający nam wyłączność na sprzedaż w Polsce
                            produktów firmy C.S.O. – w szczególności znanych na całym świecie ze swej wysokiej jakości
                            lamp szczelinowych , nie tylko pod szyldem C.S.O., ale też innych marek, np. Rodenstock czy
                            Nidek i innych, dla których są produkowane.
                        </Typography>

                        <Typography variant='h5' component='h2' className={s.subtitle}>
                            Importer i dystrybutor urządzeń firmy NIDEK
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            W roku 2003 Poland Optical nawiązał również współpracę i stał się dystrybutorem urządzeń
                            japońskiej firmy Nidek – światowego lidera w produkcji urządzeń okulistycznych i optycznych.
                            Obecnie jesteśmy najdynamiczniej rozwijającym się dystrybutorem w Polsce tej uznanej marki -
                            od 2004 roku wzbogacając swą ofertę o urządzenia chirurgiczne i chirurgiczno-diagnostyczne,
                            m.in. lasery.
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            Oprócz sprzedaży wysokiej jakości sprzętu optycznego i okulistycznego, Poland Optical
                            prowadzi także, autoryzowany i potwierdzony certyfikatami jakości, gwarancyjny i
                            pogwarancyjny serwis oferowanych urządzeń.
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            W celu udogodnienia Państwu kontaktu z naszą firmą, oprócz głównej siedziby w Cieszynie,
                            posiadamy także swoje przedstawicielstwa w Warszawie i w Poznaniu. Dzięki rosnącej liczbie
                            dystrybutorów i zatrudnionych pracowników możemy zapewnić naszym klientom najwyższy poziom
                            obsługi. Od stycznia 2008 roku prowadzimy również oddział w Czechach i na Słowacji, skąd
                            dystrybuujemy część swoich produktów, głównie urządzenia laboratorium optycznego firmy
                            NIDEK, na rynek czeski i słowacki.
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            Gwarantujemy pełną i rzetelną informację o oferowanym przez nas sprzęcie okulistycznym i
                            optycznym, profesjonalne doradztwo przy doborze produktów, atrakcyjne warunki sprzedaży oraz
                            korzystny leasing operacyjny i kapitałowy.
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            Wszystkie oferowane przez nas produkty objęte są gwarancją oraz posiadają certyfikaty
                            potwierdzające ich najwyższą jakość i zgodność ze standardami i dyrektywami Unii
                            Europejskiej.
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            Katalog produktów zaprezentowany na stronie internetowej ma charakter informacyjny i nie
                            zawiera pełnej oferty handlowej naszej firmy.
                        </Typography>

                        <Typography variant='body1' className={s.secondaryText}>
                            Zapraszamy do kontaktu z naszymi przedstawicielami handlowymi.
                        </Typography>

                        <Typography variant='h5' component='h2' className={s.subtitle}>
                            Jesteśmy w zasięgu wzroku!
                        </Typography>
                    </div>
                </SubPage>
            </Layout>
        </>
    );
};

export default AboutUsPage;
