import React from 'react';
import s from './OrderedProducts.module.scss';
import { Divider, Link as MuiLink, Typography } from '@mui/material';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { OrderDetails } from 'types/prestashopREST/OrderDetails';
import { Link } from 'react-router-dom';

type Props = { data: OrderDetails };

const OrderedProducts = ({ data }: Props) => {
    return (
        <>
            <Typography component='h2' variant='h5'>
                Zamówione produkty
            </Typography>

            <ul className={s.list}>
                {Object.keys(data.products || {}).map((key) => {
                    if (!data) return null;

                    const product = data.products[key];

                    return (
                        <li key={product.id_product} className={s.listItem}>
                            <div className={s.imgContainer}>
                                <img
                                    src={product.cover.bySize.small_default.url}
                                    alt={product.name}
                                    width={product.cover.bySize.small_default.width}
                                />
                            </div>
                            <div className={s.infoContainer}>
                                <MuiLink
                                    component={Link}
                                    to={`${NavigationNames.Shop}/${product.id_category_default}/${product.id_product}`}
                                >
                                    <Typography variant='body1' component='h6'>
                                        {product.name}
                                    </Typography>
                                </MuiLink>

                                <div className={s.quantityContainer}>
                                    <Typography variant='body1' fontWeight={600} className={s.price}>
                                        {product.price}
                                        <Typography variant='body2' component='span'>
                                            za sztukę
                                        </Typography>
                                    </Typography>

                                    <Typography variant='body1' component='span' className={s.quantity}>
                                        {product.quantity} szt.
                                    </Typography>
                                </div>

                                <Typography variant='body1' component='span' className={s.total}>
                                    Razem:
                                    <Typography fontWeight={600}>{product.total} </Typography>
                                </Typography>
                            </div>
                        </li>
                    );
                })}
            </ul>

            <Divider />

            <div className={s.summary}>
                <div className={s.summaryRow}>
                    <Typography variant='body1'>
                        {data.subtotals.products.label} ({data.products_count} szt.)
                    </Typography>
                    <Typography>{data.subtotals.products.value}</Typography>
                </div>

                <div className={s.summaryRow}>
                    <Typography variant='body1'>{data.subtotals.shipping.label}</Typography>
                    <Typography>{data.subtotals.shipping.value}</Typography>
                </div>

                <div className={s.summaryRow}>
                    <Typography variant='body1' fontWeight={600}>
                        {data.totals.total.label}
                    </Typography>
                    <Typography variant='h6' fontWeight={600}>
                        {data.totals.total.value}
                    </Typography>
                </div>
            </div>
        </>
    );
};

export default OrderedProducts;
