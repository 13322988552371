import React from 'react';
import s from './OurOfferSection.module.scss';
import Section from 'components/Section/Section';
import OfferCard from './OfferCard/OfferCard';

import financeImage from 'assets/offer-images/offer-svg/financing.svg';
import discountsImage from 'assets/offer-images/offer-svg/discounts.svg';
import eventsImage from 'assets/offer-images/offer-svg/events.svg';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';

interface Props {}

const OurOffer = (props: Props) => {
    return (
        <Section>
            <div className={s.container}>
                <OfferCard
                    image={eventsImage}
                    imageAlt={'Nowości'}
                    title='Nowości'
                    link={NavigationNames.NewProducts}
                    borderColor='light'
                    borderPostion='left'
                />

                <OfferCard
                    image={financeImage}
                    imageAlt={'Finansowanie'}
                    title='Finansowanie'
                    link={NavigationNames.Financing}
                    borderColor='dark'
                    borderPostion='center'
                />

                <OfferCard
                    image={discountsImage}
                    imageAlt={'Akcje specjalne'}
                    title='Akcje specjalne'
                    link={NavigationNames.SpecialOffers}
                    borderColor='light'
                    borderPostion='right'
                />
            </div>
        </Section>
    );
};

export default OurOffer;
