// export const STRAPI_URL: string = 'https://admin.polandoptical.pl';
// export const STRAPI_URL: string = 'http://localhost:1337';

export const STRAPI_URL: string =
    process.env.NODE_ENV === 'production' ? 'https://admin.polandoptical.pl' : 'http://localhost:1337';

export const addStrapiUrl = (url: string) => {
    return STRAPI_URL + url;
};

export const addCMSUrlToRichText = (text: string) => {
    return text.replaceAll('/uploads', `${STRAPI_URL}/uploads`);
};
