import React, { useState } from 'react';
import s from './Navbar.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from 'assets/logo_20_years.svg';
import MobileMenu from './MobileMenu/MobileMenu';
import Dropdown from 'components/Layout/Navbar/Dropdown/Dropdown';
import DropdownItem from 'components/Layout/Navbar/Dropdown/DropdownItem/DropdownItem';
import DropdownCategory from 'components/Layout/Navbar/Dropdown/DropdownCategory/DropdownCategory';
import { gql, useQuery } from '@apollo/client';

interface Props {}

const GET_CATEGORIES = gql`
    query GetProductCategories {
        productCategories(pagination: { limit: -1 }) {
            data {
                id
                attributes {
                    name
                    url
                    subcategories(pagination: { limit: -1 }) {
                        data {
                            id
                            attributes {
                                name
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export enum NavigationNames {
    Home = '/',
    Products = '/produkty',
    Shop = '/sklep',
    Service = '/serwis',
    News = '/aktualnosci',
    AboutUs = '/o-nas',
    Contact = '/kontakt',
    SpecialOffers = '/akcje-specjalne',
    Financing = '/finansowanie',
    LegalNotice = '/noty-prawne',
    TermsOfSale = '/ogolne-warunki-sprzedazy',
    Rodo = '/rodo',
    NewProducts = '/nowosci',
    Register = '/rejestracja',
    Login = '/logowanie',
    PasswordReset = '/przypomnienie-hasla',
    Profile = '/profil',
}

const Navbar = (props: Props) => {
    const { pathname } = useLocation();
    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_CATEGORIES);

    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
    const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);

    const handleMouseEnter = () => setIsProductsDropdownOpen(true);

    const handleMouseLeave = () => setIsProductsDropdownOpen(false);

    return (
        <nav className={s.navbar}>
            <Link to='/' className={s.logo}>
                <img alt='Logo' src={logo} className={s.logoImg} />
            </Link>

            <ul className={s.linksList}>
                <li className={s.linksListItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <Link
                        to='/produkty'
                        className={`${s.link} ${pathname.includes(NavigationNames.Products) ? s.selected : ''}`}
                    >
                        Produkty
                    </Link>

                    {!queryLoading && queryData && (
                        <Dropdown isOpen={isProductsDropdownOpen}>
                            {queryData.productCategories.data.map((category: any) => {
                                if (category.attributes.subcategories.data.length !== 0)
                                    return (
                                        <DropdownCategory
                                            name={category.attributes.name}
                                            link={`/produkty/${category.attributes.url}`}
                                            key={category.attributes.url}
                                        >
                                            {category.attributes.subcategories.data.map((subcategory: any) => (
                                                <DropdownItem
                                                    link={`/produkty/${category.attributes.url}/${subcategory.attributes.url}`}
                                                    key={subcategory.attributes.url}
                                                >
                                                    {subcategory.attributes.name}
                                                </DropdownItem>
                                            ))}
                                        </DropdownCategory>
                                    );

                                return null;
                            })}
                        </Dropdown>
                    )}
                </li>

                <Link
                    to={NavigationNames.Shop}
                    className={`${s.link} ${pathname.includes(NavigationNames.Shop) ? s.selected : ''}`}
                >
                    Sklep
                </Link>
                <Link
                    to={NavigationNames.Service}
                    className={`${s.link} ${pathname.includes(NavigationNames.Service) ? s.selected : ''}`}
                >
                    Serwis
                </Link>
                <Link
                    to={NavigationNames.News}
                    className={`${s.link} ${pathname.includes(NavigationNames.News) ? s.selected : ''}`}
                >
                    Aktualności
                </Link>
                <Link
                    to={NavigationNames.AboutUs}
                    className={`${s.link} ${pathname.includes(NavigationNames.AboutUs) ? s.selected : ''}`}
                >
                    O nas
                </Link>
                <Link
                    to={NavigationNames.Contact}
                    className={`${s.link} ${pathname.includes(NavigationNames.Contact) ? s.selected : ''}`}
                >
                    Kontakt
                </Link>
            </ul>

            <FontAwesomeIcon icon={faBars} className={s.mobileBars} onClick={() => setIsMobileMenuActive(true)} />

            <MobileMenu isActive={isMobileMenuActive} onClose={() => setIsMobileMenuActive(false)} />
        </nav>
    );
};

export default Navbar;
