import React from 'react';
import s from './ProductSidebar.module.scss';
import FeaturedProducts from './FeaturedProducts/FeaturedProducts';
import { IAccessories, IRelatedProducts } from 'types/types';

interface Props {
    accessories: IAccessories;
    relatedProducts: IRelatedProducts;
}

const ProductSidebar = ({ accessories, relatedProducts }: Props) => {
    return (
        <div className={s.container}>
            {accessories.data.length > 0 && <FeaturedProducts title='Akcesoria' products={accessories} />}
            {relatedProducts.data.length > 0 && (
                <FeaturedProducts title='Powiązane urządzenia' products={relatedProducts} />
            )}
        </div>
    );
};

export default ProductSidebar;
