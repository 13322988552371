import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import Event from './Event/Event';
import EventsList from './EventsList/EventsList';

type Props = {};

const EventsPage = (props: Props) => {
    return (
        <>
            <Helmet>
                <title>Aktualności</title>
            </Helmet>
            <Layout>
                <Routes>
                    <Route
                        index
                        element={
                            <SubPage title='Aktualności' currentPageName='Aktualności'>
                                <EventsList />
                            </SubPage>
                        }
                    />
                    <Route path=':id' element={<Event />} />
                </Routes>
            </Layout>
        </>
    );
};

export default EventsPage;
