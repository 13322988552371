import React, { ReactNode } from 'react';
import s from './MobileMenuLayout.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
    isActive: boolean;
    onClose: () => void;
    children: ReactNode;
    title: string;
    className?: string;
    fullWidth?: boolean;
}

const MobileMenuLayout = ({ isActive, onClose, children, title, fullWidth = false, className = '' }: Props) => {
    return (
        <div className={`${s.container} ${isActive ? s.active : ''} ${className} ${fullWidth ? s.fullWidth : ''}`}>
            <div className={s.menuTop}>
                <h3 className={s.title}>{title}</h3>
                <FontAwesomeIcon icon={faTimes} className={s.closeButton} onClick={onClose} />
            </div>
            <div className={s.menuBody}>{children}</div>
        </div>
    );
};

export default MobileMenuLayout;
