import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FinishedOrderApiResponse } from 'types/prestashopREST/Checkout/FinishedOrderDetails';
import { AddressInfo } from 'types/prestashopREST/Checkout/GetAddresses';
import { Carrier } from 'types/prestashopREST/Checkout/GetCarriers';
import { PaymentOption } from 'types/prestashopREST/Checkout/GetPaymentMethods';
import { useCart } from './CartContext';
import { useSnackbar } from './SnackbarContext';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';

interface CheckoutContextType {
    loading: boolean;
    setAddress: (address: AddressInfo | null) => Promise<void>;
    selectedAddress: AddressInfo | null;
    selectedCarrier: Carrier | null;
    setCarrier: (carrier: Carrier | null) => Promise<void>;
    selectedPaymentOption: PaymentOption | null;
    setSelectedPaymentOption: React.Dispatch<React.SetStateAction<PaymentOption | null>>;
    finishCheckout: () => Promise<void>;
    agreementChecked: boolean;
    setAgreementChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckoutContext = createContext<CheckoutContextType | null>(null);

export function CheckoutProvider({ children }: { children: React.ReactNode }) {
    const cart = useCart();
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [selectedAddress, setSelectedAddress] = useState<AddressInfo | null>(null);

    const setAddress = async (address: AddressInfo | null) => {
        setSelectedAddress(address);
        setLoading(true);

        const body = {
            id_address: address?.id ?? 0,
            id_address_invoice: address?.id ?? 0,
            id_address_delivery: address?.id ?? 0,
            note: 'Test note',
        };

        const res = await fetch(`${process.env.REACT_APP_PRESTASHOP_URL}/rest/setaddresscheckout`, {
            method: 'POST',
            body: JSON.stringify(body),
            credentials: 'include',
            mode: 'cors',
        });

        // const data = await res.json();

        setLoading(false);
    };

    const [selectedCarrier, setSelectedCarrier] = useState<Carrier | null>(null);

    const setCarrier = async (carrier: Carrier | null) => {
        setSelectedCarrier(carrier);
        setLoading(true);

        const body = {
            // id_address: address?.id ?? 0,
            id_carrier: carrier?.id ?? 0,
            note: 'Test note',
        };

        const res = await fetch(`${process.env.REACT_APP_PRESTASHOP_URL}/rest/setcarriercheckout`, {
            method: 'POST',
            body: JSON.stringify(body),
            credentials: 'include',
            mode: 'cors',
        });

        // const data = await res.json();

        setLoading(false);
    };

    const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOption | null>(null);
    const [agreementChecked, setAgreementChecked] = useState(false);

    const finishCheckout = async () => {
        if (selectedPaymentOption === null) return;

        setLoading(true);

        try {
            const url = `${process.env.REACT_APP_PRESTASHOP_URL}/rest/${selectedPaymentOption.module_name}`;
            const res = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                mode: 'cors',
            });

            const data: FinishedOrderApiResponse = await res.json();
            if (data.success) {
                cart?.refetch();
                navigate(`./potwierdzenie/${data.psdata.order.details.id}`);
            } else {
                console.error(data);
                snackbar.openSnackbar('Wystąpił błąd podczas składania zamówienia', 'error');
                cart?.refetch();
                navigate(NavigationNames.Shop);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    const value = {
        loading,
        setAddress,
        selectedAddress,
        selectedCarrier,
        setCarrier,
        selectedPaymentOption,
        setSelectedPaymentOption,
        finishCheckout,
        agreementChecked,
        setAgreementChecked,
    };

    return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>;
}

export function useCheckout() {
    return useContext(CheckoutContext);
}
