import React from 'react';
import s from './SideBarItem.module.scss';
import { Divider, Typography } from '@mui/material';

type Props = {
    titleVariant: 'h3' | 'h4' | 'h5' | 'h6';
    titleComponent: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    children: React.ReactNode;
    title: string;
    startIcon?: React.ReactNode;
    showDivider?: boolean;
};

const SideBarItem = ({ titleVariant, children, title, startIcon, showDivider = true }: Props) => {
    return (
        <div className={s.container}>
            <div className={s.titleContainer}>
                {startIcon}

                <Typography variant={titleVariant} component='h2'>
                    {title}
                </Typography>
            </div>

            {showDivider && <Divider className={s.divider} />}

            <div className={s.childrenContainer}>{children}</div>
        </div>
    );
};

export default SideBarItem;
