import React from 'react';
import s from './ShopCategoriesList.module.scss';
import ShopCategoriesListItem from './ShopCategoriesListItem/ShopCategoriesListItem';
import { Category } from 'types/prestashopApi';
import { Typography } from '@mui/material';

type Props = { categories: Category[] | null };

const ShopCategoriesList = ({ categories }: Props) => {
    if (!categories) return null;

    return (
        <div>
            <div className={s.textContainer}>
                <Typography component='h2' variant='h5'>
                    WSZYSTKIE KATEGORIE
                </Typography>
                <Typography component='p' variant='body1' color='primary'>
                    Wybierz kategorię, aby zobaczyć produkty
                </Typography>
            </div>
            <ul className={s.list}>
                {categories?.map((category) => (
                    <ShopCategoriesListItem key={category.id} category={category} />
                ))}
            </ul>
        </div>
    );
};

export default ShopCategoriesList;
