import React from 'react';
import ProfilePage from 'pages/ProfilePage/ProfilePage';
import { Routes, Route } from 'react-router-dom';
import ProfileSettings from 'pages/ProfilePage/ProfileSettings/ProfileSettings';
import OrderHistoryPage from 'pages/ProfilePage/OrderHistoryPage/OrderHistoryPage';
import OrderDetailsPage from 'pages/ProfilePage/OrderDetailsPage/OrderDetailsPage';
import ProtectedRoute from './ProtectedRoute';

export enum ProfileRoutesNames {
    Settings = 'ustawienia',
    OrderHistory = 'historia-zamowien',
}

const ProfileRoutes: React.FC = () => {
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <ProtectedRoute>
                        <ProfilePage />
                    </ProtectedRoute>
                }
            />
            <Route
                path={ProfileRoutesNames.Settings}
                element={
                    <ProtectedRoute>
                        <ProfileSettings />
                    </ProtectedRoute>
                }
            />
            <Route path={ProfileRoutesNames.OrderHistory}>
                <Route
                    index
                    element={
                        <ProtectedRoute>
                            <OrderHistoryPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path=':id'
                    element={
                        <ProtectedRoute>
                            <OrderDetailsPage />
                        </ProtectedRoute>
                    }
                />
            </Route>
        </Routes>
    );
};

export default ProfileRoutes;
