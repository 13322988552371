import React from 'react';
import s from './ShopCategoryFilters.module.scss';
import { faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryProductsApiResponse } from 'types/prestashopREST/CategoryProduct';
import CategoriesTreeView from '../CategoriesTreeView/CategoriesTreeView';
import FilterFacet from '../FilterFacet/FilterFacet';
import SideBarItem from '../SideBarItem/SideBarItem';
import { Button } from '@mui/material';

type Props = {
    data: CategoryProductsApiResponse | null;
    setSelectedFiltersFacet: React.Dispatch<React.SetStateAction<string>>;
    loading: boolean;
    clearFilters: () => void;
    closeMobileFilters: () => void;
};

const ShopCategoryFilters = ({ data, setSelectedFiltersFacet, loading, clearFilters, closeMobileFilters }: Props) => {
    const handleFilterClear = () => clearFilters();

    return (
        <div className={s.container}>
            {data && (
                <SideBarItem titleVariant='h5' titleComponent='h3' title='Podkategorie'>
                    <CategoriesTreeView category={data.psdata.categories} closeMobileFilters={closeMobileFilters} />
                </SideBarItem>
            )}

            <div className={s.filtersTopContainer}>
                <SideBarItem
                    titleVariant='h5'
                    titleComponent='h3'
                    title='Filtry'
                    startIcon={<FontAwesomeIcon icon={faFilter} />}
                    showDivider={false}
                >
                    <Button
                        startIcon={<FontAwesomeIcon icon={faFilterCircleXmark} />}
                        fullWidth
                        variant='outlined'
                        color='secondary'
                        onClick={handleFilterClear}
                        // remove when clear implemented
                        disabled
                        // disabled={loading}
                    >
                        Wyczyść
                    </Button>
                </SideBarItem>
            </div>

            <div className={s.facetsContainer}>
                {data?.psdata.facets.map((facet) => {
                    if (!facet.displayed) return null;

                    return (
                        <FilterFacet
                            facet={facet}
                            key={facet.label}
                            setSelectedFiltersFacet={setSelectedFiltersFacet}
                            loading={loading}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ShopCategoryFilters;
