import React, { useState } from 'react';
import s from './PersonalDataStep.module.scss';
import LoginForm from 'components/LoginForm/LoginForm';
import { useAuth } from 'contexts/AuthContext';
import { Button, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { green } from '@mui/material/colors';
import RegisterDialog from './RegisterDialog/RegisterDialog';

type Props = { nextStep: () => void };

const PersonalDataStep = ({ nextStep }: Props) => {
    const auth = useAuth();

    const [registerDialogOpen, setRegisterDialogOpen] = useState(false);

    const handleLoginSuccess = () => {
        nextStep();
    };

    const handleRegisterClick = () => {
        setRegisterDialogOpen(true);
    };

    const handleDialogClose = () => setRegisterDialogOpen(false);

    return (
        <>
            <div className={s.container}>
                {auth?.isAuthenticated ? (
                    <div>
                        <Typography>
                            Zalogowany jako:{' '}
                            <b>
                                {auth.userData?.firstname} {auth.userData?.lastname} ({auth.userData?.email})
                            </b>
                        </Typography>

                        <Typography>
                            Jeśli to nie ty,{' '}
                            <Link sx={{ cursor: 'pointer' }} onClick={auth?.logout}>
                                wyloguj się
                            </Link>{' '}
                            (koszyk zostanie opróżniony)
                        </Typography>
                    </div>
                ) : (
                    <>
                        <div className={s.login}>
                            <Typography variant='h5' align='center' component='h4' marginBottom={'1rem'}>
                                Logowanie
                            </Typography>
                            <LoginForm onLoginSuccess={handleLoginSuccess} showRegisterSuggestion={false} />
                        </div>
                        <div className={s.noAccount}>
                            <div>
                                <Typography variant='h6' component='h4' align='center'>
                                    Załóż konto i zyskaj dostęp do:
                                </Typography>
                                <List dense>
                                    <ListItem>
                                        <ListItemIcon sx={{ color: green[600] }}>
                                            <FontAwesomeIcon icon={faCheckCircle} size='xl' />
                                        </ListItemIcon>
                                        <ListItemText primary='Szybkiego procesu zamawiania' />
                                    </ListItem>

                                    <ListItem>
                                        <ListItemIcon sx={{ color: green[600] }}>
                                            <FontAwesomeIcon icon={faCheckCircle} size='xl' />
                                        </ListItemIcon>
                                        <ListItemText primary='Pełnej historii zamówień' />
                                    </ListItem>

                                    <ListItem>
                                        <ListItemIcon sx={{ color: green[600] }}>
                                            <FontAwesomeIcon icon={faCheckCircle} size='xl' />
                                        </ListItemIcon>
                                        <ListItemText primary='Specjalnych kodów rabatowych oraz promocji' />
                                    </ListItem>
                                </List>
                            </div>
                            <Button variant='contained' onClick={handleRegisterClick}>
                                Załóż konto
                            </Button>
                        </div>

                        {/* <div className={s.buyAsGuest}>
                            <Typography component='h4' align='center'>
                                Kup jako gość bez zakładania konta
                            </Typography>
                            <Button disabled variant='outlined' color='secondary'>
                                Kup jako gość
                            </Button>
                        </div> */}
                    </>
                )}
            </div>

            <RegisterDialog open={registerDialogOpen} handleClose={handleDialogClose} onSuccess={nextStep} />
        </>
    );
};

export default PersonalDataStep;
