import { gql } from '@apollo/client';

export const GET_REPRESENTATION_LOGOS = gql`
    query GetRepresentationLogos {
        representationLogos(sort: "order") {
            data {
                id
                attributes {
                    website_url
                    logo {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_LANDING_MEDIA = gql`
    query GetLandingMedia {
        landingPageMedia {
            data {
                id
                attributes {
                    content {
                        data {
                            attributes {
                                url
                                alternativeText
                                mime
                            }
                        }
                    }
                    video_poster {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    show_text
                    main_text
                    secondary_text
                    image_over_text {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_CAROUSEL_ITEMS = gql`
    query GetCarouselItems {
        carouselElements(pagination: { limit: -1 }, sort: "order:asc") {
            data {
                id
                attributes {
                    text
                    order
                    button_link
                    button_text
                    logo {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    image {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    external_link
                    download_file {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;
