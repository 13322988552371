import React from 'react';
import s from './CategoriesTreeView.module.scss';
import { Category } from '../../../../../types/prestashopREST/CategoryProduct';
import TreeViewItem from './TreeViewItem/TreeViewItem';

type Props = { category: Category; closeMobileFilters: () => void };

const CategoriesTreeView = ({ category, closeMobileFilters }: Props) => {
    return (
        <ul className={s.tree}>
            <TreeViewItem category={category} expanded closeMobileFilters={closeMobileFilters}></TreeViewItem>
        </ul>
    );
};

export default CategoriesTreeView;
