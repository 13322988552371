import * as React from 'react';
import s from './ProductsTable.module.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CartProduct } from 'types/prestashopREST/Cart';
import { IconButton, Link as MuiLink, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useCart } from 'contexts/CartContext';
import UncontrolledNumberInput from 'components/NumberInput/UncontrolledNumberInput/UncontrolledNumberInput';

type Props = {
    cartItems: CartProduct[];
};

export default function CartSummaryTable({ cartItems }: Props) {
    const cart = useCart();

    const handleDelete = (item: CartProduct) => {
        cart?.removeCartItem(item.id_product);
    };

    const handleQuantityChange = async (value: number, item: CartProduct) => {
        cart?.changeQuantity(value, item, false);
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='cart table'>
                <TableHead>
                    <TableRow>
                        <TableCell>Przedmiot</TableCell>
                        <TableCell>Nazwa</TableCell>
                        <TableCell align='right'>Cena</TableCell>
                        <TableCell align='right'>Ilość</TableCell>
                        <TableCell align='right'>Cena końcowa</TableCell>
                        <TableCell align='right'>Akcje</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cartItems.map((item) => (
                        <TableRow key={item.id_product}>
                            <TableCell>
                                <img src={item.image_url} alt={item.name} width={96} />
                            </TableCell>
                            <TableCell>
                                <MuiLink
                                    component={Link}
                                    to={`${NavigationNames.Shop}/${item.id_category_default}-${item.category}/${item.id_product}`}
                                >
                                    {item.name}
                                </MuiLink>
                            </TableCell>
                            <TableCell align='right'>{item.formatted_price}</TableCell>
                            <TableCell align='right'>
                                <div className={s.quantityInputContainer}>
                                    <UncontrolledNumberInput
                                        value={item.quantity}
                                        min={1}
                                        onChange={(value: number) => handleQuantityChange(value, item)}
                                        disabled={cart?.cartChanging}
                                    />
                                </div>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography variant='h6' fontWeight={600}>
                                    {item.formatted_total}
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Tooltip title='Usuń z koszyka'>
                                    <IconButton onClick={() => handleDelete(item)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
