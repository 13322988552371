import React from 'react';
import s from './OrderHistoryPage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import { Link } from 'react-router-dom';
import { OrderHistoryDetails } from 'types/prestashopREST/OrderHistory';
import useFetch from 'hooks/useFetch';
import { PrestashopRestApiResponse } from 'types/prestashopREST/ApiResponse';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Link as MuiLink,
    Chip,
    Tooltip,
    Alert,
} from '@mui/material';
import getContrastingTextColor from 'utils/getContrastingTextColor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {};

const URL = `${process.env.REACT_APP_PRESTASHOP_URL}/rest/orderhistory`;

const OrderHistoryPage = (props: Props) => {
    const { data, loading, error } = useFetch<PrestashopRestApiResponse<OrderHistoryDetails[]>>(URL);

    return (
        <Layout>
            <SubPage title='Historia zamówień' loading={loading} currentPageName='Historia zamówień'>
                {data?.psdata.length === 0 ? (
                    <Alert severity='info'>Nie złożyłeś jeszcze żadnego zamówienia.</Alert>
                ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label='cart table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Numer zamówienia</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell align='right'>Cena końcowa</TableCell>
                                    <TableCell align='center'>Płatność</TableCell>
                                    <TableCell align='center'>Status</TableCell>
                                    <TableCell align='center'>Faktura</TableCell>
                                    <TableCell align='center'>Akcje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.psdata.map((order) => (
                                    <TableRow key={order.id_order}>
                                        <TableCell>
                                            <MuiLink component={Link} to={`details`}>
                                                <Typography fontWeight={'500'}>{order.reference}</Typography>
                                            </MuiLink>
                                        </TableCell>
                                        <TableCell>{order.date_add}</TableCell>
                                        <TableCell align='right'>
                                            <Typography>
                                                {parseFloat(order.total_paid_tax_incl).toFixed(2)} zł brutto
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Typography>{order.payment}</Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Chip
                                                sx={{
                                                    background: order.order_state_color,
                                                    color: getContrastingTextColor(order.order_state_color),
                                                }}
                                                label={order.order_state}
                                            />
                                        </TableCell>
                                        <TableCell align='center'>
                                            {order.invoice === '1' ? (
                                                <FontAwesomeIcon icon={faCheck} size='xl' />
                                            ) : (
                                                <FontAwesomeIcon icon={faTimes} size='xl' />
                                            )}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Tooltip title='Szczegóły'>
                                                <Link to={order.id_order} className={s.link}>
                                                    <FontAwesomeIcon icon={faInfoCircle} size='xl' />
                                                </Link>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </SubPage>
        </Layout>
    );
};

export default OrderHistoryPage;
