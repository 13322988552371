import React from 'react';
import s from './LoginForm.module.scss';
import { Button, Link, TextField, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { LoginResult } from 'contexts/AuthContext.types';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';

type Props = { onLoginSuccess?: () => void; showRegisterSuggestion?: boolean };

interface IFormValues {
    email: string;
    password: string;
}

const LoginForm = ({ onLoginSuccess, showRegisterSuggestion = true }: Props) => {
    const auth = useAuth();

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Niepoprawny email').required('Email jest wymagany'),
        password: Yup.string().required('Hasło jest wymagane').min(5, 'Hasło musi mieć minimum 5 znaków'),
    });

    const handeSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
        const response: LoginResult | undefined = await auth?.login(values.email, values.password);

        if (response?.success) {
            setSubmitting(false);
            onLoginSuccess?.();
            return;
        }
    };
    return (
        <div>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handeSubmit}
            >
                {({ submitForm, isSubmitting, getFieldProps, touched, errors }) => (
                    <Form className={s.form}>
                        <TextField
                            {...getFieldProps('email')}
                            label='Adres e-mail'
                            variant='outlined'
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />
                        <TextField
                            {...getFieldProps('password')}
                            type='password'
                            label='Hasło'
                            variant='outlined'
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />

                        <Link component={RouterLink} to={`${NavigationNames.PasswordReset}`}>
                            <Typography variant='body2' component='p' textAlign='center'>
                                Nie pamiętasz hasła?
                            </Typography>
                        </Link>

                        <Button
                            disabled={isSubmitting}
                            variant='contained'
                            color='primary'
                            onClick={submitForm}
                            type='submit'
                        >
                            Zaloguj się
                        </Button>

                        {showRegisterSuggestion && (
                            <div>
                                <Typography variant='body2' component='p' textAlign='center'>
                                    Nie posiadasz konta?{' '}
                                    <Link component={RouterLink} to={`${NavigationNames.Register}`}>
                                        Zarejestruj się
                                    </Link>
                                </Typography>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default LoginForm;
