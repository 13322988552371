import React from 'react';
import s from './ProductTabs.module.scss';
import { Tabs, Tab } from '@mui/material';
import TabPanel from './TabPanel';
import { ProductData } from 'types/prestashopREST/ProductDetail';
import DOMPurify from 'dompurify';

type Props = {
    product?: ProductData;
};

const ProductTabs = ({ product }: Props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={s.container}>
            <div className={s.content}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label='Opis' disabled={!product?.description} />
                    {/* <Tab label='Szczegóły' disabled={product?.product_info.length === 0} /> */}
                    <Tab label='Szczegóły' disabled />
                </Tabs>

                {product && (
                    <>
                        <TabPanel value={value} index={0}>
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.description) }} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {product.product_info.map((info) => (
                                <div key={info.name}>
                                    <h3>{info.name}</h3>
                                    <p>{info.value}</p>
                                </div>
                            ))}
                        </TabPanel>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProductTabs;
