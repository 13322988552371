import BeanButton from 'components/BeanButton/BeanButton';
import React from 'react';
import s from './CarouselItem.module.scss';
import RoundImage from 'components/RoundImage/RoundImage';
import { Typography } from '@mui/material';

type Props = {
    text: string;
    link?: string;
    buttonText?: string;
    logoUrl?: string;
    logoAlt?: string;
    imageUrl: string;
    imageAlt?: string;
    externalLink?: boolean;
    downloadFileUrl?: string;
};

const CarouselItem = ({
    imageUrl,
    logoUrl,
    text,
    link,
    buttonText,
    logoAlt,
    imageAlt,
    externalLink = false,
    downloadFileUrl,
}: Props) => {
    return (
        <div className={s.container}>
            <div className={s.content}>
                <RoundImage className={s.image} imageSrc={imageUrl} imageAlt={imageAlt} />

                <div className={s.textContainer}>
                    {logoUrl && <img src={logoUrl} alt={logoAlt} className={s.smallImage} />}
                    <Typography variant='body1' fontSize='20px' className={s.description}>
                        {text}
                    </Typography>
                    {(link || downloadFileUrl) && buttonText && (
                        <BeanButton
                            className={s.ctaButton}
                            link={downloadFileUrl ? downloadFileUrl : link}
                            text={buttonText}
                            variant='outlined'
                            color='white'
                            externalLink={externalLink || downloadFileUrl !== undefined}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CarouselItem;
