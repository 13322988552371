import React, { useEffect, useState } from 'react';
import s from './ShopProductPage.module.scss';
import SubPage from 'components/SubPage/SubPage';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import { ProductData } from 'types/prestashopREST/ProductDetail';
import Layout from 'components/Layout/Layout';
import ProductInfo from './ProductInfo/ProductInfo';
import ProductMedia from './ProductMedia/ProductMedia';
import ProductTabs from './Tabs/ProductTabs';
import MedicalStaffCheckDialog from 'components/MedicalStaffCheckDialog/MedicalStaffCheckDialog';
import { Alert } from '@mui/material';
import { PrestashopRestApiResponse } from 'types/prestashopREST/ApiResponse';

interface Props {}

const ShopProductPage = (props: Props) => {
    const { productId } = useParams<string>();
    const [isMedicalStaffCheckDialogOpen, setIsMedicalStaffCheckDialogOpen] = useState(false);

    useEffect(() => {
        sessionStorage.getItem('isMedicalStaff') !== 'true' && setIsMedicalStaffCheckDialogOpen(true);
    }, []);

    const prestaFetchUrl: string = `${process.env.REACT_APP_PRESTASHOP_URL}/rest/productdetail?product_id=${productId}&image_type=large&with_all_images=0`;

    const { data, loading, error } = useFetch<PrestashopRestApiResponse<ProductData>>(prestaFetchUrl);

    return (
        <>
            <Helmet>
                <title>{data?.psdata?.meta_title || data?.psdata?.name}</title>
                <meta name='description' content={data?.psdata?.meta_description} />
                <link rel='canonical' href={window.location.href} />
            </Helmet>

            <Layout>
                <SubPage
                    loading={loading}
                    currentPageName={data?.psdata?.name || ''}
                    previousPageName={
                        `${data?.psdata?.category_name.charAt(0).toUpperCase()}${data?.psdata?.category_name.slice(
                            1
                        )}` || ''
                    }
                >
                    {data?.success ? (
                        <>
                            <div className={`${s.container}`}>
                                <ProductInfo product={data.psdata} />
                                <ProductMedia images={data.psdata.images} coverImageUrl={data.psdata.cover_image} />
                            </div>
                            <ProductTabs product={data?.psdata} />
                        </>
                    ) : (
                        <Alert severity='error'>{data?.message}</Alert>
                    )}
                </SubPage>
            </Layout>

            <MedicalStaffCheckDialog
                open={isMedicalStaffCheckDialogOpen}
                handleClose={() => setIsMedicalStaffCheckDialogOpen(false)}
            />
        </>
    );
};

export default ShopProductPage;
