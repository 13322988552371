import BeanButton from 'components/BeanButton/BeanButton';
import React from 'react';
import s from './CategoryCard.module.scss';
import { Typography } from '@mui/material';

type Props = {
    title: string;
    description: string;
    link: string;
    bgColor?: 'white' | 'gray';
    barColor?: 'green' | 'blue' | 'yellow' | 'pink';
};

const CategoryCard = ({ title, description, link, bgColor = 'white', barColor = 'blue' }: Props) => {
    return (
        <div className={`${s.card} ${bgColor ? s[bgColor] : ''}`}>
            <div className={s.contentContainer}>
                <div className={s.textContainer}>
                    <Typography variant='h4' component='h3' className={s.title}>
                        {title}
                    </Typography>
                    <Typography variant='body1' fontSize={'1.2rem'} component='p' className={s.description}>
                        {description}
                    </Typography>
                </div>

                <BeanButton className={s.ctaButton} text='Sprawdź' link={link} variant='outlined' />
            </div>

            <div className={`${s.bottomBar} ${barColor ? s[barColor] : ''}`} />
        </div>
    );
};

export default CategoryCard;
