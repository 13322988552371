import Layout from 'components/Layout/Layout';
import LoginForm from 'components/LoginForm/LoginForm';
import SubPage from 'components/SubPage/SubPage';
import { useAuth } from 'contexts/AuthContext';
import React, { useEffect } from 'react';

type Props = {};

const LoginPage = (props: Props) => {
    const auth = useAuth();

    useEffect(() => {
        if (auth?.isAuthenticated) window.history.back();
    }, [auth?.isAuthenticated]);

    const returnToPrevPage = () => {
        window.history.back();
        window.history.back();
    };

    return (
        <Layout>
            <SubPage title='Logowanie'>
                <LoginForm onLoginSuccess={returnToPrevPage} />
            </SubPage>
        </Layout>
    );
};

export default LoginPage;
