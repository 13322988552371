import React from 'react';
import s from './Sidebar.module.scss';
import { CategoryProductsApiResponse } from 'types/prestashopREST/CategoryProduct';
import { Button } from '@mui/material';
import MobileFilters from './MobileFilters/MobileFilters';
import ShopCategoryFilters from './ShopCategoryFilters/ShopCategoryFilters';

type Props = {
    data: CategoryProductsApiResponse | null;
    setSelectedFiltersFacet: React.Dispatch<React.SetStateAction<string>>;
    clearFilters: () => void;
    loading: boolean;
};

const Sidebar = ({ data, setSelectedFiltersFacet, clearFilters, loading }: Props) => {
    const [mobileFiltersOpen, setMobileFiltersOpen] = React.useState<boolean>(false);

    const handleMobileFiltersOpen = () => setMobileFiltersOpen(true);
    const handleMobileFiltersClose = () => setMobileFiltersOpen(false);

    return (
        <>
            <div className={s.mobileFiltersButtonContainer}>
                <Button onClick={handleMobileFiltersOpen} variant='contained' fullWidth>
                    Filtry i kategorie
                </Button>
            </div>

            <div className={s.sideContainer}>
                <ShopCategoryFilters
                    data={data}
                    setSelectedFiltersFacet={setSelectedFiltersFacet}
                    clearFilters={clearFilters}
                    loading={loading}
                    closeMobileFilters={handleMobileFiltersClose}
                />
            </div>

            <MobileFilters
                data={data}
                setSelectedFiltersFacet={setSelectedFiltersFacet}
                loading={loading}
                open={mobileFiltersOpen}
                handleClose={handleMobileFiltersClose}
                clearFilters={clearFilters}
                closeMobileFilters={handleMobileFiltersClose}
            />
        </>
    );
};

export default Sidebar;
