import React from 'react';
import s from './ProfileCard.module.scss';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

type Props = { link: string; name: string };

const ProfileCard = ({ link, name }: Props) => {
    return (
        <Link to={`${link}`} className={s.card}>
            <Typography component='h3' variant='h6'>
                {name}
            </Typography>

            <div className={s.bottomBar} />
        </Link>
    );
};

export default ProfileCard;
