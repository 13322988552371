import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useCheckout } from 'contexts/CheckoutContext';
import DOMPurify from 'dompurify';
import { PaymentOption } from 'types/prestashopREST/Checkout/GetPaymentMethods';

type Props = {
    paymentOption: PaymentOption;
    selectedPaymentOption: PaymentOption | null;
};

const paymentOptionDescriptions: Record<string, string> = {
    ps_wirepayment:
        'Proszę przelać kwotę faktury na nasze konto bankowe. Otrzymasz potwierdzenie zamówienia przez e-mail zawierające dane bankowe i numer zamówienia.',
    ps_cashondelivery: 'Płacisz za towar przy dostawie',
};

const PaymentMethodCard = ({ paymentOption, selectedPaymentOption }: Props) => {
    const checkout = useCheckout();

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (checkout?.loading) return;
        if (event.target.checked) {
            checkout?.setSelectedPaymentOption(paymentOption);
        } else {
            checkout?.setCarrier(null);
        }
    };

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkout?.selectedPaymentOption?.id === paymentOption.id}
                        onChange={handleCheckboxChange}
                    />
                }
                label={paymentOption.call_to_action_text}
            />

            {selectedPaymentOption?.id === paymentOption.id && (
                <Typography variant='body2'>{paymentOptionDescriptions[paymentOption.module_name]}</Typography>
            )}
        </div>
    );
};

export default PaymentMethodCard;
