import * as React from 'react';
import s from './ProductsList.module.scss';
import { CartProduct } from 'types/prestashopREST/Cart';
import { IconButton, Link as MuiLink, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useCart } from 'contexts/CartContext';
import UncontrolledNumberInput from 'components/NumberInput/UncontrolledNumberInput/UncontrolledNumberInput';

type Props = { cartItems: CartProduct[] };

const ProductsList = ({ cartItems }: Props) => {
    const cart = useCart();

    const handleDelete = (item: CartProduct) => {
        cart?.removeCartItem(item.id_product);
    };

    const handleQuantityChange = async (value: number, item: CartProduct) => {
        cart?.changeQuantity(value, item, false);
    };

    return (
        <ul className={s.list}>
            {cartItems.map((item) => (
                <li key={item.id_product} className={s.listItem}>
                    <div className={s.imgContainer}>
                        <img src={item.image_url} alt={item.name} width={96} />
                    </div>
                    <div className={s.infoContainer}>
                        <div className={s.topContainer}>
                            <MuiLink
                                component={Link}
                                to={`${NavigationNames.Shop}/${item.id_category_default}-${item.category}/${item.id_product}`}
                            >
                                <Typography variant='body1' component='h6'>
                                    {item.name}
                                </Typography>
                            </MuiLink>

                            <Typography variant='body1' fontWeight={600} className={s.price}>
                                {/* <Typography variant='body2' component='span'>
                                    1 szt.
                                </Typography> */}
                                {item.formatted_price}
                                <Typography variant='body2' component='span'>
                                    brutto
                                </Typography>
                            </Typography>
                        </div>
                    </div>
                    <div className={s.bottomContainer}>
                        <div className={s.quantityInputContainer}>
                            <UncontrolledNumberInput
                                value={item.quantity}
                                size='small'
                                min={1}
                                onChange={(value: number) => handleQuantityChange(value, item)}
                                disabled={cart?.cartChanging}
                            />
                        </div>

                        <Tooltip title='Usuń z koszyka'>
                            <IconButton onClick={() => handleDelete(item)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default ProductsList;
