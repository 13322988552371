import { UserData } from './UserData';

export enum genderEnum {
    Male = 1,
    Female = 2,
}

export interface ApiResponse {
    code: number;
    success: boolean;
    psdata: UserData;
}
