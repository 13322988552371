import React from 'react';
import s from './PersonnelList.module.scss';

type Props = { name: string; children: React.ReactNode };

const PersonnelList = ({ children, name }: Props) => {
    return (
        <li className={s.container}>
            <h3 className={s.title}>{name}</h3>
            <ul className={s.personnelList}>{children}</ul>
        </li>
    );
};

export default PersonnelList;
