import React, { useEffect, useState } from 'react';
import s from './Product.module.scss';
import SubPage from 'components/SubPage/SubPage';
import ProductMedia from './ProductMedia/ProductMedia';
import ProductInfo from './ProductInfo/ProductInfo';
import ProductSidebar from './ProductSidebar/ProductSidebar';
import ProductMainDescription from './ProductMainDescription/ProductMainDescription';
import { IProduct } from 'types/types';
import { gql, useQuery } from '@apollo/client';
import Loading from 'components/Loading/Loading';
import { Helmet } from 'react-helmet-async';
import MedicalStaffCheckDialog from '../../../components/MedicalStaffCheckDialog/MedicalStaffCheckDialog';

interface Props {
    id: number;
    name: string;
}

interface IProductData {
    product: {
        data: IProduct;
    };
}

const GET_PRODUCT_BY_ID = gql`
    query GetProductById($id: ID!) {
        product(id: $id) {
            data {
                id
                attributes {
                    name
                    url
                    short_description
                    description
                    is_new
                    images {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    subcategory {
                        data {
                            attributes {
                                url
                                category {
                                    data {
                                        attributes {
                                            url
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                    accessories {
                        data {
                            id
                            attributes {
                                name
                                url
                                images {
                                    data {
                                        attributes {
                                            url
                                            alternativeText
                                        }
                                    }
                                }
                                subcategory {
                                    data {
                                        attributes {
                                            url
                                            category {
                                                data {
                                                    attributes {
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    related_products {
                        data {
                            id
                            attributes {
                                url
                                name
                                images {
                                    data {
                                        attributes {
                                            url
                                            alternativeText
                                        }
                                    }
                                }
                                subcategory {
                                    data {
                                        attributes {
                                            url
                                            category {
                                                data {
                                                    attributes {
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    manufacturer {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    videos {
                        data {
                            id
                            attributes {
                                previewUrl
                                url
                                alternativeText
                                caption
                            }
                        }
                    }
                    download_files {
                        data {
                            id
                            attributes {
                                url
                                name
                                caption
                            }
                        }
                    }
                    require_medical_staff_check
                }
            }
        }
    }
`;

const Product = ({ id, name }: Props) => {
    const { loading, data } = useQuery<IProductData>(GET_PRODUCT_BY_ID, {
        variables: { id },
    });

    const [isMedicalStaffCheckDialogOpen, setIsMedicalStaffCheckDialogOpen] = useState(false);

    useEffect(() => {
        if (loading) return;

        if (data?.product.data.attributes.require_medical_staff_check !== false)
            sessionStorage.getItem('isMedicalStaff') !== 'true' && setIsMedicalStaffCheckDialogOpen(true);
    }, [data, loading]);

    const hasSidebar: boolean =
        data?.product.data?.attributes.accessories.data.length !== 0 ||
        data?.product.data?.attributes.related_products.data.length !== 0;

    return (
        <>
            <Helmet>
                <title>{name}</title>
                <meta name='description' content={data?.product.data?.attributes.short_description} />
                <link rel='canonical' href={window.location.href} />
            </Helmet>

            <MedicalStaffCheckDialog
                open={isMedicalStaffCheckDialogOpen}
                handleClose={() => setIsMedicalStaffCheckDialogOpen(false)}
            />

            <SubPage currentPageName={name}>
                {loading && <Loading />}
                {!loading && data && (
                    <div className={[s.container, hasSidebar ? '' : s.noSidebar].join(' ')}>
                        {hasSidebar && (
                            <ProductSidebar
                                accessories={data.product.data?.attributes.accessories}
                                relatedProducts={data.product.data?.attributes.related_products}
                            />
                        )}

                        <ProductMedia
                            images={data.product.data?.attributes.images}
                            videos={data.product.data?.attributes.videos}
                        />

                        <ProductInfo
                            categoryName={
                                data?.product.data?.attributes.subcategory.data?.attributes.category.data?.attributes
                                    .name
                            }
                            categoryUrl={
                                data?.product.data?.attributes.subcategory.data?.attributes.category.data?.attributes
                                    .url
                            }
                            name={name}
                            description={data?.product.data?.attributes.short_description}
                            downloadFiles={data?.product.data?.attributes.download_files}
                        />

                        <ProductMainDescription
                            skipSidebar={!hasSidebar}
                            description={data.product.data.attributes.description}
                        />
                    </div>
                )}
            </SubPage>
        </>
    );
};

export default Product;
