import React, { useRef, useState } from 'react';
import s from './ShopProductCard.module.scss';
import { IconButton, Typography, Tooltip, Chip, Slide } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useCart } from 'contexts/CartContext';
import { Link } from 'react-router-dom';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { Product } from 'types/prestashopREST/MainShopPage/ProductListing';

type Props = { product: Product; link: string };

const ShopProductCard = ({ product, link }: Props) => {
    const [lockCartBtn, setLockCartBtn] = useState<boolean>(false);
    const cart = useCart();

    const handleCartClick = async () => {
        setLockCartBtn(true);
        await cart?.updateCartItem(parseInt(product.id_product), 1, 'up');
        setLockCartBtn(false);
    };

    const handleWishlistButtonClick = async () => {};

    const [mouseOverCard, setMouseOverCard] = useState<boolean>(false);

    const cardRef = useRef(null);

    return (
        <Link to={link}>
            <div
                className={s.container}
                onMouseOut={() => setMouseOverCard(false)}
                onMouseOver={() => setMouseOverCard(true)}
            >
                <div className={s.imgContainer}>
                    {product.quantity <= 0 && (
                        <Chip
                            className={s.noAvaiableChip}
                            color='primary'
                            size='small'
                            label='Obecnie brak na stanie'
                        />
                    )}

                    <img className={s.img} src={product?.default_image?.url} alt={product.name} />
                </div>

                <div className={s.bottomContainer} ref={cardRef}>
                    <Slide
                        in={mouseOverCard}
                        container={cardRef.current}
                        direction='up'
                        mountOnEnter
                        unmountOnExit
                        onClick={(e) => e.preventDefault()}
                    >
                        <div className={s.actionBar}>
                            <Tooltip title='Dodaj do ulubionych'>
                                <div>
                                    <IconButton onClick={handleWishlistButtonClick} size='large' disabled>
                                        <FontAwesomeIcon icon={faHeart} />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title='Dodaj do koszyka'>
                                <div>
                                    <IconButton
                                        onClick={handleCartClick}
                                        disabled={product.quantity === 0 || lockCartBtn}
                                        size='large'
                                        color='primary'
                                    >
                                        <FontAwesomeIcon icon={faCartShopping} />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </div>
                    </Slide>

                    <Typography className={s.name} component='h3' variant='body2' sx={{ fontWeight: 600 }}>
                        {product.name}
                    </Typography>

                    <p className={s.price}>{product.price} brutto</p>
                </div>
            </div>
        </Link>
    );
};

export default ShopProductCard;
