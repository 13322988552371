export default function getContrastingTextColor(backgroundColor: string): string {
    // Convert the background color to RGB
    const r = parseInt(backgroundColor.slice(1, 3), 16);
    const g = parseInt(backgroundColor.slice(3, 5), 16);
    const b = parseInt(backgroundColor.slice(5, 7), 16);

    // Calculate the relative luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Choose text color based on luminance
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
}
