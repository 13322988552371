import React from 'react';
import Layout from 'components/Layout/Layout';
import CategorySelect from './CategorySelect/CategorySelect';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import { gql, useQuery } from '@apollo/client';
import Loading from 'components/Loading/Loading';
import { IProductCategory } from 'types/types';
import ProductCategory from './ProductCategory/ProductCategory';
import SubPage from 'components/SubPage/SubPage';
import backgroundImg from 'assets/subpage-images/products.png';

interface Props {}

interface IProductCategoriesData {
    productCategories: {
        data: IProductCategory[];
    };
}

const GET_CATEGORIES = gql`
    query GetProductCategories {
        productCategories(pagination: { limit: -1 }, sort: "order:asc") {
            data {
                id
                attributes {
                    name
                    url
                    description
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ProductsPage = (props: Props) => {
    const { loading, data: queryData } = useQuery<IProductCategoriesData>(GET_CATEGORIES);

    return (
        <Layout>
            <Routes>
                <Route path='/'>
                    <Route
                        index
                        element={
                            <>
                                <SubPage title={'Produkty'} image={backgroundImg}>
                                    {loading && <Loading />}
                                    {!loading && queryData && (
                                        <CategorySelect categories={queryData.productCategories.data} />
                                    )}
                                </SubPage>
                            </>
                        }
                    />

                    {queryData?.productCategories.data.map(({ id, attributes }) => (
                        <Route
                            key={id}
                            path={`${attributes.url}/*`}
                            element={
                                <ProductCategory id={id} name={attributes.name} description={attributes.description} />
                            }
                        />
                    ))}

                    {!loading && <Route path='*' element={<NotFoundPage showLayout={false} />} />}
                </Route>
            </Routes>
        </Layout>
    );
};

export default ProductsPage;
