import React from 'react';
import s from './RepresentationSection.module.scss';
import Section from 'components/Section/Section';
import { IRepresentationLogo } from '../HomePage';
import { addStrapiUrl } from 'utils/strapiUtils';

interface Props {
    repLogos?: IRepresentationLogo[];
}

const RepresentationSection = ({ repLogos }: Props) => {
    if (repLogos?.length === 0) return null;
    return (
        <Section className={s.section}>
            <div className={s.container}>
                {repLogos?.map((logo) => (
                    <a
                        href={logo.attributes.website_url}
                        target='_blank'
                        rel='noreferrer'
                        className={s.logoContainer}
                        key={logo.id}
                    >
                        <img
                            className={s.logo}
                            src={addStrapiUrl(logo.attributes.logo.data.attributes.url)}
                            alt={logo.attributes.logo.data.attributes.alternativeText}
                        />
                    </a>
                ))}
            </div>
        </Section>
    );
};

export default RepresentationSection;
