import React from 'react';
import s from './OrderConfirmationTable.module.scss';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Button,
} from '@mui/material';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { OrderConfirmPSData } from 'types/prestashopREST/Checkout/OrderConfirmation';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useBreakpoint from 'hooks/useBreakpoint';

type Props = {
    data: OrderConfirmPSData;
};

const paymentMethodsDict: { [key: string]: string } = {
    ps_wirepayment: 'Płatność przelewem',
    ps_cashondelivery: 'Płatność przy odbiorze',
};

export default function OrderConfirmationTable({ data }: Props) {
    const { belowTablet } = useBreakpoint();

    return (
        <>
            <TableContainer component={Paper} className={s.container}>
                {!belowTablet && (
                    <Table sx={{ minWidth: 650 }} aria-label='cart table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Przedmiot</TableCell>
                                <TableCell>Nazwa</TableCell>
                                <TableCell align='center'>Cena</TableCell>
                                <TableCell align='center'>Ilość</TableCell>
                                <TableCell align='center'>Cena końcowa</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(data.products || {}).map((key) => {
                                const product = data.products[key];

                                return (
                                    <TableRow key={product.id_product}>
                                        <TableCell>
                                            <img
                                                src={product.default_image.medium.url}
                                                alt={product.name}
                                                width={150}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MuiLink
                                                component={Link}
                                                to={`${NavigationNames.Shop}/${product.id_category_default}/${product.id_product}`}
                                            >
                                                {product.name}
                                            </MuiLink>
                                        </TableCell>
                                        <TableCell align='center'>{product.price}</TableCell>
                                        <TableCell align='center'>{product.quantity}</TableCell>
                                        <TableCell align='center'>
                                            <Typography variant='h6' fontWeight={600}>
                                                {product.total}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}

                <div className={s.totalsItem}>
                    <Typography variant='body1'>{data.amounts.subtotals.products.label}</Typography>
                    <Typography variant='body1'>{data.amounts.subtotals.products.value}</Typography>
                </div>

                <div className={s.totalsItem}>
                    <Typography variant='body1'>{data.amounts.subtotals.shipping.label}</Typography>
                    <Typography variant='body1'>{data.amounts.subtotals.shipping.value}</Typography>
                </div>

                <div className={s.subtotalsItem}>
                    <Typography variant='body1' fontWeight='bold'>
                        {data.amounts.totals.total.label} (Brutto)
                    </Typography>
                    <Typography variant='body1' fontWeight='bold'>
                        {data.amounts.totals.total.value}
                    </Typography>
                </div>

                <Typography variant='h6' component='h3'>
                    SZCZEGÓŁY ZAMÓWIENIA
                </Typography>

                <Typography component='p'>Numer zamówienia: {data.details.reference}</Typography>
                <Typography component='p'>Metoda płatności: {paymentMethodsDict[data.details.module]}</Typography>
                <Typography component='p'>
                    Sposób dostawy: {data.carrier.name} {data.carrier.delay}
                </Typography>
            </TableContainer>

            <Button
                startIcon={<FontAwesomeIcon icon={faShoppingBasket} />}
                variant='contained'
                component={Link}
                to={NavigationNames.Shop}
            >
                Kontynuuj zakupy
            </Button>
        </>
    );
}
