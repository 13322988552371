import React from 'react';
import s from './NumberInput.module.scss';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, TextField } from '@mui/material';

type Props = {
    value: number;
    setValue: (value: number) => void;
    max?: number;
    min?: number;
    disabled?: boolean;
};

const NumberInput = ({ value, setValue, max, min, disabled }: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(e.target.value);

        if (isNaN(newValue)) newValue = 1;
        else if (min !== undefined && newValue < min) newValue = min;
        else if (max !== undefined && newValue > max) newValue = max;

        setValue(newValue);
    };

    const decrementValue = () => {
        let newValue = value - 1;

        if (min !== undefined && newValue < min) newValue = min;

        setValue(newValue);
    };

    const incrementValue = () => {
        let newValue = value + 1;

        if (max !== undefined && newValue > max) newValue = max;

        setValue(newValue);
    };

    return (
        <div className={s.container}>
            <IconButton onClick={decrementValue} disabled={disabled}>
                <FontAwesomeIcon icon={faMinus} />
            </IconButton>
            <TextField
                className={s.input}
                inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    min,
                    max,
                    inputprops: {
                        style: {
                            '-webkit-appearance': 'none',
                            '-moz-appearance': 'textfield',
                        } as React.CSSProperties,
                    },
                }}
                fullWidth={false}
                size='small'
                value={value}
                onChange={handleChange}
                disabled={disabled}
            />
            <IconButton onClick={incrementValue} disabled={disabled}>
                <FontAwesomeIcon icon={faPlus} />
            </IconButton>
        </div>
    );
};

export default NumberInput;
