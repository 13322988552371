import React from 'react';
import s from './RoundImage.module.scss';

type Props = { imageSrc: string; imageAlt?: string; className?: string };

const RoundImage = ({ imageSrc, imageAlt, className }: Props) => {
    return (
        <div className={`${s.container} ${className ?? ''}`}>
            <img src={imageSrc} alt={imageAlt} className={s.image} />
        </div>
    );
};

export default RoundImage;
