import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { ICheckoutStep } from '../OrderCheckout.types';
import { useCheckout } from 'contexts/CheckoutContext';
import { useAuth } from 'contexts/AuthContext';

type Props = {
    disabled?: boolean;
    setActiveStep: (value: React.SetStateAction<number>) => void;
    activeStep: number;
    steps: ICheckoutStep[];
};

export enum CheckoutStepsEnum {
    PersonalData = 0,
    Address,
    Shipping,
    Payment,
}

const StepNavigationButtons = ({ disabled = false, setActiveStep, activeStep, steps }: Props) => {
    const checkout = useCheckout();
    const auth = useAuth();

    const handleNext = () => {
        if (isLastStep()) return;
        setActiveStep((prevState) => prevState + 1);
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
    };

    const totalSteps = () => {
        return steps.length;
    };

    const disableNextButton: () => boolean = () => {
        if (isLastStep() || checkout?.loading) return true;

        if (
            (activeStep === CheckoutStepsEnum.PersonalData && !auth?.isAuthenticated) ||
            (activeStep === CheckoutStepsEnum.Address && !checkout?.selectedAddress) ||
            (activeStep === CheckoutStepsEnum.Shipping && !checkout?.selectedCarrier)
        )
            return true;

        return false;
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button variant='contained' color='inherit' disabled={activeStep === 0} onClick={handleBack}>
                Powrót
            </Button>

            <Box sx={{ flex: '1 1 auto' }} />
            {isLastStep() ? (
                <Button
                    variant='contained'
                    onClick={checkout?.finishCheckout}
                    disabled={!(checkout?.agreementChecked && checkout?.selectedPaymentOption)}
                >
                    Złóż zamówienie
                </Button>
            ) : (
                <Button variant='contained' onClick={handleNext} disabled={disableNextButton()}>
                    Dalej
                </Button>
            )}
        </Box>
    );
};

export default StepNavigationButtons;
