import { Slider } from '@mui/material';
import React, { useState } from 'react';
import { Facet } from 'types/prestashopREST/CategoryProduct';
import SideBarItem from '../SideBarItem/SideBarItem';
import FacetCheckbox from './FacetCheckbox/FacetCheckbox';

type Props = { facet: Facet; setSelectedFiltersFacet: React.Dispatch<React.SetStateAction<string>>; loading: boolean };

const PRICE_TYPE = 'price';

const FilterFacet = ({ facet, setSelectedFiltersFacet, loading }: Props) => {
    const priceFacet = facet.filters.find((filter) => filter.type === PRICE_TYPE);
    const [sliderValue, setSliderValue] = useState<number[]>(
        priceFacet ? [priceFacet.properties.min, priceFacet.properties.max] : [0, 1000]
    );

    return (
        <SideBarItem titleVariant='h6' titleComponent='h4' title={facet.label}>
            {facet.filters.map((filter) => (
                <div key={`${filter.label}-${filter.type}`}>
                    {filter.type === PRICE_TYPE ? (
                        // Remove disabled when implemented
                        <Slider disabled min={filter.properties.min} max={filter.properties.max} value={sliderValue} />
                    ) : (
                        <FacetCheckbox
                            filter={filter}
                            loading={loading}
                            setSelectedFiltersFacet={setSelectedFiltersFacet}
                        />
                    )}
                </div>
            ))}
        </SideBarItem>
    );
};

export default FilterFacet;
