import React from 'react';
import s from './ShopProductsList.module.scss';
import ShopProductCard from '../../../components/ShopComponents/ShopProductCard/ShopProductCard';
import { Typography } from '@mui/material';
import Carousel from 'react-multi-carousel';
import { Product } from 'types/prestashopREST/MainShopPage/ProductListing';

type Props = { products: Product[] | null; title?: string; description?: string };

const responsive = {
    desktop: {
        breakpoint: { min: 1024, max: 4000 },
        items: 4,
    },
    tablet: {
        breakpoint: { min: 768, max: 1024 },
        items: 2,
    },
    mobile: {
        breakpoint: { min: 0, max: 768 },
        items: 1,
    },
};

const ShopProductsList = ({ products, title, description }: Props) => {
    if (!products || products.length === 0) return null;

    return (
        <>
            <div>
                <Typography component='h2' variant='h5'>
                    {title}
                </Typography>

                <Typography component='p' variant='body1' color='primary'>
                    {description}
                </Typography>
            </div>

            <Carousel
                autoPlay={products.length > 1}
                autoPlaySpeed={4000}
                className={s.list}
                responsive={responsive}
                pauseOnHover
                // infinite
                arrows={products.length > 1}
                showDots={false}
                dotListClass={s.dots}
                draggable={false}
            >
                {products?.map((product: Product) => (
                    <ShopProductCard
                        key={product.id_product}
                        product={product}
                        link={`${product.id_category_default}-${product.category}/${product.id_product}`}
                    />
                ))}
            </Carousel>
        </>
    );
};

export default ShopProductsList;
