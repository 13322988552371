import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import { useAuth } from 'contexts/AuthContext';
import React from 'react';
import s from './ProfilePage.module.scss';
import ProfileCard from './ProfileCard/ProfileCard';
import { ProfileRoutesNames } from 'Router/ProfileRoutes';

type Props = {};

const ProfilePage = (props: Props) => {
    const auth = useAuth();

    return (
        <Layout>
            <SubPage>
                <h1>Witaj {auth?.userData?.firstname}</h1>

                <ul className={s.cardsList}>
                    <ProfileCard name='Historia zamówień' link={ProfileRoutesNames.OrderHistory} />
                    <ProfileCard name='Ustawienia konta' link={ProfileRoutesNames.Settings} />
                </ul>
            </SubPage>
        </Layout>
    );
};

export default ProfilePage;
