import { Button, Dialog, DialogContent, DialogTitle, Divider, TextField, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import s from './AddressDialog.module.scss';
import * as Yup from 'yup';
import { AddAddressApiResponse } from 'types/prestashopREST/Checkout/AddAddress';
import { useSnackbar } from 'contexts/SnackbarContext';
import useBreakpoint from 'hooks/useBreakpoint';
import { AddressInfo } from 'types/prestashopREST/Checkout/GetAddresses';

type Props = {
    open: boolean;
    handleClose: () => void;
    refetchAddresses: () => void;
    isEdit?: boolean;
    address?: AddressInfo | null;
};

interface IFormValues {
    alias: string;
    firstname: string;
    lastname: string;
    company: string;
    address1: string;
    address2: string;
    postcode: string;
    city: string;
    country: string;
    phone: string;
    vat_number: string;
}

interface AddressInsert extends IFormValues {
    id_country: number;
    id_address?: number;
}

const AddressDialog = ({ open, handleClose, refetchAddresses, isEdit = false, address = null }: Props) => {
    const snackbar = useSnackbar();
    const { belowTablet } = useBreakpoint();

    const validationSchema = Yup.object().shape({
        alias: Yup.string().required('Nazwa adresu jest wymagana'),
        firstname: Yup.string().required('Imię jest wymagane'),
        lastname: Yup.string().required('Nazwisko jest wymagane'),
        company: Yup.string(),
        address1: Yup.string().required('Adres jest wymagany'),
        address2: Yup.string(),
        postcode: Yup.string()
            .required('Kod pocztowy jest wymagany')
            .matches(/^\d{2}-\d{3}$/, 'Kod pocztowy musi mieć format NN-NNN'),
        city: Yup.string().required('Miasto jest wymagane'),
        country: Yup.string().required('Kraj jest wymagany'),
        phone: Yup.string().required('Numer telefonu jest wymagany'),
        vat_number: Yup.string().required('NIP jest wymagany'),
    });

    const handeSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
        try {
            const requestBody: AddressInsert = {
                ...values,
                id_country: 14,
            };

            if (isEdit && address) {
                requestBody.id_address = parseInt(address.id);
            }

            const response = await fetch(`${process.env.REACT_APP_PRESTASHOP_URL}/rest/address`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify(requestBody),
            });

            const data: AddAddressApiResponse = await response.json();

            if (!data.success) {
                snackbar.openSnackbar(data.message || 'Wystąpił błąd', 'error');
                handleClose();
                return;
            }

            snackbar.openSnackbar('Adres został dodany');
            refetchAddresses();
            handleClose();
        } catch (err) {
            if (err instanceof Error) {
                snackbar.openSnackbar(err.message, 'error');
                return;
            }

            snackbar.openSnackbar('Wystąpił błąd', 'error');
        }
    };

    const initialValues: IFormValues =
        isEdit && address
            ? {
                  alias: address.alias,
                  firstname: address.firstname,
                  lastname: address.lastname,
                  company: address.company,
                  address1: address.address1,
                  address2: address.address2,
                  postcode: address.postcode,
                  city: address.city,
                  country: address.country,
                  phone: address.phone,
                  vat_number: address.vat_number,
              }
            : {
                  alias: '',
                  firstname: '',
                  lastname: '',
                  company: '',
                  address1: '',
                  address2: '',
                  postcode: '',
                  city: '',
                  country: 'Polska',
                  phone: '',
                  vat_number: '',
              };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth fullScreen={belowTablet}>
            <DialogTitle>
                <Typography variant='h6' component='h6' gutterBottom>
                    {isEdit ? 'Edytuj adres' : 'Nowy adres'}
                </Typography>
                {isEdit ? null : (
                    <Typography variant='body1'>
                        Wprowadź nowy adres. Możesz go później wybrać podczas składzania zamówienia.
                    </Typography>
                )}
            </DialogTitle>
            <DialogContent className={s.dialogContent}>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handeSubmit}>
                    {({ submitForm, isSubmitting, getFieldProps, touched, errors }) => (
                        <>
                            <Form className={s.form}>
                                <div className={s.contactInfo}>
                                    <TextField
                                        required
                                        {...getFieldProps('alias')}
                                        label='Nazwa adresu'
                                        placeholder='np. prywatny, firma, itp.'
                                        variant='outlined'
                                        error={Boolean(touched.alias && errors.alias)}
                                        helperText={touched.alias && errors.alias}
                                        className={s.alias}
                                    />
                                    <TextField
                                        required
                                        {...getFieldProps('firstname')}
                                        label='Imię'
                                        variant='outlined'
                                        error={Boolean(touched.firstname && errors.firstname)}
                                        helperText={touched.firstname && errors.firstname}
                                        className={s.firstname}
                                    />
                                    <TextField
                                        required
                                        {...getFieldProps('lastname')}
                                        label='Nazwisko'
                                        variant='outlined'
                                        error={Boolean(touched.lastname && errors.lastname)}
                                        helperText={touched.lastname && errors.lastname}
                                        className={s.lastname}
                                    />

                                    <TextField
                                        {...getFieldProps('phone')}
                                        label='Numer telefonu'
                                        variant='outlined'
                                        error={Boolean(touched.phone && errors.phone)}
                                        helperText={touched.phone && errors.phone}
                                        className={s.phone}
                                        required
                                    />
                                    <TextField
                                        {...getFieldProps('company')}
                                        label='Nazwa firmy'
                                        variant='outlined'
                                        error={Boolean(touched.company && errors.company)}
                                        helperText={touched.company && errors.company}
                                        className={s.company}
                                    />

                                    <TextField
                                        {...getFieldProps('vat_number')}
                                        label='NIP'
                                        variant='outlined'
                                        error={Boolean(touched.vat_number && errors.vat_number)}
                                        helperText={touched.vat_number && errors.vat_number}
                                        className={s.vatNumber}
                                        required
                                    />
                                </div>

                                <Divider />

                                <div className={s.addressInfo}>
                                    <TextField
                                        required
                                        {...getFieldProps('address1')}
                                        label='Ulica i numer domu'
                                        variant='outlined'
                                        error={Boolean(touched.address1 && errors.address1)}
                                        helperText={touched.address1 && errors.address1}
                                        className={s.address1}
                                    />
                                    <TextField
                                        {...getFieldProps('address2')}
                                        label='Numer lokalu'
                                        variant='outlined'
                                        error={Boolean(touched.address2 && errors.address2)}
                                        helperText={touched.address2 && errors.address2}
                                        className={s.address2}
                                    />
                                    <TextField
                                        required
                                        {...getFieldProps('postcode')}
                                        label='Kod pocztowy'
                                        variant='outlined'
                                        error={Boolean(touched.postcode && errors.postcode)}
                                        helperText={touched.postcode && errors.postcode}
                                        className={s.postcode}
                                    />
                                    <TextField
                                        required
                                        {...getFieldProps('city')}
                                        label='Miasto'
                                        variant='outlined'
                                        error={Boolean(touched.city && errors.city)}
                                        helperText={touched.city && errors.city}
                                        className={s.city}
                                    />
                                    <TextField
                                        required
                                        {...getFieldProps('country')}
                                        label='Kraj'
                                        variant='outlined'
                                        error={Boolean(touched.country && errors.country)}
                                        helperText={touched.country && errors.country}
                                        className={s.country}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                            </Form>

                            <div className={s.buttons}>
                                <Button
                                    disabled={isSubmitting}
                                    variant='contained'
                                    color='primary'
                                    onClick={submitForm}
                                    type='submit'
                                    fullWidth
                                >
                                    Zapisz adres
                                </Button>

                                <Button fullWidth variant='outlined' color='secondary' onClick={handleClose}>
                                    Anuluj
                                </Button>
                            </div>
                        </>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AddressDialog;
