import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    palette: {
        primary: {
            // main: '#0096d6', // old blue color, lighter than current
            main: '#0069B4',
        },
        secondary: {
            main: '#303041',
        },
    },
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        body1: {
            fontFamily: 'Open Sans, sans-serif',
        },
        body2: {
            fontFamily: 'Open Sans, sans-serif',
        },
        h1: {
            fontWeight: 500,
        },
        h2: {
            fontWeight: 500,
        },
        h3: {
            fontWeight: 500,
        },
        h4: {
            fontWeight: 500,
        },
        h5: {
            fontWeight: 500,
        },
        h6: {
            fontWeight: 500,
        },
    },
    gradients: {
        pink: 'linear-gradient(to right, #e2528c, #e28c52)',
        blue: 'linear-gradient(to right, #5fbace, #0182bb)',
        green: 'linear-gradient(to right, #d3e8a7, #97d5b9)',
        yellow: 'linear-gradient(to right, #ff9900, #ffd500)',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 1100,
            xl: 1600,
        },
    },
});

theme = responsiveFontSizes(theme, { factor: 2 });

export default theme;
