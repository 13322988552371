import React, { useState } from 'react';
import s from './ServicePage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import ServiceRequestForm from './ServiceRequestForm/ServiceRequestForm';
import ServiceArticlesList from './ServiceArticlesList/ServiceArticlesList';
import { gql, useQuery } from '@apollo/client';
import { IServiceArticle } from 'types/types';
import Loading from 'components/Loading/Loading';
import { Route, Routes } from 'react-router-dom';
import Article from './Article/Article';
import NotFound from 'pages/NotFoundPage/NotFoundPage';
import backgroundImage from 'assets/subpage-images/service.png';
import BeanButton from 'components/BeanButton/BeanButton';
import { Helmet } from 'react-helmet-async';
import Typography from '@mui/material/Typography';

interface Props {}

const GET_SERVICE_ARTICLES = gql`
    query GET_SERVICE_ARTICLES {
        serviceArticles(pagination: { limit: 10 }, sort: "createdAt:desc") {
            data {
                id
                attributes {
                    title
                    description
                    createdAt
                    image {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ServicePage = (props: Props) => {
    const [isServiceRequestOpen, setIsServiceRequestOpen] = useState(false);

    const { loading: loadingArticles, error: errorArticles, data: dataArticles } = useQuery(GET_SERVICE_ARTICLES);
    const articles: IServiceArticle[] = dataArticles?.serviceArticles?.data;

    return (
        <>
            <Helmet>
                <title>Serwis</title>
                <meta
                    name='description'
                    content={`Oferta Poland Optical to nie tylko znakomity sprzęt, urządzenia czy narzędzia okulistyczne i optyczne oraz elementy wyposażenia gabinetów okulistycznych. Nasza firma zajmuje się również świadczeniem usług serwisowych. Jakie dokładnie usługi świadczymy?`}
                />
            </Helmet>
            <Layout>
                <Routes>
                    <Route
                        index
                        element={
                            <SubPage
                                title={'Serwis'}
                                description={'Autoryzowany serwis urządzeń Nidek, CSO, Meccanottica Mazza'}
                                image={backgroundImage}
                                actions={
                                    <div className={s.buttonsContainer}>
                                        <BeanButton
                                            color='black'
                                            text='Zgłoszenie serwisowe'
                                            onClick={() => setIsServiceRequestOpen(true)}
                                            iconEnd={<FontAwesomeIcon icon={faWrench} size='lg' />}
                                        />
                                    </div>
                                }
                            >
                                {loadingArticles && <Loading />}

                                {!loadingArticles && (
                                    <div className={s.container}>
                                        <div className={s.mainContent}>
                                            <Typography variant='h5' component='h2' className={s.heading}>
                                                Serwis Poland Optical
                                            </Typography>
                                            <div className={s.textContent}>
                                                <Typography variant='body1' className={s.secondaryText}>
                                                    Oferta Poland Optical to nie tylko znakomity sprzęt, urządzenia czy
                                                    narzędzia okulistyczne i optyczne oraz elementy wyposażenia
                                                    gabinetów okulistycznych. Nasza firma zajmuje się również
                                                    świadczeniem usług serwisowych. Jakie dokładnie usługi świadczymy?
                                                </Typography>

                                                <Typography variant='h6' component='h3'>
                                                    Autoryzowany serwis urządzeń Nidek, CSO, Meccanottica Mazza
                                                </Typography>

                                                <Typography variant='body1' className={s.secondaryText}>
                                                    Poland Optical to przede wszystkim{' '}
                                                    <b>
                                                        autoryzowany serwis sprzętu okulistycznego oraz sprzętu
                                                        optycznego firm Nidek i CSO oraz Meccanottica Mazza
                                                    </b>
                                                    , czyli najchętniej wybieranych przez specjalistów urządzeń
                                                    stosowanych w celu diagnostyki i leczenia różnego typu schorzeń i
                                                    chorób oczu. Nasza firma ma już wieloletnie doświadczenie w
                                                    świadczeniu tego typu usług, dzięki czemu można nam w pełni zaufać!
                                                    Jesteśmy w stanie naprawić wiele, różnego typu usterek. W przypadku
                                                    powstania jakichś problemów wystarczy tylko się z nami skontaktować
                                                    w celu wybrania odpowiedniego terminu wizyty. My przyjedziemy,
                                                    sprawdzimy, czy awarię można naprawić od razu na miejscu, czy jednak
                                                    konieczne będzie przeprowadzenie bardziej skomplikowanych prac lub
                                                    nawet zabranie uszkodzonego urządzania i wykonanie naprawy w
                                                    serwisie.
                                                </Typography>

                                                <Typography variant='body1' className={s.secondaryText}>
                                                    Dzięki wieloletniej współpracy z producentami urządzeń Nidek i CSO
                                                    oraz unitów okulistycznych Meccanottica Mazza staramy się rozwiązać
                                                    każdy problem. Wykonujemy nie tylko naprawy uszkodzonych urządzeń
                                                    okulistycznych czy sprzętu optycznego, ale także{' '}
                                                    <b>przeglądy techniczne ze wpisem do paszportów technicznych</b>.
                                                </Typography>

                                                <Typography variant='h6' component='h3'>
                                                    Uaktualnienia oprogramowania do urządzeń Nidek
                                                </Typography>

                                                <Typography variant='body1' className={s.secondaryText}>
                                                    Oprócz usług serwisowych zajmujemy się również świadczeniem usług,
                                                    polegających na wykonywaniu uaktualnień programowania do urządzeń
                                                    okulistycznych i sprzętu optycznego japońskiej firmy Nidek.
                                                    Wieloletnia współpraca z tym znanym na całym świecie producentem
                                                    sprawia, że nasi przedstawiciele mogą bez problemu pomóc naszym
                                                    klientom w pobraniu i zainstalowaniu najnowszego oprogramowania,
                                                    które jeszcze bardziej zwiększy możliwości stosowanego sprzętu.
                                                </Typography>

                                                <Typography variant='body1' className={s.secondaryText}>
                                                    W razie jakichkolwiek pytań zachęcamy do kontaktu!
                                                </Typography>
                                            </div>
                                        </div>
                                        {articles && <ServiceArticlesList articles={articles} showBlogButton />}
                                    </div>
                                )}

                                <ServiceRequestForm
                                    isOpen={isServiceRequestOpen}
                                    onClose={() => setIsServiceRequestOpen(false)}
                                />
                            </SubPage>
                        }
                    />
                    <Route path=':id' element={<Article />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Layout>
        </>
    );
};

export default ServicePage;
