import React from 'react';
import s from './RegisterDialog.module.scss';
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Formik, Form, FormikHelpers } from 'formik';
import { genderEnum } from 'types/prestashopREST/AccountInformation';
import { IRegisterData } from 'contexts/AuthContext.types';
import * as Yup from 'yup';
import { useAuth } from 'contexts/AuthContext';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'contexts/SnackbarContext';

type Props = { open: boolean; handleClose: () => void; onSuccess?: () => void };

interface IFormValues {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    gender: genderEnum;
    gdprConsent: boolean;
    privacyConsent: boolean;
    newsletter: boolean;
}

const RegisterDialog = ({ open, handleClose, onSuccess }: Props) => {
    const auth = useAuth();
    const theme = useTheme();
    const snackbar = useSnackbar();

    const validationSchema = Yup.object().shape({
        gender: Yup.number().required('Płeć jest wymagana'),
        firstname: Yup.string().required('Imię jest wymagane'),
        lastname: Yup.string().required('Nazwisko jest wymagane'),
        email: Yup.string().email('Niepoprawny email').required('Email jest wymagany'),
        password: Yup.string().required('Hasło jest wymagane').min(5, 'Hasło musi mieć minimum 5 znaków'),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password')], 'Hasła muszą być takie same')
            .required('Potwierdzenie hasła jest wymagane'),
        gdprConsent: Yup.boolean().oneOf([true], 'Zgoda wymagana'),
        privacyConsent: Yup.boolean().oneOf([true], 'Zgoda wymagana'),
        newsletter: Yup.boolean(),
    });

    const handleSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
        if (!auth) return;

        const registerData: IRegisterData = {
            gender: values.gender,
            firstName: values.firstname,
            lastName: values.lastname,
            email: values.email,
            password: values.password,
            newsletter: Number(values.newsletter),
        };

        const registered: boolean = await auth.register(registerData);

        if (registered) {
            snackbar.openSnackbar('Konto zostało utworzone', 'success');
            onSuccess?.();
            handleClose();
        }
    };

    return (
        <Dialog
            maxWidth={'lg'}
            fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>
                <div className={s.dialogTitle}>
                    Rejestracja{' '}
                    <IconButton onClick={handleClose}>
                        <FontAwesomeIcon icon={faClose} size='lg' />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        gender: genderEnum.Female,
                        firstname: '',
                        lastname: '',
                        email: '',
                        password: '',
                        passwordConfirmation: '',
                        gdprConsent: false,
                        privacyConsent: false,
                        newsletter: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        submitForm,
                        setFieldValue,
                        isSubmitting,
                        getFieldProps,
                        touched,
                        errors,
                        handleChange,
                        values,
                    }) => (
                        <Form className={s.form}>
                            <div className={s.titleSelectContainer}>
                                <FormLabel id='gender-radio-buttons-group-label'>Tytuł</FormLabel>
                                <RadioGroup
                                    aria-labelledby='gender-radio-buttons-group-label'
                                    value={values.gender}
                                    name='gender'
                                    row
                                >
                                    <FormControlLabel
                                        value={genderEnum.Male}
                                        onChange={handleChange}
                                        control={<Radio />}
                                        label='Pan'
                                    />
                                    <FormControlLabel
                                        value={genderEnum.Female}
                                        onChange={handleChange}
                                        control={<Radio />}
                                        label='Pani'
                                    />
                                </RadioGroup>
                            </div>

                            <TextField
                                {...getFieldProps('firstname')}
                                label='Imię*'
                                variant='outlined'
                                error={Boolean(touched.firstname && errors.firstname)}
                                helperText={touched.firstname && errors.firstname}
                            />

                            <TextField
                                {...getFieldProps('lastname')}
                                label='Nazwisko*'
                                variant='outlined'
                                error={Boolean(touched.lastname && errors.lastname)}
                                helperText={touched.lastname && errors.lastname}
                            />

                            <TextField
                                className={s.email}
                                {...getFieldProps('email')}
                                label='Adres e-mail*'
                                variant='outlined'
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />

                            <TextField
                                {...getFieldProps('password')}
                                type='password'
                                label='Hasło*'
                                variant='outlined'
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                            />

                            <TextField
                                {...getFieldProps('passwordConfirmation')}
                                type='password'
                                label='Powtórz hasło*'
                                variant='outlined'
                                error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                                helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                            />

                            <div className={`${s.fullSpan} ${s.checkboxesContainer}`}>
                                <FormControlLabel
                                    control={<Checkbox {...getFieldProps('newsletter')} />}
                                    label='Zapisz mnie do newslettera'
                                />

                                <div>
                                    <FormControlLabel
                                        control={<Checkbox {...getFieldProps('privacyConsent')} />}
                                        label='Zgadzam się na przetwarzanie moich udostępnionych danych osobowych w celu udzielania odpowiedzi na zapytania, realizacji zamówień lub umożliwiania dostępu do konkretnych informacji.*'
                                    />

                                    <FormHelperText error={Boolean(touched.privacyConsent && errors.privacyConsent)}>
                                        {errors.privacyConsent}
                                    </FormHelperText>
                                </div>

                                <div>
                                    <FormControlLabel
                                        control={<Checkbox {...getFieldProps('gdprConsent')} />}
                                        label='Akceptuję ogólne warunki użytkowania i politykę prywatności*'
                                    />
                                    <FormHelperText error={Boolean(touched.gdprConsent && errors.gdprConsent)}>
                                        {errors.gdprConsent}
                                    </FormHelperText>
                                </div>
                            </div>

                            <Button
                                disabled={isSubmitting}
                                variant='contained'
                                color='primary'
                                onClick={submitForm}
                                type='submit'
                                className={s.submitButton}
                            >
                                Zarejestruj się
                            </Button>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default RegisterDialog;
