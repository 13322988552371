import React from 'react';
import s from './ShippingStep.module.scss';
import ShippingMethodCard from './ShippingMethodCard/ShippingMethodCard';
import useFetch from 'hooks/useFetch';
import { GetCarriersApiResponse } from 'types/prestashopREST/Checkout/GetCarriers';
import Alert from '@mui/material/Alert/Alert';
import { LinearProgress } from '@mui/material';

type Props = {};

const ShippingStep = (props: Props) => {
    const { data, loading, error } = useFetch<GetCarriersApiResponse>(
        `${process.env.REACT_APP_PRESTASHOP_URL}/rest/carriers`
    );
    if (loading) return <LinearProgress />;

    return (
        <div className={s.cards}>
            {Object.keys(data?.psdata || {}).length === 0 && (
                <Alert severity='warning'>Brak dostępnych metod dostawy dla wybranego adresu</Alert>
            )}
            {Object.keys(data?.psdata || {}).map((key) => {
                if (!data) return null;
                return <ShippingMethodCard carrier={data.psdata[key]} key={key} />;
            })}
        </div>
    );
};

export default ShippingStep;
