import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { useAuth } from 'contexts/AuthContext';
import React from 'react';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
    const auth = useAuth();
    if (auth?.loading) return <LoadingScreen />;

    if (!auth?.isAuthenticated) return <Navigate to={NavigationNames.Login} />;

    return <>{children}</>;
};

export default ProtectedRoute;
